import React, {useState, useEffect} from 'react';
import s from './styles.scss';

export const LoadingSpinner = (
    {
        isLoading,
        isDashboard = false,
        className = ''
    }
) => {

    const [loading, setLoading] = useState(isLoading || true);

    useEffect(() => {

        setLoading(isLoading)
        const timer = setTimeout(() => {
            setLoading(false);
        }, 10000);

        return () => clearTimeout(timer);
    }, [loading]);

    return loading ? (
        <div className={isDashboard ? s.dashboard_container : s.container}>
            <div className={`${s.loadingspinner} ${className}`}>
                <div className={s.square1}/>
                <div className={s.square2}/>
                <div className={s.square3}/>
                <div className={s.square4}/>
                <div className={s.square5}/>
            </div>
        </div>
    ) : null;
};