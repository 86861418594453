import React from "react";

export const TestReportIcon = () => {
    return (
        <svg fill="#2B2C2D" height="30px" width="30px" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24" enableBackground="new 0 0 24 24">
            <g>
                <g>
                    <path d="M8.5,9C8.2,9,8,9.2,8,9.5V10c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3h0.5c0.3,0,0.5-0.2,0.5-0.5C12,10.6,10.4,9,8.5,9z
			 M9,10.1c1,0.2,1.8,1,1.9,1.9H9V10.1z M8,15c-1.1,0-2-0.9-2-2s0.9-2,2-2v1.5C8,12.8,8.2,13,8.5,13H10C10,14.1,9.1,15,8,15z"/>
                </g>
                <g>
                    <path d="M17.5,22h-11C5.7,22,5,21.3,5,20.5v-2C5,17.7,5.7,17,6.5,17h11c0.8,0,1.5,0.7,1.5,1.5v2C19,21.3,18.3,22,17.5,22z M6.5,18
			C6.2,18,6,18.2,6,18.5v2C6,20.8,6.2,21,6.5,21h11c0.3,0,0.5-0.2,0.5-0.5v-2c0-0.3-0.2-0.5-0.5-0.5H6.5z"/>
                </g>
                <g>
                    <path
                        d="M18.5,16h-6c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h6c0.3,0,0.5,0.2,0.5,0.5S18.8,16,18.5,16z"/>
                </g>
                <g>
                    <path
                        d="M18.5,14h-5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5c0.3,0,0.5,0.2,0.5,0.5S18.8,14,18.5,14z"/>
                </g>
                <g>
                    <path
                        d="M18.5,12h-5c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5c0.3,0,0.5,0.2,0.5,0.5S18.8,12,18.5,12z"/>
                </g>
                <g>
                    <path d="M18.5,10h-6C12.2,10,12,9.8,12,9.5S12.2,9,12.5,9h6C18.8,9,19,9.2,19,9.5S18.8,10,18.5,10z"/>
                </g>
                <g>
                    <path d="M18.5,8h-6C12.2,8,12,7.8,12,7.5S12.2,7,12.5,7h6C18.8,7,19,7.2,19,7.5S18.8,8,18.5,8z"/>
                </g>
                <path d="M19.5,0h-8.6c-0.4,0-0.8,0.2-1.1,0.4L3.4,6.9C3.2,7.1,3,7.5,3,7.9v14.6C3,23.3,3.7,24,4.5,24h15c0.8,0,1.5-0.7,1.5-1.5v-21
		C21,0.7,20.3,0,19.5,0z M11,3h6.5C17.8,3,18,3.2,18,3.5v1C18,4.8,17.8,5,17.5,5H11V3z M10,1.7v4.8C10,6.8,9.8,7,9.5,7H4.7L10,1.7z
		 M20,22.5c0,0.3-0.2,0.5-0.5,0.5h-15C4.2,23,4,22.8,4,22.5V8h5.5C10.3,8,11,7.3,11,6.5V6h6.5C18.3,6,19,5.3,19,4.5v-1
		C19,2.7,18.3,2,17.5,2H11V1h8.5C19.8,1,20,1.2,20,1.5V22.5z"/>
            </g>
        </svg>
    );
}
