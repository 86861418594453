


export const timestampConverter = (timestamp, includeTime = false) =>
    new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: includeTime ? '2-digit' : undefined,
        minute: includeTime ? '2-digit' : undefined,
        second: includeTime ? '2-digit' : undefined,
        hour12: false // Use 24-hour format
    }).format(timestamp * 1000);

export const timestampGenerator = () => Math.floor(new Date().valueOf() /1000)

const getUserLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

            },
            (error) => {
                console.error(`Geolocation error: ${error.message}`);
            }
        );
    } else {
        console.error('Geolocation is not supported by this browser.');
    }
}

const getGeolocationByIP = async () => {
    try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();

        console.log('Geolocation Information:', data);
    } catch (error) {
        console.error('Error fetching geolocation information:', error.message);
    }
};

export const topicToNumOfQsInfo = {
    'Craft and Structure': '28% of test, 13-15 questions',
    "Information and Ideas": '26% of test, 13-14 questions',
    "Expression of Ideas": '20% of test, 8-12 questions',
    "Standard English Conventions": '26% of test, 11-15 questions',
    "Algebra": '35% of test, 13-15 questions',
    "Advanced Math": '35% of test, 13-15 questions',
    "Problem-Solving and Data Analysis": '15% of test, 5-7 questions',
    "Geometry and Trigonometry": '15% of test, 5-7 questions'
}
