import React, {useEffect, useState, useRef} from 'react';

import {calculateTestScore, populateQuestions} from "../utils/utils";
import {QuestionNav} from "./tabs";
import {DirectionContent} from "./direction/";
import {QuestionMap} from "./questionmap";
import {MainBody} from "./body/main";
import {Footer} from "./footer";
import {Annotate} from "./annotate";
import {More} from "./more";
import {Overview} from "./body/main/overview";
import {SectionOver} from "./body/main/sectionOver";
import {LoadingSpinner} from "./loading";

import s from "../styles.scss";
import useCountdown from "@bradgarropy/use-countdown";
import {getData, submitAnswersBySection} from "../../../utils/api";
import {CompletedSection} from "../utils/models";
import {TestStatus} from "../models/AssignedTest";
import TestOver from "./body/main/testOver";
import {timestampGenerator} from "../../../utils/utils";
import {Reference} from "./reference";

export const Section = ({
                            testId,
                            test,
                            setTest,
                            setTestEnded,
                            testEnded,
                            testComponents,
                            currentTestComponent,
                            currentQuestionNumber,
                            setCurrentQuestionNumber,
                            questionsAnswered,
                            isFirstPage,
                            showMap,
                            toggleMap,
                            prevQuestion,
                            nextQuestion,
                            submitSection
                        }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [questions, setQuestions] = useState([]);

    // Toggled Components
    const [showDirection, setShowDirection] = useState(false);
    const [showAnnotate, setShowAnnotate] = useState(false);

    // TODO: #52. change the name to dropdown
    const [showMore, setShowMore] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [showReference, setShowReference] = useState(false);

    const [selectedAnswers, setSelectedAnswers] = useState(questionsAnswered);
    const [bookmarks, setBookmarks] = useState({});
    const [showCrossingOption, setShowCrossingOption] = useState(false);

    const [timeEnded, setTimeEnded] = useState(false);
    const [isSectionOver, setIsSectionOver] = useState(false);
    const [selectedCrossingOptions, setSelectedCrossingOptions] = useState({})

    // TODO: use redux!
    const [annotatedPositions, setAnnotatedPositions] = useState({});
    // const [selectedPosition, setSelectedPosition] = useState({});

    const [answers, setAnswers] = useState({});

    const dropdownRef = useRef(null);
    const referenceRef = useRef(null);

    useEffect(() => {
        document.body.addEventListener('click', handleOutsideClick);
        return () => document.body.removeEventListener('click', handleOutsideClick);
    }, []);

    useEffect(() => {
        document.body.addEventListener('click', handleShowReference);
        return () => document.body.removeEventListener('click', handleShowReference);
    }, []);

    const handleShowReference = (event) => {
        if (referenceRef.current && !referenceRef.current.contains(event.target)) {
            // setShowMore(false);
            setShowReference(false)
        }
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowMore(false);
            // setShowReference(false)
        }
    };

    useEffect(() => {
        setIsLoading(true);

        getData(`/api/questions?sectionId=${testId}-${currentTestComponent.section}`)
            .then(({data}) => {

                const answersFromData = Array(data.length);
                setQuestions(populateQuestions(data, answersFromData))
                setAnswers(answersFromData)
            })
            .catch(e => console.error(e));

        setIsLoading(false);
    }, [currentTestComponent.section]);


    /******************************** TOGGLES *********************************/
    const toggleAnnotate = () => setShowAnnotate(!showAnnotate);
    const toggleMore = () => setShowMore(!showMore);
    const toggleDirection = () => setShowDirection(!showDirection);
    const toggleCalculator = () => setShowCalculator(!showCalculator);
    const toggleReference = () => setShowReference(!showReference);
    const toggleCrossingOption = () => setShowCrossingOption(!showCrossingOption);
    const navigateToSpecificQuestion = (e) => setCurrentQuestionNumber(parseInt(e.currentTarget.id))
    const handleIsSectionOver = () => setIsSectionOver(!isSectionOver);

    const toggleOption = (e) => {
        const option = e.currentTarget.id

        if (currentQuestionNumber in selectedCrossingOptions &&
            selectedCrossingOptions[currentQuestionNumber].has(option)) return;

        selectedAnswers[currentQuestionNumber] = option;
        setSelectedAnswers(selectedAnswers);
    }

    const toggleBookmarks = (e) => {
        const id = e.currentTarget.id;

        bookmarks[id] = id in bookmarks ? !bookmarks[id] : true;
        setBookmarks(bookmarks);
    }

    const handleSelectedCrossingOptions = (e) => {
        const id = e.currentTarget.id;
        const updatedCrossingOptions = {...selectedCrossingOptions};
        const updatedSelectedAnswers = {...selectedAnswers};

        // Delete selected answer if it exists
        if (updatedSelectedAnswers[currentQuestionNumber] === id) {
            delete updatedSelectedAnswers[currentQuestionNumber];
            setSelectedAnswers(updatedSelectedAnswers);
        }

        // Update crossing options
        if (currentQuestionNumber in updatedCrossingOptions) {
            const questionOptions = updatedCrossingOptions[currentQuestionNumber];
            const isSelected = selectedAnswers[currentQuestionNumber] && selectedAnswers[currentQuestionNumber][id];

            // Remove option from selected answers if it exists
            if (isSelected) {
                delete selectedAnswers[currentQuestionNumber][id];
                setSelectedAnswers(selectedAnswers);
            }

            // Toggle option in crossing options
            if (questionOptions.has(id)) {
                questionOptions.delete(id);
            } else {
                questionOptions.add(id);
            }
        } else {
            // Initialize crossing options if not exist
            updatedCrossingOptions[currentQuestionNumber] = new Set([id]);
        }

        // Update states
        setSelectedCrossingOptions(updatedCrossingOptions);
    };

    const countdown = useCountdown({
        minutes: currentTestComponent.minutes,
        seconds: currentTestComponent.seconds,
        format: "mm:ss",
        isActive: true,
        onCompleted: () => {
            const timer = setTimeout(() => {
                setTimeEnded(true);
            }, 10000);
            return () => clearTimeout(timer);
        },
    })

    const resetTimer = () => {
        const nextSection = currentTestComponent.section + 1;

        if (nextSection < testComponents.length) {
            handleIsSectionOver()
            setTimeEnded(false);
            countdown.reset({
                minutes: testComponents[currentTestComponent.section + 1].minutes,
                seconds: testComponents[currentTestComponent.section + 1].seconds
            });
        }
    }

    const submitAnswers = () => {
        setSelectedCrossingOptions({});
        const section = currentTestComponent.section;
        test.sections[section - 1] = new CompletedSection(currentTestComponent, selectedAnswers, answers);

        let request;
        if (section === testComponents.length - 1) {
            calculateTestScore(test);
            // Submit and end the test
            request = {
                status: TestStatus.COMPLETED,
                completedDate: timestampGenerator(),
                currentSection: section,
                sections: test.sections,
                result: test.result
            }
            setTestEnded(true)
        } else {
            // submit to the next section
            request = {
                status: TestStatus.IN_PROGRESS,
                currentSection: section + 1,
                sections: test.sections
            }
        }

        setTest(test);

        setShowCrossingOption(false);
        setSelectedCrossingOptions({})
        setAnnotatedPositions({})
        setShowReference(false);
        setSelectedAnswers({})
        setBookmarks({})

        submitAnswersBySection(testId, request);
    }

    if (isLoading) {
        return <LoadingSpinner isLoading={isLoading}/>;
    }

    return (
        <>
            <QuestionNav
                currentTestComponent={currentTestComponent}
                countdown={countdown}
                toggleDirection={toggleDirection}
                toggleAnnotate={toggleAnnotate}
                toggleCalculator={toggleCalculator}
                toggleReference={toggleReference}
                toggleMore={toggleMore}
                dropdownRef={dropdownRef}
                referenceRef={referenceRef}
            />
            <main className={s.main}>
                {showDirection ?
                    <DirectionContent
                        title={currentTestComponent.title}
                        toggleDirection={toggleDirection}
                    /> : null
                }
                {showAnnotate ? <Annotate toggleAnnotate={toggleAnnotate}/> : null}
                {showMore ? <More/> : null}
                {showReference ? <Reference/> : null}
                {showMap ? (
                    <div
                        onClick={toggleMap}
                        className={s.question_map}
                    >
                        <div className={s.question_container}>
                            <div className={s.map_content}>
                                <QuestionMap
                                    currentTestComponent={currentTestComponent}
                                    currentQuestionNumber={currentQuestionNumber}
                                    selectedAnswers={selectedAnswers}
                                    bookmarks={bookmarks}
                                    navigateToSpecificQuestion={navigateToSpecificQuestion}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}

                {/* Question Main Body*/}
                {isLoading ? (
                    <LoadingSpinner
                        isLoading={isLoading}
                    />
                ) : (
                    testEnded ? (
                        <TestOver/>
                    ) : (
                        timeEnded ? (
                            <SectionOver
                                submitSection={submitSection}
                                submitAnswers={submitAnswers}
                                timeEnded={timeEnded}
                                setTimeEnded={setTimeEnded}
                                resetTimer={resetTimer}
                                handleIsSectionOver={handleIsSectionOver}
                            />
                        ) : (
                            currentQuestionNumber <= currentTestComponent.noOfQuestions + 1 ? (
                                currentQuestionNumber === currentTestComponent.noOfQuestions + 1 ? (
                                    <Overview
                                        currentTestComponent={currentTestComponent}
                                        currentQuestionNumber={currentQuestionNumber}
                                        selectedAnswers={selectedAnswers}
                                        bookmarks={bookmarks}
                                        navigateToSpecificQuestion={navigateToSpecificQuestion}
                                    />
                                ) : (
                                    questions[currentQuestionNumber] && (
                                        <MainBody
                                            testComponent={currentTestComponent}
                                            questionNumber={currentQuestionNumber}
                                            questionComponent={questions[currentQuestionNumber]}
                                            setSelectedAnswers={setSelectedAnswers}
                                            selectedAnswers={selectedAnswers}
                                            showCalculator={showCalculator}
                                            toggleOption={toggleOption}
                                            toggleBookmarks={toggleBookmarks}
                                            showCrossingOption={showCrossingOption}
                                            toggleCrossingOption={toggleCrossingOption}
                                            selectedCrossingOptions={selectedCrossingOptions}
                                            handleSelectedCrossingOptions={handleSelectedCrossingOptions}
                                            annotatedPositions={annotatedPositions}
                                            setAnnotatedPositions={setAnnotatedPositions}
                                        />
                                    )
                                )
                            ) : (
                                <SectionOver
                                    submitSection={submitSection}
                                    submitAnswers={submitAnswers}
                                    timeEnded={timeEnded}
                                    setTimeEnded={setTimeEnded}
                                    resetTimer={resetTimer}
                                    handleIsSectionOver={handleIsSectionOver}
                                />
                            )
                        )
                    )
                )}
            </main>
            <Footer
                isFirstPage={isFirstPage}
                questionNumber={currentQuestionNumber}
                testType={currentTestComponent.testType}
                numOfProblems={currentTestComponent.noOfQuestions}
                testNumber={currentTestComponent.testNumber}
                showMap={showMap}
                toggleMap={toggleMap}
                nextQuestion={nextQuestion}
                prevQuestion={prevQuestion}
            />
        </>
    )
}