import React, {useEffect, useState} from 'react';
import {QuestionNav} from "../../../../Test/components/tabs";
import s from "../../../../Test/styles.scss";
import {QuestionMap} from "../../../../Test/components/questionmap";
import {LoadingSpinner} from "../../../../Test/components/loading";
import {MainBody} from "../../../../Test/components/body/main";
import {Footer} from "../../../../Test/components/footer";
import PropTypes from "prop-types";
import {fetchTestByUserId, fetchTestsByUserId} from "../../../../../redux/actions/test";
import {withRouter} from "react-router";
import {connect, useDispatch} from "react-redux";
import {populateQuestions, populateTestComponents} from "../../../../Test/utils/utils";
import {TestComponent} from "../../../../Test/utils/models";
import {useHistory} from "react-router-dom";
import {getData} from "../../../../../utils/api";
import {NavDirection} from "../../../components/navDirection";

// TODO: #44: see if we want to refactor 'Section' class to handle 'TestReview'
const TestReview = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {testId, section, questionNum, performanceBySkills} = props.location.state || {};

    const [isLoading, setIsLoading] = useState(true);
    const [showAnnotate, setShowAnnotate] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [showReference, setShowReference] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [showMap, setShowMap] = useState(false)
    const [questions, setQuestions] = useState([]);
    const [sections, setSections] = useState([]);

    const toggleAnnotate = () => setShowAnnotate(!showAnnotate);
    const toggleMore = () => setShowMore(!showMore);
    const toggleCalculator = () => setShowCalculator(!showCalculator);
    const toggleReference = () => setShowReference(!showReference);
    const toggleMap = () => setShowMap(!showMap);

    const fetchData = async (testId) => {
        try {
            return await dispatch(fetchTestByUserId(testId));
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };
    const [test, setTest] = useState(null);
    const [currentTestComponent, setCurrentTestComponent] = useState(new TestComponent());
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [testComponents, setTestComponents] = useState([]);

    const MAX_RETRIES = 3;
    const RETRY_DELAY = 1000;
    const handleDataWithRetries = async (data) => {
        let retries = 0;

        while (retries < MAX_RETRIES) {
            try {
                if (data && data.sections) {
                    setTest(data);

                    const populatedComponents =
                        populateTestComponents(data, true, questionNum);
                    setTestComponents(populatedComponents);
                    // setQuestionsAnswered(populateQuestionsAnswered(data.sections[data.currentSection]));

                    // use this to display students answer vs correct answer!
                    setSections(data.sections);

                    const currentTestComponent = populatedComponents[section]
                    setCurrentTestComponent(currentTestComponent);
                    setCurrentQuestionNumber(currentTestComponent.currentQuestion);


                    // TODO: #48. Should be inside of .then()
                    getData(`/api/questions?sectionId=${testId}-${currentTestComponent.section}`)
                        .then(({data}) => {
                            let answersFromData = Array(data.length);
                            setQuestions(populateQuestions(data, answersFromData))
                        })
                        .catch(e => console.error(e));
                }
                break;
            } catch (error) {
                console.error("Error occurred:", error);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                retries++;
            }
        }

        if (retries === MAX_RETRIES) {
            console.error("Max retries reached. Failed to handle data.");
            history.push("/template/tests");
        }
    }

    const submitSection = () => {
        const nextSection = currentTestComponent.section + 1;

        if (nextSection === testComponents.length - 1) {
            history.push(`/template/tests/${testId}/report`)

        } else {
            getData(`/api/questions?sectionId=${testId}-${nextSection}`)
                .then(({data}) => {
                    let answersFromData = Array(data.length);
                    setQuestions(populateQuestions(data, answersFromData))
                })
                .catch(e => console.error(e));

            const nextTestComponent = testComponents[nextSection];
            setCurrentTestComponent(nextTestComponent);
            setCurrentQuestionNumber(1);

            return nextTestComponent.section;
        }
    }

    const navigateQuestion = (num) => {
        if (num === currentTestComponent.noOfQuestions + 1) {
            submitSection();
        }
        return num < 1 ? 1 : num;
    };

    const prevQuestion = () => {
        setCurrentQuestionNumber(() => {
            if (showMap) setShowMap(!showMap);
            return navigateQuestion(currentQuestionNumber - 1);
        });
    };

    const nextQuestion = () => {
        setCurrentQuestionNumber((num) => {
            if (showMap) setShowMap(!showMap);
            return navigateQuestion(num + 1);
        });
    };

    const navigateToSpecificQuestion = (e) => {
        setCurrentQuestionNumber(parseInt(e.currentTarget.id))
    }

    useEffect(() => {
        if (!testId) {
            history.push("/template/tests");
            return;
        }

        if (props.testsByUserId) {
            // TODO:  1/14/24: map testId to tests on click
            props.testsByUserId
                .filter(test => test.testId === testId)
                .map(currTest => handleDataWithRetries(currTest)
                    .then(() => setIsLoading(false)));
        } else {
            if (props.testByUserId && Object.keys(props.testByUserId).length === 0) {
                fetchData(testId).then((data) => {
                    handleDataWithRetries(props.testByUserId)
                        .then(() => setIsLoading(false));
                });
            } else {
                handleDataWithRetries(props.testByUserId)
                    .then(() => setIsLoading(false));
            }
        }
    }, [props.testsByUserId || props.testByUserId]);

    const isReviewMode = true;

    // TODO: 'Report' update url in navDirection
    return (
        <>
            <NavDirection
                navs={['Dashboard', 'Tests', 'Report', 'Test Review']}
                title={''}
                component={'testReview'}
            />
            <QuestionNav
                currentTestComponent={currentTestComponent}
                toggleAnnotate={toggleAnnotate}
                toggleCalculator={toggleCalculator}
                toggleReference={toggleReference}
                toggleMore={toggleMore}
                isReviewMode={isReviewMode}
                testId={testId}
            />
            <main className={s.main}>
                {!showMap ? null : (
                    <div
                        onClick={toggleMap}
                        className={s.question_map}
                    >
                        <div className={s.question_container}>
                            <div className={s.map_content}>
                                <QuestionMap
                                    currentTestComponent={currentTestComponent}
                                    currentQuestionNumber={currentQuestionNumber}
                                    selectedAnswers={selectedAnswers}
                                    navigateToSpecificQuestion={navigateToSpecificQuestion}
                                    isReviewMode={isReviewMode}
                                    section={sections[currentTestComponent.section - 1]}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Question Main Body*/}
                {isLoading ? (
                    <LoadingSpinner
                        isLoading={isLoading}
                    />
                ) : (
                    questions[currentQuestionNumber] && (
                        <MainBody
                            section={sections[currentTestComponent.section - 1]}
                            testComponent={currentTestComponent}
                            questionNumber={currentQuestionNumber}
                            questionComponent={questions[currentQuestionNumber]}
                            setSelectedAnswers={setSelectedAnswers}
                            selectedAnswers={selectedAnswers}
                            showCalculator={showCalculator}
                            isReviewMode={isReviewMode}
                            performanceBySkills={performanceBySkills}
                        />
                    )

                )}
            </main>
            {/*<ChatWidget />*/}

            <Footer
                isFirstPage={false}
                questionNumber={currentQuestionNumber}
                numOfProblems={currentTestComponent.noOfQuestions}
                testType={currentTestComponent.testType}
                testNumber={currentTestComponent.testNumber}
                showMap={showMap}
                toggleMap={toggleMap}
                nextQuestion={nextQuestion}
                prevQuestion={prevQuestion}
                isReviewMode={isReviewMode}
            />
        </>
    )
}

TestReview.propTypes = {
    dispatch: PropTypes.func.isRequired,
    testByUserId: PropTypes.object,
    testsByUserId: PropTypes.array
}

const mapStateToProps = (store) => {
    return {
        testByUserId: store.test.testByUserId,
        fetchTestByUserId: fetchTestByUserId,
        testsByUserId: store.test.testsByUserId,
        fetchTestsByUserId: fetchTestsByUserId
    }
}

export default withRouter(connect(mapStateToProps)(TestReview));
