import React, { useEffect } from 'react';

export const Calculator = () => {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://www.desmos.com/api/v1.6/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6';
    //     script.async = true;
    //     document.body.appendChild(script);
    //
    //
    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    useEffect(() => {
        const elt = document.getElementById('calculator');
        const calculator = Desmos.GraphingCalculator(elt);
        calculator.setExpression({ });
    }, []);

    return (
        <div>
            <div id="calculator" style={{width: '600px', height: '400px'}}/>
            {/*<GraphingCalculator*/}
            {/*    attributes={{className: "calculator"}}*/}
            {/*    fontSize={18} keypad projectorMode*/}
            {/*>*/}

            {/*</GraphingCalculator>*/}
            {/*);*/}
        </div>
    );
}
