import React, {useEffect} from "react";
import {assignUsersToTests} from "../../../../../../utils/api";


export const Complete = ({data}) => {

    useEffect(() => {
        const assignUsersToTestsRequestPayload = {
            userIds: Object.keys(data.selectedUsers),
            testIds: Object.keys(data.selectedTests)
        }

        assignUsersToTests(assignUsersToTestsRequestPayload);

    }, []);

    return (
        <>
            <section className="flex flex-col justify-center items-center w-full gap-4 py-[36px]">
                <h2>Done!</h2>
            </section>
        </>
    );
};
