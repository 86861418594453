import React, {useState} from "react";
import {Table} from "./components/assignTest/components/helpers/Table";
import {renderSubComponent} from "./components/assignTest/components/helpers/TableHelperJs";
import PropTypes from "prop-types";
import {LoadingSpinner} from "../../Test/components/loading";
import {fetchUserInfo} from "../../../redux/actions/user";
import {fetchUsers} from "../../../redux/actions/admin";
import {getData, getToken} from "../../../utils/api";
import {timestampConverter} from "../../../utils/utils";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const AdminDashboard = (props) => {

    const [usersToTests, setUsersToTests] = useState({});

    const updateSelectedUsersToTests = async (row) => {
        // row.getToggleSelectedHandler();

        if (row.original) {
            const selectedRow = row.original;

            if (!usersToTests[selectedRow.userId]) {
                try {
                    getData(`/api/assigned-tests?userId=${selectedRow.userId}`, {authorization: getToken()})
                        .then(({data}) => {
                            data = data ? data : [];
                            const userTestsList = []

                            data.map(test => {
                                userTestsList.push({
                                    testId: test.testId,
                                    testName: `${test.testType} ${test.testNumber}`,
                                    assignedDate: timestampConverter(test.assignedDate),
                                    status: test.status,
                                    result: test.result ? [test.result.total, test.result.readingAndWriting, test.result.math] : null,
                                    summary: "we will add test analytics here!"
                                });
                            });

                            setUsersToTests((prevUsersToTests) => ({
                                ...prevUsersToTests,
                                [row.original.userId]: userTestsList,
                            }))

                        })

                } catch (error) {
                    console.error("Error fetching tests: ", error);
                }
            }
        }
    }

    const columns = React.useMemo(
        () => [
            {
                id: 'expander',
                header: () => null,
                cell: ({row}) => {
                    return row.getCanExpand() ? (
                        <button
                            {...{
                                onClick: (e) => {
                                    e.preventDefault();

                                    updateSelectedUsersToTests(row)
                                        .then(() => row.toggleExpanded())
                                },
                                style: {cursor: 'pointer'},
                            }}
                        >
                            {row.getIsExpanded() ? "\u2193" : "\u2192"}
                        </button>
                    ) : (
                        '🔵'
                    )
                },
            },
            {
                accessorFn: row => row.firstName,
                id: 'firstName',
                header: () => <span style={{color: "#2B2C2D"}}>First Name</span>,
                cell: info => info.getValue(),
                footer: props => props.column.id,
            },
            {
                accessorFn: row => row.lastName,
                id: 'lastName',
                cell: info => info.getValue(),
                header: () => <span style={{color: "#2B2C2D"}}>Last Name</span>,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => row.email,
                id: 'email',
                cell: info => info.getValue(),
                header: () => <span style={{color: "#2B2C2D"}}>Email</span>,
                footer: props => props.column.id,
            }
        ],
        []
    );

    return (
        props.users && props.userInfo ?
            <Table
                columns={columns}
                data={props.users || []}
                subData={usersToTests}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            /> :
            <LoadingSpinner/>
    )
}

AdminDashboard.propTypes = {
    sidebarOpened: PropTypes.bool,
    users: PropTypes.array,
    userInfo: PropTypes.object,
}

const mapStateToProps = (store) => {
    return {
        error: store.admin.error,
        users: store.admin.users,
        fetchUsers: fetchUsers,
        userInfo: store.user.userInfo,
        fetchUserInfo: fetchUserInfo
    }
}

export default withRouter(connect(mapStateToProps)(AdminDashboard));