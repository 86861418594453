import {getData} from "../../utils/api";

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';


export const fetchUsers = (role = null) => {
    return (dispatch) => {
        getData(`/api/users?role=${role === null ? "STUDENT" : role}`)
            .then(({ data }) => {
                data = data ? data : []
                dispatch({ type: FETCH_USERS_SUCCESS, payload: data });
            })
            .catch((error) => {
                dispatch({ type: FETCH_USERS_FAILURE, payload: error.message });
            });
    };
}