import React from "react";

export const CalculatorIcon = () => {
    return (
        <svg fill="#2B2C2D" height="30PX" width="30PX" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 512 512" >
		<g>
			<g>
				<path d="M402.316,0H109.684C83.917,0,62.953,20.963,62.955,46.728v418.543c0,25.765,20.963,46.728,46.729,46.728h292.631
					c25.767,0,46.731-20.963,46.731-46.728V46.728C449.045,20.963,428.083,0,402.316,0z M432.098,465.272
					c0,16.421-13.359,29.781-29.782,29.781H109.684c-16.421,0-29.782-13.359-29.782-29.781V46.728
					c0-16.421,13.36-29.781,29.782-29.781h292.632c16.421,0,29.782,13.359,29.782,29.781V465.272z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M395.378,45.194H116.622c-4.68,0-8.474,3.793-8.474,8.474v112.15c0,4.681,3.794,8.474,8.474,8.474h278.754
					c4.681,0,8.474-3.794,8.475-8.474V53.668C403.851,48.987,400.057,45.194,395.378,45.194z M386.904,157.344H125.096V62.142h261.807
					V157.344z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M142.599,209.856H131.73c-15.783,0-28.624,12.841-28.624,28.624v10.869c0,15.783,12.841,28.624,28.624,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C171.222,222.697,158.382,209.856,142.599,209.856z M154.275,249.349
					c0,6.438-5.238,11.676-11.676,11.676H131.73c-6.438,0-11.676-5.238-11.676-11.676v-10.869c0-6.438,5.238-11.676,11.676-11.676
					h10.869c6.438,0,11.676,5.238,11.676,11.676V249.349z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M221.823,209.856h-10.869c-15.782,0-28.622,12.841-28.622,28.624v10.869c0,15.783,12.84,28.624,28.622,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C250.446,222.697,237.606,209.856,221.823,209.856z M233.497,249.349
					c0,6.438-5.238,11.676-11.676,11.676h-10.869c-6.438,0-11.675-5.238-11.675-11.676v-10.869c0-6.438,5.237-11.676,11.675-11.676
					h10.869c6.438,0,11.676,5.238,11.676,11.676V249.349z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M301.046,209.856h-10.869c-15.782,0-28.622,12.841-28.622,28.624v10.869c0,15.783,12.84,28.624,28.622,28.624h10.869
					c15.783,0,28.622-12.841,28.622-28.624v-10.869C329.669,222.697,316.829,209.856,301.046,209.856z M312.72,249.349
					c0,6.438-5.237,11.676-11.675,11.676h-10.869c-6.438,0-11.675-5.238-11.675-11.676v-10.869c0-6.438,5.237-11.676,11.675-11.676
					h10.869c6.438,0,11.675,5.238,11.675,11.676V249.349z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M380.27,209.856h-10.869c-15.783,0-28.624,12.841-28.624,28.624v10.869c0,15.783,12.841,28.624,28.624,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C408.894,222.697,396.053,209.856,380.27,209.856z M391.946,249.349
					c0,6.438-5.238,11.676-11.676,11.676h-10.869c-6.438,0-11.676-5.238-11.676-11.676v-10.869c0-6.438,5.238-11.676,11.676-11.676
					h10.869c6.438,0,11.676,5.238,11.676,11.676V249.349z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M142.599,295.141H131.73c-15.783,0-28.624,12.841-28.624,28.624v10.869c0,15.783,12.841,28.624,28.624,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C171.222,307.982,158.382,295.141,142.599,295.141z M154.275,334.634
					c0,6.438-5.238,11.676-11.676,11.676H131.73c-6.438,0-11.676-5.238-11.676-11.676v-10.869c0-6.438,5.238-11.676,11.676-11.676
					h10.869c6.438,0,11.676,5.238,11.676,11.676V334.634z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M221.823,295.141h-10.869c-15.782,0-28.622,12.841-28.622,28.624v10.869c0,15.783,12.84,28.624,28.622,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C250.446,307.982,237.606,295.141,221.823,295.141z M233.497,334.634
					c0,6.438-5.238,11.676-11.676,11.676h-10.869c-6.438,0-11.675-5.238-11.675-11.676v-10.869c0-6.438,5.237-11.676,11.675-11.676
					h10.869c6.438,0,11.676,5.238,11.676,11.676V334.634z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M301.046,295.141h-10.869c-15.782,0-28.622,12.841-28.622,28.624v10.869c0,15.783,12.84,28.624,28.622,28.624h10.869
					c15.783,0,28.622-12.841,28.622-28.624v-10.869C329.669,307.982,316.829,295.141,301.046,295.141z M312.72,334.634
					c0,6.438-5.237,11.676-11.675,11.676h-10.869c-6.438,0-11.675-5.238-11.675-11.676v-10.869c0-6.438,5.237-11.676,11.675-11.676
					h10.869c6.438,0,11.675,5.238,11.675,11.676V334.634z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M142.599,380.427H131.73c-15.783,0-28.624,12.84-28.624,28.622v10.869c0,15.783,12.841,28.624,28.624,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C171.222,393.266,158.382,380.427,142.599,380.427z M154.275,419.918
					c0,6.438-5.238,11.676-11.676,11.676H131.73c-6.438,0-11.676-5.238-11.676-11.676v-10.869c0-6.437,5.238-11.675,11.676-11.675
					h10.869c6.438,0,11.676,5.238,11.676,11.675V419.918z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M221.823,380.427h-10.869c-15.782,0-28.622,12.84-28.622,28.622v10.869c0,15.783,12.84,28.624,28.622,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-10.869C250.446,393.266,237.606,380.427,221.823,380.427z M233.497,419.918
					c0,6.438-5.238,11.676-11.676,11.676h-10.869c-6.438,0-11.675-5.238-11.675-11.676v-10.869c0-6.437,5.237-11.675,11.675-11.675
					h10.869c6.438,0,11.676,5.238,11.676,11.675V419.918z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M301.046,380.427h-10.869c-15.782,0-28.622,12.84-28.622,28.622v10.869c0,15.783,12.84,28.624,28.622,28.624h10.869
					c15.783,0,28.622-12.841,28.622-28.624v-10.869C329.669,393.266,316.829,380.427,301.046,380.427z M312.72,419.918
					c0,6.438-5.237,11.676-11.675,11.676h-10.869c-6.438,0-11.675-5.238-11.675-11.676v-10.869c0-6.437,5.237-11.675,11.675-11.675
					h10.869c6.438,0,11.675,5.238,11.675,11.675V419.918z"/>
			</g>
		</g>
            <g>
			<g>
				<path d="M380.27,295.14h-10.869c-15.783,0-28.624,12.841-28.624,28.624v96.155c0,15.783,12.841,28.624,28.624,28.624h10.869
					c15.783,0,28.622-12.841,28.624-28.624v-96.155C408.894,307.981,396.053,295.14,380.27,295.14z M391.946,419.92
					c0,6.438-5.238,11.676-11.676,11.676h-10.869c-6.438,0-11.676-5.238-11.676-11.676v-96.155c0-6.438,5.238-11.676,11.676-11.676
					h10.869c6.438,0,11.676,5.238,11.676,11.676V419.92z"/>
			</g>
		</g>
	</svg>
    )
}