import {Question, Result, TestComponent} from "./models";
import {scoreConversionTable} from "./scoreConversationTable";

export const READING_WRITING = "Reading and Writing";
export const MATH = "Math";

export const populateQuestions = (data, answers) => {
    const questions = Array(data.length + 1);
    /**
     * To keep question numbers consistent with index number,
     * let's store an empty object at 0 index
     * For example, questionNumber = 2 should be at questions[2]
     */
    questions[0] = new Question(0, 0, "", "", "", []);

    data.forEach(datum => {
        questions[datum.questionNumber] = new Question(
            datum.section,
            datum.module,
            datum.title,
            datum.problem,
            datum.question,
            datum.options,
            datum.domain,
            datum.topic,
            datum.problemType,
            datum.hasImage,
            datum.difficulty,
            datum.explanation
        );

        answers[datum.questionNumber] = [datum.answer, datum.domain, datum.topic, datum.difficulty, datum.explanation];
    });

    return questions;
};

export const populateQuestionsAnswered = (questionsAnswered) => {
    const selectedAnswers = {};

    if (questionsAnswered !== undefined && questionsAnswered.length > 0) {
        questionsAnswered.forEach(questionAnswered => {
            /**
             Students will have the option to leave the section and start
             where they left off. We want to persist questions that have
             been answered on a section that hasn't been submitted.

             If the test hasn't been started, we wouldn't need to populate
             because 'questionsAnswered' field will be empty.

             Let's populate questions answered:
             {
           1 : "A",
           2 : "B"
           ...
         }
             */
            selectedAnswers[questionAnswered.questionNumber] = questionAnswered.answered;
        });
    }

    return selectedAnswers;
};

export const populateTestComponents = (test, isReviewMode = false, questionNum = null) => {
    const sections = test.sections;
    const components = Array(sections.length + 1);
    /**
     * To keep section numbers consistent with index number,
     * let's store an empty object at 0 index
     * For example, section = 2 should be at sections[2]
     */
    components[0] = new TestComponent("", 0, 0, "", 0, 0, 0, 0);

    if (isReviewMode) {
        sections.forEach(testComponent => {
            components[testComponent.section] = new TestComponent(
                test.testType ? test.testType : 'Test',
                test.testNumber,
                testComponent.section,
                testComponent.module,
                testComponent.title,
                0,
                0,
                testComponent.noOfQuestions,
                testComponent.noOfQsPerDomain,
                testComponent.noOfQsPerTopic,
                questionNum
            );
        });
    } else {
        sections.forEach(testComponent => {
            components[testComponent.section] = new TestComponent(
                test.testType ? test.testType : 'Test',
                test.testNumber,
                testComponent.section,
                testComponent.module,
                testComponent.title,
                testComponent.minutes,
                testComponent.seconds,
                testComponent.noOfQuestions,
                testComponent.noOfQsPerDomain,
                testComponent.noOfQsPerTopic,
                testComponent.currentQuestion === undefined ? 1 : testComponent.currentQuestion
            );
        });
    }

    return components;
};

export const calculateTestScore = (test) => {
    let readingAndWritingCorrectAns = 0;
    let mathCorrectAns = 0;

    test.sections.forEach(section => {
        if (section.title === READING_WRITING) {
            readingAndWritingCorrectAns += section.correctAnswers;
        } else {
            mathCorrectAns += section.correctAnswers;
        }
    })

    const defaultValue = 0;

    const readingAndWritingScore = (scoreConversionTable[test.testNumber] &&
            scoreConversionTable[test.testNumber][READING_WRITING] &&
            scoreConversionTable[test.testNumber][READING_WRITING][readingAndWritingCorrectAns]) ||
        defaultValue;

    const mathScore =
        (scoreConversionTable[test.testNumber] &&
            scoreConversionTable[test.testNumber][MATH] &&
            scoreConversionTable[test.testNumber][MATH][mathCorrectAns]) ||
        defaultValue;

    test.result = new Result(readingAndWritingScore + mathScore, readingAndWritingScore, mathScore);
}

export const testDocumentSectionsMapper = (sections) => {

    let list = [];

    sections.forEach(section => {
        list.push()
    })
}