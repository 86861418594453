import React from "react";
import {Col} from "reactstrap";
import {ErrorCircle} from "../../../../../images/ErrorCircle";
import s from './styles.scss';

export const NoData = ({content}) => {

    return (
        <Col className={s.container}>
            <ErrorCircle/>
            <div className={s.content}>{content}</div>
        </Col>
    )
}