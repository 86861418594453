import React, {useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {fetchTestsByUserId} from "../../../../../redux/actions/test";
import s from "./styles.scss";
import {NavDirection} from "../../../components/navDirection";
import {TestStatus} from "../../../../Test/models/AssignedTest";
import {TestsTable} from "../tables/TestsTable";

const Tests = (props) => {

    const [isAssignedTests, setIsAssignedTests] = useState(true);

    const toggleIsAssignedTests = () => {
        setIsAssignedTests(prevState => !prevState);
    }

    return (
        <>
            <NavDirection
                navs={['Dashboard', 'Tests']}
                title={''}
                //     <>
                //         <span>Digital SAT</span>
                //         <span style={{ paddingLeft: '2px', fontSize: 'smaller', verticalAlign: 'top' }}>&#x24C7;</span>
                //     </>
            />

            <div className={s.container}>
                <div className={`${s.select_container}`}>
                    <div
                        className={`${s.col} ${s.col_1} ${isAssignedTests ? s.selected_col : ''}`}
                        onClick={toggleIsAssignedTests}
                    >
                        Assigned Tests
                    </div>
                    <div
                        className={`${s.col} ${s.col_1} ${isAssignedTests ? '' : s.selected_col}`}
                        onClick={toggleIsAssignedTests}
                    >
                        Completed Tests
                    </div>
                </div>
                {isAssignedTests ?
                    <TestsTable
                        tests={props.testsByUserId.filter(test => test.status !== TestStatus.COMPLETED)}
                        areCompletedTests={false}
                    /> :
                    <TestsTable
                        tests={props.testsByUserId.filter(test => test.status === TestStatus.COMPLETED)}
                        areCompletedTests={true}
                    />}
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        testsByUserId: state.test.testsByUserId,
        fetchTestsByUserId: fetchTestsByUserId
    }
}

export default withRouter(connect(mapStateToProps, {fetchTestsByUserId})(Tests));
