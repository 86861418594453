import React from "react";
import {Switch, Route, Redirect} from "react-router";
import {HashRouter} from "react-router-dom";
import {connect} from "react-redux";
import Layout from "./components/layout/";
import ErrorPage from "./pages/error/";
import Register from "./pages/register/"
import Login from "./pages/login/";
import {logoutUser} from "../../redux/actions/auth";
import isAuthenticated from "../../redux/services/authService";
import Test from "../../components/Test/";
import "../../styles/main.scss";

const PrivateRoute = ({dispatch, component, ...rest}) => {
    if (!isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))) {
        dispatch(logoutUser());
        return (<Redirect to="/login"/>)
    } else {
        return (
            <Route {...rest} render={props => (React.createElement(component, props))}/>
        );
    }
};

// https://learnwithparam.com/blog/how-to-handle-query-params-in-react-router/
const App = (props) => {
    return (
        <>
            <HashRouter>
                <Switch>
                    <Route path="/" exact render={() => <Redirect to="/template/dashboard"/>}/>
                    <Route path="/template" exact render={() => <Redirect to="/template/dashboard"/>}/>
                    <PrivateRoute path="/template" dispatch={props.dispatch} component={Layout}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/error" exact component={ErrorPage}/>
                    <Route path="/tests/:testId" exact component={Test}/>
                    <Route path="/register" exact component={Register}/>
                    <Route component={ErrorPage}/>
                    <Route path='*' exact={true} render={() => <Redirect to="/error"/>}/>
                </Switch>
            </HashRouter>
        </>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
