import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {withRouter, Redirect} from "react-router";
import {connect} from "react-redux";
import {
    Button,
    FormGroup,
} from "reactstrap";

import {registerUser} from "../../../../redux/actions/register.js";
import hasToken from "../../../../utils/authService";
import s from "./styles.scss";

const Register = (props) => {
    const [state, setState] = useState(
        {
            firstName: '',
            lastName: '',
            grade: '',
            school: '',
            email: '',
            password: '',
            verifyPassword: ''
        }
    )

    const changeCred = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    const isFormValid = () => {
        const {firstName, lastName, grade, email, password, verifyPassword} = this.state

        return firstName && lastName && grade && email && password && verifyPassword
    }

    const doRegister = (event) => {
        event.preventDefault();
        props.dispatch(registerUser({
            creds: state,
            history: props.history,
        }))
    }

    const {from} = props.location.state || {from: {pathname: '/template'}}

    if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
        return (
            <Redirect to={from}/>
        );
    }

    return (
        <div
            style={{
                background: '#F5F5F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <div className={s.register_container}>
                <div style={{width: "473px"}} className={s.register_form_l}>
                    <div className={s.register_form_inner}>
                        {/* Top Header */}
                        <div>PrepNerds</div>
                        <h1 style={{paddingBottom: 30}}>Sign Up</h1>

                        <form onSubmit={(event => doRegister(event))}>
                            {/* Register with Email: First Name */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label>
                                        First Name
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="firstName"
                                        value={state.firstName}
                                        onChange={(event) => changeCred(event)}
                                        type="text"
                                        required
                                        name="firstName"
                                        // placeholder="Will Smith"
                                    />
                                </div>
                            </FormGroup>

                            {/* Register with Email: Last Name */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label>
                                        Last Name
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="lastName"
                                        value={state.lastName}
                                        onChange={(event) => changeCred(event)}
                                        type="text"
                                        required
                                        name="lastName"
                                    />
                                </div>
                            </FormGroup>

                            {/* Register with Email: Grade */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label>
                                        Grade
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="grade"
                                        value={state.grade}
                                        onChange={(event) => changeCred(event)}
                                        type="number"
                                        required
                                        name="grade"
                                    />
                                </div>
                            </FormGroup>

                            {/* Register with Email: School */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label>
                                        School
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="school"
                                        value={state.school}
                                        onChange={(event) => changeCred(event)}
                                        type="text"
                                        required
                                        name="school"
                                    />
                                </div>
                            </FormGroup>

                            {/* Register with Email: Email */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label>
                                        Email
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="email"
                                        value={state.email}
                                        onChange={(event) => changeCred(event)}
                                        type="email"
                                        required
                                        name="student@hackersprep.org"
                                        placeholder=""
                                    />
                                </div>
                            </FormGroup>

                            {/* Register with Email: Password */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label> Password
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="password"
                                        value={state.password}
                                        onChange={(event => changeCred(event))}
                                        type="password"
                                        required
                                        name="password"
                                        placeholder="Place your password here"
                                    />
                                </div>
                            </FormGroup>

                            {/* Register with Email: Verify Password */}
                            <FormGroup>
                                <div className={s.register_form_group}>
                                    <label> Verify Password
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="verifyPassword"
                                        value={state.verifyPassword}
                                        onChange={(event => changeCred(event))}
                                        type="password"
                                        required
                                        name="verifyPassword"
                                        placeholder="Verify your password here"
                                    />
                                </div>
                            </FormGroup>

                            {/* Sign Up button */}
                            <Button
                                type="submit"
                                className={`${s.rounded_btn} ${s.register_cta}`}
                                // disabled={!isFormValid}
                            >Sign Up</Button>


                            <div
                                className={s.rem_container}
                                style={{justifyContent: "center"}}
                            >
                                <Link
                                    className={s.link_style}
                                    to="/login"
                            >
                                Click here if you are an existing user!
                            </Link>
                            </div>

                        </form>

                    </div>
                </div>


            </div>
        </div>
    )
}

Register.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Register));
