import React from 'react';
import s from '../styles.scss';

export const MathDirection = () => {

    return (
        <>
            <div className={s.content}>
                <div>
                    The questions in this section address a number of important math skills.<br/>
                </div>
                <div>
                    Use of a calculator is permitted for all questions. A reference sheet, calculator, and these
                    directions can be accessed throughout the test.<br/>
                </div>
                <div>
                    Unless otherwise indicated:<br/>
                </div>
                    <ul>
                        <li>All variables and expressions represented real numbers.</li>
                        <li>Figures provided are drawn to scale.</li>
                        <li>All figures lie in a plane.</li>
                        <li>The domain of a given function
                            <math display={"inline"}>
                                <mi>f</mi>
                                <mtext>&nbsp;is the set of all real numbers&nbsp;</mtext>
                                <mi>x</mi>
                                <mtext>&nbsp;for which&nbsp;</mtext>
                                <mi>f</mi>
                                <mi>(</mi>
                                <mi>x</mi>
                                <mi>)</mi>
                                <mtext>&nbsp;is a real number.</mtext>
                                <mo lspace={"10px"}></mo>
                            </math>
                        </li>
                    </ul>

                <div>
                    For <b>multiple-choice questions</b>, solve each problem and choose the correct answer from the
                    choices provided.
                    Each multiple-choice question has a single correct answer.

                    For <b>student-produced response questions</b>, solve each problem and enter your answer as
                    described below:
                    <ul>
                        <li>If you find <b>more than one correct answer</b>, enter only one answer.</li>
                        <li>You can enter up to 5 characters for a <b>positive</b> answer and up to 6 characters
                            (including the negative sign) for a <b>negative</b> answer.
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}