import React from 'react';
import s from '../styles.scss';

export const ReadingAndWritingDirection = () => {

    return (
        <>
            <div className={s.content}>
                The questions in this section address a number of important reading and writing skills.
                Each question includes one or more passages, which may include a table or graph.
                <br/>
                Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).
                <br/>
                <br/>
                All questions in this section are multiple-choice with four answer choices. Each
                question has a single best answer.
            </div>
        </>
    )
}