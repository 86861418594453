export const SELECT_POSITION_SUCCESS = 'SELECT_POSITION_SUCCESS';
export const SELECT_POSITION_FAILURE = 'SELECT_POSITION_FAILURE';


export function selectPositionReceived(payload) {
    return {
        type: SELECT_POSITION_SUCCESS,
        payload,
    };
}

export function selectPositionFailed(payload) {
    return {
        type: SELECT_POSITION_FAILURE,
        payload,
    };
}