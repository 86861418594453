import React from "react";
import s from "../../styles.scss";

const Button = ({
                    step,
                    goBack,
                    nextStep
                }) => {

    return (
        <>
            {
                step === 4 ? null : (
                    step === 1 ? (
                        <>
                            <button
                                className={`${s.btn} ${s.disabled_btn}`}
                                onClick={goBack}
                            >
                                Back
                            </button>
                            <button
                                className={`${s.btn}`}
                                onClick={() => nextStep()}
                            >
                                Next
                            </button>
                        </>
                    ) : (
                        <>
                            <button className={`${s.btn}`} onClick={goBack}>Back</button>
                            <button className={`${s.btn}`} onClick={() => nextStep()}>
                                {step === 3 ? 'Submit' : 'Next'}
                            </button>
                        </>
                    )
                )
            }
        </>
    );
};

export default Button;
