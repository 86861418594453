import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import s from "../../styles.scss";

const steps = [
    {
        step: 1,
        title: 'Student Info',
    },
    {
        step: 2,
        title: 'Test Info',
    },
    {
        step: 3,
        title: 'Summary',
    }
];
export const Sidebar = ({currentStep, handleNextStep}) => {
    return (
        <aside className={`${s.left_container}`}>
            <nav>
                <ol className={s.steps_nav}>
                    {steps.map((step) => (
                        <li
                            className={`${s.step_nav} ${currentStep === step.step ? s.step_color_active: s.step_color}`}
                            key={step.step}
                        >
                            <button
                                className={`${currentStep === step.step ? s.text_step_active : s.text_step}`}
                                onClick={() => handleNextStep(step.step)}
                            >
                                {step.step}
                            </button>
                            <span className={s.text_body}>
								<p className={s.text_body_sm}>
                                    Step {step.step}
                                </p>
								<span className={s.text_body_md}>
                                    {step.title}
                                </span>
							</span>
                        </li>
                    ))}
                </ol>
            </nav>
        </aside>
    );
};
