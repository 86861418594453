import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import s from "./styles.scss";

export const ScoreAnalysisBySkillsDoughnutChart = ({topic, performanceResult}) => {
    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        labels: ['Correct', 'Incorrect', 'Skipped'],
        datasets: [
            {
                label: 'topic',
                data: performanceResult,
                backgroundColor: [
                    '#5E83DB',
                    '#EA449C',
                    '#919191'
                ],
                borderColor: [
                    '#F7F8FB',
                    '#F7F8FB',
                    '#F7F8FB'
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                // position: 'bottom',
            },
        }
    };

    const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const {ctx, data} = chart;
            const width = chart.getDatasetMeta(0).data[0].x;

            ctx.save();
            ctx.font = 'bolder 15px sans-serif';
            ctx.fillStyle = '#141414';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // (() => {
            //     const words = topic.split(' ');
            //     let str = '';
            //     let result = [];
            //
            //     let height = chart.getDatasetMeta(0).data[0].y;
            //
            //     height -= 200;
            //
            //     const maxWidthForText = width + 300;
            //     for (let i = 0; i < words.length; i++) {
            //         str = str + words[i] + ' ';
            //         const measuredTxt = ctx.measureText(str);
            //         const measuredTxtWidth = measuredTxt.width;
            //
            //         if ((measuredTxtWidth > maxWidthForText && i > 0) || i === words.length - 1) {
            //             result.push(str);
            //             str = '';
            //         }
            //     }
            //
            //     if (result.length > 1) height -= 8;
            //     for (let i = 0; i < result.length; i++) {
            //         ctx.fillText(result[i], width, height + i * 13);
            //     }
            // })();

            ctx.fillText(topic, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 100);

            ctx.restore();
        }
    }

    return (
        <>
            <Doughnut
                data={data}
                options={options}
                // plugins={[textCenter]}
                className={s.chart}
            />
        </>
    )
}