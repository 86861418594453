import React from "react";
import PropTypes from "prop-types";
import {NavLink, withRouter} from "react-router-dom";
import s from "./styles.scss";
import {logoutUser} from "../../../../redux/actions/auth";
import {connect} from "react-redux";

const DropdownMenu = (props) => {
    const doLogout = () => {
        props.dispatch(logoutUser());
    }

    const handleContainerClick = () => {
        if (props.index === 'logout') {
            doLogout();
        } else {
            if (props.toggleDropdown) {
                props.toggleDropdown();
            }

            if (props.onActiveSidebarItemChange) {
                props.onActiveSidebarItemChange(props.index);
            }
        }
    };

    return (
        <li className={s.container} onClick={handleContainerClick}>
            <NavLink
                to={props.link}
                activeClassName={s.dropdown_active}
                exact={props.exact}
                target={props.target}

            >
                <div className={s.dropdown_item}>
                    {
                        typeof props.imgSrc === 'string' ?
                        <img src={props.imgSrc} alt={'dropdown image'}/> :
                        <props.imgSrc />
                    }
                    {props.header}
                </div>
            </NavLink>
        </li>
    );
};

DropdownMenu.propTypes = {
    header: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    childrenLinks: PropTypes.array,
    iconName: PropTypes.object,
    className: PropTypes.string,
    badge: PropTypes.string,
    label: PropTypes.string,
    activeItem: PropTypes.string,
    isHeader: PropTypes.bool,
    index: PropTypes.string,
    deep: PropTypes.number,
    onActiveSidebarItemChange: PropTypes.func,
    labelColor: PropTypes.string,
    exact: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (store) => {
    return {
        dispatch: store.dispatch,
    };
};

export default withRouter(connect(mapStateToProps)(DropdownMenu));