import React from "react";
import {TestStatus} from "../../../../Test/models/AssignedTest";
import {timestampConverter} from "../../../../../utils/utils";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {MATH, READING_WRITING} from "../../../../Test/utils/utils";
import s from './styles.scss';


export const ScoreResultsChart = ({tests}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        plugins: {
            title: {
                display: false,
                text: 'Total Digital SAT Score Stacked Bar Chart'
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
                min: 5,
                max: 6
            },
            y: {
                stacked: true,
                max: 1600
            },
        },
    };

    const labels = [];
    const scores = {
        readingAndWriting: [],
        math: []
    }

    if (tests && tests.length > 0) {
        tests.filter(t => t.status === TestStatus.COMPLETED).map((test, idx) => {
            labels.push([`${test.testType} ${test.testNumber}`, timestampConverter(test.completedDate)]);
            scores.readingAndWriting.push(test.result.readingAndWriting);
            scores.math.push(test.result.math)

        });
    }

    const data = {
        labels,
        datasets: [
            {
                label: READING_WRITING,
                data: scores.readingAndWriting,
                backgroundColor: '#5E83DB',
                stack: 'Stack 0',
            },
            {
                label: MATH,
                data: scores.math,
                backgroundColor: '#03CFC4',
                stack: 'Stack 0',
            }
        ],
    };

    return (
        <>
            {
                tests && tests.length > 0 ? (
                    <Bar
                        styles={s.bar_chart}
                        options={options}
                        data={data}
                    />
                ) : null
            }
        </>
    )
}