import React from 'react';
import cd from "./styles.scss";
import {TimerIcon} from "../../../../images/TimerIcon";

export const Countdown = ({countdown, hideTimer}) => {
    return (
        <>
            {hideTimer ? (
                <TimerIcon/>
            ) : (
                <div className={cd.countdown}>
                    <div className={`${countdown.minutes <= 2 ? cd.time_almost_up : ''}`}>
                        {countdown.minutes <= 10 ? `0${countdown.minutes}` : countdown.minutes}
                    </div>
                    <div className={`${countdown.minutes <= 2 ? cd.time_almost_up : ''}`}>:</div>
                    <div className={`${countdown.minutes <= 2 ? cd.time_almost_up : ''}`}>
                        {countdown.seconds < 10 ? `0${countdown.seconds}` : countdown.seconds}
                    </div>
                </div>
            )}
        </>
    )
};
