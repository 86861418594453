import React from 'react';
import s from "./styles.scss";

export const Annotate = ({
                             toggleAnnotate,
                         }) => {

    return (
        <div className={s.wrapper}>
            <div className={s.header}>
                <span><b>New Annotation:</b> "emissions"</span>
                <div
                    onClick={toggleAnnotate}
                    className={s.close_button}>
                    <span>CLOSE</span>
                    <span className={s.close_x}>x</span>
                </div>
            </div>
            <div className={s.body}>
                <div className={s.info}>
                    <div className={s.single_info}>
                        <span>Highlight color:</span>
                        <div className={s.highlight}/>
                    </div>
                    <div className={s.single_info}>
                        <span>Underline style:</span>
                        <div className={s.square}/>
                    </div>
                </div>
                <textarea className={s.textarea}/>
                <div className={s.buttons}>
                    <div className={s.save_button}>Save</div>
                    <div className={s.cancel_button}>Cancel</div>
                </div>
            </div>
        </div>
    );
}