import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import Widget from "../../../components/widget";
import s from "../../../styles.scss";
import { Editor } from "@tinymce/tinymce-react";

export const CreateTest = () => {
    const editorRef = useRef(null);
    const [content, setContent] = useState("");
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    const handleSave = () => {
        // Perform your save operation with the 'content' variable
        // console.log("Saving content:",  editorRef.current.getRootElement().getElementsByClassName("toastui-editor md-mode"));
    };

    const handleChange = (value) => {
        setContent(value);
    };

    // const handleClickButton = () => {
    //
    //     // editorRef.current.getRootElement().classList .add("my-editor-root");
    //
    //
    //     console.log("WOW: ", editorRef.current.getRootElement().className)
    //
    // };


    return (
        <div style={{ position: "relative", flexGrow: 1, padding: "24px 0 68px" }}>
            <h2 style={{ marginBottom: "2em" }}>Create a Test</h2>

            <Row className={`${s.container_row}`}>
                <Row className={`${s.row_chunk}`}>
                    <Widget>
                        <div className={`${s.chart_header}`}>
                            {/*<Editor*/}
                            {/*    previewStyle="horizontal"*/}
                            {/*    height="400px"*/}
                            {/*    initialEditType="markdown"*/}
                            {/*    initialValue=""*/}
                            {/*    ref={editorRef}*/}
                            {/*/>*/}
                            <Editor
                                apiKey="your-api-key"
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                initialValue="<p>This is the initial content of the editor.</p>"
                                init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "code",
                                        "help",
                                        "wordcount",
                                    ],
                                    toolbar:
                                        "undo redo | blocks | " +
                                        "bold italic forecolor | alignleft aligncenter " +
                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                        "removeformat | help",
                                    content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                            />
                            <button onClick={log}>Log editor content</button>
                        </div>
                        <button onClick={handleSave}>Save</button>
                        <div>{content}</div>
                    </Widget>
                </Row>
            </Row>
        </div>
    );
};
