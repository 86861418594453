import {getData} from "../../utils/api";

export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILURE = 'FETCH_USER_INFO_FAILURE';

export const fetchUserInfo = () => {
    return (dispatch) => {
        getData(`/api/users/${localStorage.getItem("userId")}`)
            .then(({ data }) => {
                data = data ? data : {}
                dispatch({ type: FETCH_USER_INFO_SUCCESS, payload: data });
            })
            .catch((error) => {
                dispatch({ type: FETCH_USER_INFO_FAILURE, payload: error.message });
            });
    };
};
