import React, {useEffect, useState} from 'react';

import {LoadingSpinner} from '../../../loading'
import s from "../sectionOver/styles.scss";
import {withRouter} from "react-router";

const TestOver = ({history}) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        const timer = setTimeout(() => {
            setIsLoading(false);
            history.push('/template/dashboard');
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className={s.container}>
                <div className={s.wrapper}>
                    <span className={s.title}>Good Job!</span>
                    <span className={s.desc}>Calculating your score</span>
                    <span className={s.desc}>You'll be redirected to your dashboard</span>
                    <span className={s.desc}>Do not refresh this page.</span>
                    {isLoading ? (
                        <LoadingSpinner isLoading={isLoading}/>
                    ) : null}
                </div>
            </div>
        </>

    )
}

export default withRouter(TestOver);
