
export class Question {
    constructor(section, module, title, problem, question, options, domain = null, topic = null, problemType = null, hasImage = null, difficulty = null, explanation = null) {
        this.section = section;
        this.module = module;
        this.title = title;
        this.problem = problem;
        this.question = question;
        this.options = options;
        this.domain = domain;
        this.topic = topic;
        this.problemType = problemType;
        this.hasImage = hasImage;
        this.difficulty = difficulty;
        this.explanation = explanation;
    }
}

// Use this to generate test components
export class TestComponent {
    constructor(testType = 'Test', testNumber, section, module, title, minutes, seconds, noOfQuestions, noOfQsPerDomain, noOfQsPerTopic, currentQuestion = 1) {
        this.testType = testType;
        this.testNumber = testNumber
        this.section = section;
        this.module = module;
        this.title = title;
        this.minutes = minutes;
        this.seconds = seconds;
        this.noOfQuestions = noOfQuestions;
        this.noOfQsPerDomain = noOfQsPerDomain;
        this.noOfQsPerTopic = noOfQsPerTopic;
        this.currentQuestion = currentQuestion;
    }
}

// Use this class to generate questionAnswered within AnswersToBeSubmitted
export class QuestionAnswered {
    constructor(questionNumber, answered, correctAnswer, domain = null, topic = null, difficulty= null, explanation = null) {
        this.questionNumber = questionNumber;
        this.answered = answered;
        this.correctAnswer = correctAnswer;
        this.domain = domain;
        this.topic = topic
        this.difficulty = difficulty;
        this.explanation = explanation;
    }
}

export class Result {
    constructor(total = 0, readingAndWriting = 0, math = 0) {
        this.total = total;
        this.readingAndWriting = readingAndWriting;
        this.math = math;
    }
}

export class TestDocument {
    constructor(data) {
        this.testId = data.testId;
        this.testType = data.testType;
        this.testNumber = data.testNumber;
        this.userId = data.userId;
        this.status = data.status;
        this.assignedDate = data.assignedDate;
        this.startedDate = data.startedDate;
        this.completedDate = data.completedDate;
        this.numOfSections = data.numOfSections;
        this.currentSection = data.currentSection;
        this.sections = data.sections;
        this.result = data.result;
    }
}

export class CompletedSection {
    constructor(
        testComponent, selectedAnswers, answers, correctAnswers = 0,
        wrongAnswers = 0, unanswered = 0, areasToImprove = {}
    ) {
        this.section = testComponent.section;
        this.module = testComponent.module;
        this.title = testComponent.title;
        this.minutes = testComponent.minutes;
        this.seconds = testComponent.seconds;
        this.noOfQuestions = testComponent.noOfQuestions;
        this.noOfQsPerDomain = testComponent.noOfQsPerDomain;
        this.noOfQsPerTopic = testComponent.noOfQsPerTopic;

        const questionsAnswered =
            testComponent.questionsAnswered === undefined || testComponent.questionsAnswered.length === 0 ?
                Array(answers.length) : testComponent.questionsAnswered;

        // TODO: #56. remove this and refactor
        // questionsAnswered[0] = new QuestionAnswered(0, null, null, null)

        Object.entries(answers).forEach(([questionNumber, list]) => {
            console.log("hohoho: ", list)
            const qNum = parseInt(questionNumber);
            const selectedAnswer =
                selectedAnswers[qNum] !== undefined ? selectedAnswers[qNum] : null;

            const [answer, domain, topic, difficulty, explanation] = list;
            if (selectedAnswer !== null && selectedAnswer === answer) correctAnswers += 1
            else {
                if (selectedAnswer === null) unanswered += 1;
                else wrongAnswers += 1;

                areasToImprove[topic] = areasToImprove.hasOwnProperty(topic) ? areasToImprove[topic] + 1 : 1;
            }

            /**
             questionsAnswered: [
             {
                    questionNumber: 1,
                    answered: "C",
                    correctAnswer: "C",
                    domain: ""
                    topic: "LI"
                }
             ]
             */

            // TODO: #57. Add 'domain'
            questionsAnswered[qNum] = new QuestionAnswered(qNum, selectedAnswer, answer, domain, topic, difficulty, explanation);
        })
        this.correctAnswers = correctAnswers;
        this.wrongAnswers = wrongAnswers;
        this.unanswered = unanswered;
        this.areasToImprove = areasToImprove;
        this.questionsAnswered = questionsAnswered;
    }
}

class Enum {
    constructor(enumObject) {
        this.enumObject = enumObject;
        this.reverseLookup = {};

        // Populate reverse lookup
        for (const [key, value] of Object.entries(enumObject)) {
            this[key] = value;
            this.reverseLookup[value] = key;
        }
    }

    getValueByKey(key) {
        return this.enumObject[key];
    }

    getKeyByValue(value) {
        return this.reverseLookup[value];
    }
}


export const mTopicsToDomains = {
    LE1V: "Algebra",
    LE2V: "Algebra",
    LF: "Algebra",
    LI: "Algebra",

    EE: "Advanced Math",
    NE1V: "Advanced Math",
    NE2V: "Advanced Math",
    NF: "Advanced Math",
    STLE2V: "Advanced Math",

    RRPRU: "Problem-Solving and Data Analysis",
    PCT: "Problem-Solving and Data Analysis",
    OVD: "Problem-Solving and Data Analysis",
    TVD: "Problem-Solving and Data Analysis",
    PCP: "Problem-Solving and Data Analysis",
    IFSSAME: "Problem-Solving and Data Analysis",
    ESC: "Problem-Solving and Data Analysis",

    AV: "Geometry and Trigonometry",
    LAT: "Geometry and Trigonometry",
    RTAT: "Geometry and Trigonometry",
    CIR: "Geometry and Trigonometry",
}

export const rwTopicsToDomains = {
    WIC: "Craft and Structure",
    TSP: "Craft and Structure",
    CTC: "Craft and Structure",

    CIAD: "Information and Ideas",
    CETQ: "Information and Ideas",
    INF: "Information and Ideas",

    BND: "Standard English Conventions",
    FSS: "Standard English Conventions",

    RS: "Expression of Ideas",
    TRNS: "Expression of Ideas",
}

const readingAndWritingDomainsToTopics = new Enum({
        CRAFT_AND_STRUCTURE: new Enum({
            WIC: "Words in Context",
            TSP: "Text Structure and Purpose",
            CTC: "Cross-Text Connections",
        }),
        INFORMATION_AND_IDEAS: new Enum({
            CIAD: "Central Ideas and Details",
            CETQ: "Command of Evidence (Textual/Quantitative)",
            INF: "Inferences",
        }),
        STANDARD_ENGLISH_CONVENTIONS: new Enum({
            BND: "Boundaries",
            FSS: "Form, Structure, and Sense",
        }),
        EXPRESSION_OF_IDEAS: new Enum({
            RS: "Rhetorical Synthesis",
            TRNS: "Transitions"
        })
    }
)

export const MathDomains = {
    // Algebra - 13-15 Questions / ~35%
    ALGEBRA: new Enum({
            LE1V: 'Linear Equation 1 Variable',
            LE2V: 'Linear Equation 2 Variables',
            LF: 'Linear Function',
            LI: 'Linear Inequalities',
        }
    ),

    // Advanced Math - 13-15 Questions / ~35%
    ADVANCED_MATH: new Enum({
            EE: 'Equivalent Expressions',
            NE1V: 'Nonlinear Equations in 1 Variable',
            NE2V: 'Nonlinear Equations in 2 Variables',
            NF: 'Nonlinear Functions',
            STLE2V: 'System of Equations in 2 Variables'
        }
    ),

    // Problem-solving and Data Analysis - 5-7 Questions / ~15%
    PROBLEM_SOLVING_AND_DATA_ANALYSIS: new Enum({
            RRPRU: 'Ratio, Rate, Proportions',
            PCT: 'Percentages',
            OVD: 'One-Variable Data',
            TVD: 'Two-Variable Data',
            PCP: 'Probability and Conditional Probability',
            IFSSAME: 'Inference from Sample Statistics and Margin of Error',
            ESC: 'Evaluating Statistical Claims',
        }
    ),

    // Geometry and Trigonometry - 5-7 Questions / ~15%
    GEOMETRY_AND_TRIGONOMETRY: new Enum({
            AV: 'Area and Volume',
            LAT: 'Lines, Angles, and Triangles',
            RTAT: 'Right Triangles and Trigonometry',
            CIR: 'Circles'
        }
    )
}

export const combinedTopicEnums = new Enum({
    // Math
    LE1V: 'Linear Equation 1 Variable',
    LE2V: 'Linear Equation 2 Variables',
    LF: 'Linear Function',
    LI: 'Linear Inequalities',
    EE: 'Equivalent Expressions',
    NE1V: 'Nonlinear Equations in 1 Variable',
    NE2V: 'Nonlinear Equations in 2 Variables',
    NF: 'Nonlinear Functions',
    STLE2V: 'System of Equations in 2 Variables',
    RRPRU: 'Ratio, Rate, Proportions',
    PCT: 'Percentages',
    OVD: 'One-Variable Data',
    TVD: 'Two-Variable Data',
    PCP: 'Probability and Conditional Probability',
    IFSSAME: 'Inference from Sample Statistics and Margin of Error',
    ESC: 'Evaluating Statistical Claims',
    AV: 'Area and Volume',
    LAT: 'Lines, Angles, and Triangles',
    RTAT: 'Right Triangles and Trigonometry',
    CIR: 'Circles',

    // Reading & Writing
    WIC: "Words in Context",
    TSP: "Text Structure and Purpose",
    CTC: "Cross-Text Connections",
    CIAD: "Central Ideas and Details",
    CETQ: "Command of Evidence (Textual/Quantitative)",
    INF: "Inferences",
    BND: "Boundaries",
    FSS: "Form, Structure, and Sense",
    RS: "Rhetorical Synthesis",
    TRNS: "Transitions"
});

export const MathDomainEnum = new Enum({
        ALGEBRA: 'Algebra',
        ADVANCED_MATH: 'Advanced Math',
        PROBLEM_SOLVING_AND_DATA_ANALYSIS: 'Problem-Solving and Data Analysis',
        GEOMETRY_AND_TRIGONOMETRY: 'Geometry and Trigonometry'
    }
)

export const ReadingAndWritingDomainEnum = new Enum({
        INFORMATION_AND_IDEAS: 'Information and Ideas',
        CRAFT_AND_STRUCTURE: 'Craft and Structure',
        EXPRESSION_OF_IDEAS: 'Expression of Ideas',
        STANDARD_ENGLISH_CONVENTIONS: 'Standard English Conventions'
    }
)

// TODO: #45. Revisit this whether to combine or not.
export const combinedDomainEnum = new Enum({
        INFORMATION_AND_IDEAS: 'Information and Ideas',
        CRAFT_AND_STRUCTURE: 'Craft and Structure',
        EXPRESSION_OF_IDEAS: 'Expression of Ideas',
        STANDARD_ENGLISH_CONVENTIONS: 'Standard English Conventions',
        ALGEBRA: 'Algebra',
        ADVANCED_MATH: 'Advanced Math',
        PROBLEM_SOLVING_AND_DATA_ANALYSIS: 'Problem-Solving and Data Analysis',
        GEOMETRY_AND_TRIGONOMETRY: 'Geometry and Trigonometry'
    }
)


// TODO: #50 replace this
export const test_status = new Enum({
    ASSIGNED: 'Assigned',
    NOT_STARTED: 'Not Started',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed'
});


