import React, {useEffect, useRef, useMemo} from 'react';
import * as d3 from 'd3';

const MARGIN = { top: 30, right: 30, bottom: 50, left: 50 };
const WIDTH = 400;
const HEIGHT = 400;

export const LineChart = ({data, chartInfo}) => {
    data = [
        {x: 0, y: 43},
        {x: 1, y: 40},
        {x: 2, y: 33},
        {x: 3, y: 37},
        {x: 4, y: 40},
        {x: 5, y: 33},
        {x: 6, y: 24},
        {x: 7, y: 14},
        {x: 8, y: 14},
        {x: 9, y: 5}
    ]

    chartInfo = {
        title: 'Reported Food Related Illnesses since 2013',
        xLabel: 'Number of food related Illnesses',
        yLabel: 'Years since 2000'
    }

    const axesRef = useRef(null);
    const boundsWidth = WIDTH - MARGIN.right - MARGIN.left;
    const boundsHeight = HEIGHT - MARGIN.top - MARGIN.bottom;

    // Y axis
    const [min, max] = d3.extent(data, (d) => d.y);
    const yScale = useMemo(() => {
        return d3.scaleLinear().domain([0, max || 0]).range([boundsHeight, 0]);
    }, [data, HEIGHT]);

    // X axis
    const [xMin, xMax] = d3.extent(data, (d) => d.x);
    const xScale = useMemo(() => {
        return d3.scaleLinear().domain([0, xMax || 0]).range([0, boundsWidth]);
    }, [data, WIDTH]);

    // Render the X and Y axis using d3.js, not react
    useEffect(() => {
        const svgElement = d3.select(axesRef.current);
        svgElement.selectAll('*').remove();

        // X Axis
        const xAxisGenerator = d3.axisBottom(xScale);
        svgElement
            .append('g')
            .attr('transform', `translate(0, ${boundsHeight})`)
            .call(xAxisGenerator)
            .append('text')
            .attr('x', boundsWidth / 2)
            .attr('y', 0)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .text('X Axis Label');

        // Y Axis
        const yAxisGenerator = d3.axisLeft(yScale);
        svgElement
            .append('g')
            .call(yAxisGenerator)
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -boundsHeight / 2)
            .attr('x', 0)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .text('Y Axis Label');


        // Y Gridlines
        svgElement
            .append('g')
            .attr('class', 'grid')
            .style('color', '#D6D6D6')
            .call(
                d3
                    .axisLeft(yScale)
                    .tickSize(-boundsWidth)
                    .tickFormat('')
            );

        // Title
        svgElement
            .append('text')
            .attr('x', boundsWidth / 2)
            .attr('y', -40)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-size', '16px')
            .style('color', '#2B2C2D')
            .text(chartInfo.title);
    }, [xScale, yScale, boundsHeight, boundsWidth]);

    // Build the line
    const lineBuilder = d3
        .line()
        .x((d) => xScale(d.x))
        .y((d) => yScale(d.y));

    const linePath = lineBuilder(data);
    if (!linePath) {
        return null;
    }

    const allShapes = data.map((d, i) => {
        return (
            <circle
                key={i}
                r={2}
                cx={xScale(d.x)}
                cy={yScale(d.y)}
                opacity={1}
                stroke="#2B2C2D"
                fill="#2B2C2D"
                strokeWidth={1}
            />
        );
    });

    return (
        <div>
            <svg width={WIDTH} height={HEIGHT}>
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
                >
                    <path
                        d={linePath}
                        opacity={1}
                        stroke="#2B2C2D"
                        fill="none"
                        strokeWidth={2}
                    />
                    {allShapes}
                </g>
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    ref={axesRef}
                    transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
                />
            </svg>
        </div>
    );
};