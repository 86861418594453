import React from "react";
import {Column, Table} from "@tanstack/react-table";

export const DebouncedInput = ({
                                   value: initialValue,
                                   onChange,
                                   debounce = 500,
                                   ...props
                               }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}


export const Filter = ({
                           column,
                           table,
                       }: {
    column: Column<any, any>
    table: Table<any>
}) => {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    return typeof firstValue === 'number' ? (
        <div style={{display: "flex", justifyContent: "center",  padding: "0.5rem 0 0.5rem 0", color: "#0A3B6B"}}>
            <input
                type="number"
                value={((column.getFilterValue() as any)?.[0] ?? '') as string}
                onChange={e =>
                    column.setFilterValue((old: any) => [e.target.value, old?.[1]])
                }
                placeholder={`Min`}
                style={{maxWidth: "4rem", borderRadius: "0.2rem", justifyContent: "center", textAlign: "center"}}
            />
            <span style={{padding: "0.3rem"}}/>
            <input
                type="number"
                value={((column.getFilterValue() as any)?.[1] ?? '') as string}
                onChange={e =>
                    column.setFilterValue((old: any) => [old?.[0], e.target.value])
                }
                placeholder={`Max`}
                style={{maxWidth: "4rem", borderRadius: "0.2rem", justifyContent: "center", textAlign: "center", color: "#0A3B6B"}}
            />
        </div>
    ) : (
        <div style={{display: "flex", justifyContent: "center", padding: "0.5rem 0 0.5rem 0"}}>
            <input
                type="text"
                value={(column.getFilterValue() ?? '') as string}
                onChange={e => column.setFilterValue(e.target.value)}
                placeholder={`Search...`}
                style={{
                    display: "flex",
                    maxWidth: "6rem",
                    borderRadius: "0.2rem",
                    justifyContent: "center",
                    textAlign: "center"
                }}
            />
        </div>
    )
}