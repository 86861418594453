import React, {useEffect, useState} from 'react';

import {LoadingSpinner} from '../../../loading'
import s from "./styles.scss";

export const SectionOver = ({
                                submitSection,
                                submitAnswers,
                                timeEnded,
                                setTimeEnded,
                                resetTimer,
                                handleIsSectionOver
                            }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        submitAnswers()

        setIsLoading(true);
        handleIsSectionOver()

        const timer = setTimeout(() => {
            submitSection()
            setTimeEnded(!timeEnded);
            setIsLoading(false);
            resetTimer();
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className={s.container}>
                <div className={s.wrapper}>
                    <span className={s.title}>This Module Is Over</span>
                    <span className={s.desc}>All your work has been saved.</span>
                    <span className={s.desc}>You'll move on automatically in just a moment.</span>
                    <span className={s.desc}>Do not refresh this page.</span>
                    {isLoading ? (
                        <LoadingSpinner isLoading={isLoading}/>
                    ) : null}
                </div>
            </div>
        </>

    )
}
