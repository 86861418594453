import {getData} from "../../utils/api";

export const FETCH_TESTS_SUCCESS = 'FETCH_TESTS_SUCCESS';
export const FETCH_TESTS_FAILURE = 'FETCH_TESTS_FAILURE';
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS';
export const UPDATE_TEST_FAILURE = 'UPDATE_TEST_FAILURE';
export const FETCH_ASSIGNED_TESTS_BY_USERID_SUCCESS = 'FETCH_ASSIGNED_TESTS_BY_USERID_SUCCESS';
export const FETCH_ASSIGNED_TESTS_BY_USERID_FAILURE = 'FETCH_ASSIGNED_TESTS_BY_USERID_FAILURE';
export const FETCH_ASSIGNED_TEST_BY_USERID_SUCCESS = 'FETCH_ASSIGNED_TEST_BY_USERID_SUCCESS';
export const FETCH_ASSIGNED_TEST_BY_USERID_FAILURE = 'FETCH_ASSIGNED_TEST_BY_USERID_FAILURE';
export const UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ_SUCCESS = 'UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ_SUCCESS';
export const FETCH_ASSIGNED_TESTS_BY_USERID_OBJ_FAILURE = 'FETCH_ASSIGNED_TESTS_BY_USERID_OBJ_FAILURE';
export const UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ = 'UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ';
export const RESET_TESTS_STATE = 'RESET_TESTS_STATE';

export const fetchTests = () => {
    return (dispatch) => {
        getData(`/api/all-tests`)
            .then(({ data }) => {
                data = data ? data : []
                dispatch({ type: FETCH_TESTS_SUCCESS, payload: data });
            })
            .catch((error) => {
                dispatch({ type: FETCH_TESTS_FAILURE, payload: error.message });
            });
    };
};

export const resetAllRenderedTests = () => {
    return (dispatch) => {
        dispatch({ type: 'RESET_TESTS_STATE' });
    }
}

export const fetchTestsByUserId = (userId = null) => {
    return (dispatch) => {
        getData(`/api/assigned-tests?userId=${userId === null ? localStorage.getItem("userId") : userId}`)
            .then(({ data }) => {
                data = data ? data : []

                // TODO: to make this more efficient, use an object with key = testId, value = payload
                /**
                 * {
                 *     t01H1SGH19YGXBGV33SN9XV1W36: {},
                 *     t01H1SGH19YGXBGV33SN9XV1W33: {}
                 * }
                 */
                dispatch({type: FETCH_ASSIGNED_TESTS_BY_USERID_SUCCESS, payload: data});
            })
            .catch((error) => {
                dispatch({ type: FETCH_ASSIGNED_TESTS_BY_USERID_FAILURE, payload: error.message });
            });
    };
};

// export const fetchTestsByUserIdObj = (userId = null) => {
//     return (dispatch, getState) => {
//         getData(`/api/assigned-tests?userId=${userId === null ? localStorage.getItem("userId") : userId}`)
//             .then(({ data }) => {
//                 data = data ? data : [];
//
//                 const currentState = getState();
//                 const currentTestByUserIdObj = currentState.test.testByUserIdObj;
//                 // console.log("currentTestByUserIdObj: ", currentTestByUserIdObj )
//
//                 const updatedTestByUserIdObj = {
//                     ...currentTestByUserIdObj,
//                     [userId]: data,
//                 };
//
//                 // console.log("updatedTestByUserIdObj: ", updatedTestByUserIdObj )
//                 // console.log("currentTestByUserIdObj2: ", currentTestByUserIdObj )
//
//                 dispatch({ type: UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ_SUCCESS, payload: updatedTestByUserIdObj });
//             })
//             .catch((error) => {
//                 dispatch({ type: FETCH_ASSIGNED_TESTS_BY_USERID_OBJ_FAILURE, payload: error.message });
//             });
//     };
// };

export const fetchTestByUserId = (testId, userId = null) => {
    return (dispatch) => {
        getData(`/api/assigned-tests/${testId}?userId=${userId === null ? localStorage.getItem("userId") : userId}`)
            .then(({ data }) => {
                data = data ? data : {}
                dispatch({type: FETCH_ASSIGNED_TEST_BY_USERID_SUCCESS, payload: data});
            })
            .catch((error) => {
                dispatch({ type: FETCH_ASSIGNED_TEST_BY_USERID_FAILURE, payload: error.message });
            });
    };
};

// export const updateTest = (testId, request) => {
//     return (dispatch) => {
//         putData(`/api/assigned-tests/${testId}/reset`, request)
//             .then(r => {
//                 dispatch({ type: UPDATE_TEST_SUCCESS, payload: r });
//             })
//             .catch(e =>{
//                 dispatch({ type: UPDATE_TEST_FAILURE, payload: e.message });
//             });
//     }
// }