import React from "react";

class ReportErrorIcon extends React.Component {

    render() {
        return (
            <div style={{marginRight: "1rem"}}>
                <svg width="40" height="40" viewBox="-3 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.5 16.75V0.5H8.5961L8.9961 2.5H14.5V11.5H8.40385L8.00385 9.49995H1.99997V16.75H0.5ZM9.64997 9.99998H13V3.99998H7.74997L7.34998 1.99998H1.99997V7.99998H9.24998L9.64997 9.99998Z"
                        fill="#3C4043"/>
                </svg>
            </div>
        );
    }
}

export default ReportErrorIcon;

