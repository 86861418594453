import { combineReducers } from "redux";
import navigation from "./navigation.js";
import register from "./register.js";
import auth from "./auth.js";
import admin from "./admin";
import selectPosition from "./selectPosition";
import test from "./test";
import user from "./user";

export default combineReducers({
  test,
  user,
  admin,
  register,
  auth,
  navigation,
  selectPosition
});