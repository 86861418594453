import React, {useMemo} from "react";
import DOMPurify from "dompurify";

export const SanitizeHTML = ({
                                 input,
                                 questionNumber,
                                 annotatedPositions ,
                                 selectedPosition = {},
                                 className
                             }) => {

    const sanitizedHTML = useMemo(() => {
        let sanitizedInput = DOMPurify.sanitize(input, {
            ALLOWED_TAGS: [
                'div', 'a', 'img', 'b', 'br', 'link', 'li', 'p', 'table',
                'th', 'tr', 'th', 'u', 'ul', 'i', 'ul', 'math', 'mrow',
                'mfrac', 'msqrt', 'mn', 'mi', 'mo', 'code'
            ]
        });

        const purifiedStr = sanitizedInput
            .replace(/(<([^>]+)>)/ig, '[caps]')
            .split("[caps]").filter(x => x !== "").join("");

        // let modifiedInput = purifiedStr;
        let modifiedInput = "";
        let prevSlice = 0;

        // if (selectedPosition && Object.keys(selectedPosition).length > 0) {
        //     const start = selectedPosition.start;
        //     const end = selectedPosition.end;
        //     const selectedText = purifiedStr.slice(start, end);
        //
        //     let beforeSelection = sanitizedInput.slice(0, start);
        //     let afterSelection = sanitizedInput.slice(end);
        //
        //     for (let i = start; i < sanitizedInput.length - selectedText.length; i++) {
        //         if (selectedText === sanitizedInput.slice(i, i + selectedText.length)) {
        //             beforeSelection = sanitizedInput.slice(0, i);
        //             afterSelection = sanitizedInput.slice(i + selectedText.length);
        //             break;
        //         }
        //     }
        //
        //     modifiedInput = `${beforeSelection}<span class="${className}">${selectedText}</span>${afterSelection}`;
        // }

        // console.log("annotatedPositionsannotatedPositions: ", annotatedPositions)

            // if (annotatedPositions[questionNumber] !== undefined) {
        // if (annotatedPositions[questionNumber] && Object.keys(annotatedPositions[questionNumber].length > 0)) {
        //     const annotatedPositionsForQ = annotatedPositions[questionNumber];
        //     // console.log("annotatedPositionsForQ: ", annotatedPositionsForQ)
        //     let idx = 0;
        //     while (idx < purifiedStr.length) {
        //         if (annotatedPositionsForQ[idx.toString()]) {
        //             const start = idx;
        //             const end = annotatedPositionsForQ[idx][0];
        //
        //
        //             // console.log("start: ", start)
        //             // console.log("end: ", end)
        //
        //             const selectedText = purifiedStr.slice(start, end);
        //
        //             modifiedInput += purifiedStr.slice(prevSlice, idx);
        //             modifiedInput += `<span class="${className}">${selectedText}</span>`
        //
        //             idx += selectedText;
        //             prevSlice = idx;
        //         }
        //
        //         idx += 1;
        //     }
        // }
        //
        // modifiedInput += purifiedStr.slice(prevSlice, purifiedStr.length);

        return sanitizedInput;
    }, [input]);

    return (
        <div dangerouslySetInnerHTML={{__html: input}}  style={{listStyleType:"disc"}}/>
    );
}