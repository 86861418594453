import React, {useState} from 'react';
import s from './styles.scss';
import {Countdown} from "../countdown";
import {MATH} from "../../utils/utils";
import {MathNav} from "./math";
import {ReadingAndWritingNav} from "./readingAndWriting";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";

export const QuestionNav = ({
                                currentTestComponent,
                                countdown,
                                toggleDirection,
                                toggleAnnotate,
                                toggleCalculator,
                                toggleReference,
                                toggleMore,
                                dropdownRef,
                                referenceRef,
                                isReviewMode = false,
                                testId,
                                ...props
                            }) => {

    const [hideTimer, setHideTimer] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const toggleHideTimer = () => setHideTimer(!hideTimer);
    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        currentTestComponent.section &&
        <nav className={`${isReviewMode ? s.nav_review_mode : s.nav}`}>

            {/* Left */}
            <div className={s.left_content} style={{flexDirection: "column"}}>
                <div className={`${s.module}`}>
                    {`Section ${currentTestComponent.section}: ${currentTestComponent.title}`}
                </div>
                {isReviewMode ? null : (
                    <>
                        <div
                            onClick={toggleDirection}
                            className={s.direction}
                        >
                            <span className={s.desc}>Directions</span>
                            <div className={s.arrow}/>
                        </div>
                    </>
                )}
            </div>

            {/* Middle */}
            <div className={s.timer} style={{flexDirection: "column"}}>
                {isReviewMode ? (
                    <div className={s.review_mode}>REVIEW MODE</div>
                ) : (
                    <>
                        <Countdown
                            hideTimer={hideTimer}
                            countdown={countdown}
                        />
                        <div
                            onClick={toggleHideTimer}
                            className={s.hide_button}
                        >
                            Hide
                        </div>
                    </>
                )}
            </div>
            {/* Right */}
            <div className={s.tools}>
                {isReviewMode ? (
                    <>
                            <span
                                className={s.nav_dropdown}
                                onClick={toggleDropdown}
                            >
                                <Button type="submit" className={`${s.rounded_btn} ${s.button}`}>
                                    Navigate to
                                </Button>
                            </span>

                        <Link
                            style={{textDecoration: "none"}}
                            to={{
                                pathname: `/template/tests/${testId}/report`,
                                state: {testId: testId},
                            }}>
                            <Button type="submit" className={`${s.rounded_btn} ${s.button}`}>End
                                Review</Button>
                        </Link>
                    </>
                ) : (
                    currentTestComponent.title === MATH ? (
                        <MathNav
                            toggleCalculator={toggleCalculator}
                            toggleReference={toggleReference}
                            toggleMore={toggleMore}
                            dropdownRef={dropdownRef}
                            referenceRef={referenceRef}
                        />
                    ) : (
                        <ReadingAndWritingNav
                            toggleAnnotate={toggleAnnotate}
                            toggleMore={toggleMore}
                            dropdownRef={dropdownRef}
                        />
                    )
                )}
            </div>
        </nav>
    )
};