import React from 'react';
import s from './styles.scss';
import redBookmark from '../../../../images/bookmark_red.png';
import {Button} from "reactstrap";
import {LocationIcon} from "../../../../images/LocationIcon";

export const QuestionMap = ({
                                currentTestComponent,
                                currentQuestionNumber,
                                selectedAnswers,
                                bookmarks,
                                navigateToSpecificQuestion,
                                isReviewMode,
                                section
                            }) => {

    const determineColor = (qNum) => {
        const qReview = section.questionsAnswered;
        if (qReview[qNum].answered === '' || !qReview[qNum].answered) return '';
        return qReview[qNum].answered === qReview[qNum].correctAnswer ? s.correct : s.incorrect;
    }

    return (
        <>
            {/* Section Information */}
            <div className={s.section_info}>
                <span>Section {currentTestComponent.section}, Module {currentTestComponent.module}: {currentTestComponent.title}</span>
            </div>

            {/* Icons */}
            <div className={s.map_icons}>

                {/* Current Position Icon */}
                <div className={s.icon_wrapper}>
                    <LocationIcon/>
                    <span>Current</span>
                </div>
                {isReviewMode ? (
                    <>
                        <div className={s.icon_wrapper}>
                            <div className={s.correct_ind}/>
                            <span>Correct</span>
                        </div>
                        <div className={s.icon_wrapper}>
                            <div className={s.incorrect_ind}/>
                            <span>Incorrect</span>
                        </div>
                    </>
                ) : null
                }
                <div className={s.icon_wrapper}>
                    <div className={s.dashed_square}/>
                    <span>Unanswered</span>
                </div>

                {isReviewMode ? null : (
                    <div className={s.icon_wrapper}>
                        <img
                            className={s.icon_image}
                            src={redBookmark}
                            alt={"Bookmarked"}
                        />
                        {/*<BookmarkRedIcon />*/}
                        <span>For Review</span>
                    </div>
                )
                }
            </div>

            {/* List of Questions */}
            <div className={s.questions_list}>

                {Array(currentTestComponent.noOfQuestions).fill(null).map((_, i) => i).map((qNum) => (
                        <div
                            id={(qNum + 1).toString()}
                            key={qNum + 1}
                            onClick={navigateToSpecificQuestion}
                            className={s.question_item}
                        >
                            {currentQuestionNumber === qNum + 1 ?
                                <LocationIcon
                                    key={qNum + 1}
                                    className={s.icon_current_position}
                                    alt={"Current Position"}
                                /> : null
                            }
                            {isReviewMode ? (
                                <div className={`${s.question_num} ${determineColor(qNum + 1)}`}>
                                    {qNum + 1}
                                </div>
                            ) : (
                                <>
                                    {bookmarks[qNum + 1] ?
                                        <div className={s.bookmarked}>
                                            <img
                                                key={qNum + 1}
                                                className={s.bookmark_img}
                                                src={redBookmark}
                                                alt={"Bookmarked"}
                                            />
                                        </div> : null
                                    }
                                    <div
                                        className={`${s.question_num} ${selectedAnswers[qNum + 1] ? s.answered : ''}`}
                                    >
                                        {qNum + 1}
                                    </div>
                                </>
                            )}
                        </div>
                    )
                )}
            </div>

            {currentQuestionNumber <= currentTestComponent.noOfQuestions ? (
                <Button
                    id={currentTestComponent.noOfQuestions + 1}
                    type="submit" className={s.btn}
                    onClick={navigateToSpecificQuestion}
                >
                    Go to Review Page
                </Button>
            ) : null}
        </>
    )
}
