import React from 'react';
import s from "./styles.scss";
import MathReferenceImg from "../../../../images/math_reference.jpg";

export const Reference = () => {
    return (
        <>
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.triangle_arrow}/>
                    <div className={s.content_wrapper}>
                        <div className={s.content_container}>
                            <img
                                src={MathReferenceImg}
                                alt={"Math Reference"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}