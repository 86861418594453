import React, {useMemo} from 'react';
import * as d3 from 'd3';

// https://www.react-graph-gallery.com/scatter-plot

const MARGIN = {top: 60, right: 60, bottom: 60, left: 60};

export const ScatterPlotDisplay = ({data}) => {
    const width = 400;
    const height = 400;

    data = [
        {x: 1, y: 9.2},
        {x: 2, y: 8},
        {x: 3, y: 5.7},
        {x: 4, y: 7},
        {x: 5, y: 5.5},
        {x: 5.5, y: 3.6},
        {x: 6, y: 2},
        {x: 6.4, y: 1},
        {x: 7, y: 0.2}
    ]


    const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - MARGIN.top - MARGIN.bottom;

    // Scales
    const yScale = d3.scaleLinear().domain([0, 10]).range([boundsHeight, 0]);
    const xScale = d3.scaleLinear().domain([0, 10]).range([0, boundsWidth]);

    // Build the shapes
    const allShapes = data.map((d, i) => {
        return (
            <circle
                key={i}
                r={2}
                cx={xScale(d.x)}
                cy={yScale(d.y)}
                opacity={1}
                stroke="#2B2C2D"
                fill="#2B2C2D"
                strokeWidth={1}
            />
        );
    });

    return (
        <div>
            <svg width={width} height={height}>
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
                >
                    {/* Y axis */}
                    <AxisLeft yScale={yScale} pixelsPerTick={20} width={boundsWidth}/>
                    <text
                        transform={`translate(${-MARGIN.left + 15}, ${boundsHeight / 2}) rotate(-90)`}
                        textAnchor="middle"
                        style={{ fontSize: "14px", fill: "#2B2C2D" }}
                    >
                        Y-axis
                    </text>

                    {/* X axis */}
                    <g transform={`translate(0, ${boundsHeight})`}>
                        <AxisBottom
                            xScale={xScale}
                            pixelsPerTick={20}
                            height={boundsHeight}
                        />
                        <text
                            transform={`translate(${boundsWidth / 2}, ${MARGIN.bottom - 10})`}
                            textAnchor="middle"
                            style={{ fontSize: "14px", fill: "#2B2C2D" }}
                        >
                            X-axis
                        </text>
                    </g>

                    {/* Circles */}
                    {allShapes}
                </g>
            </svg>
        </div>
    );
};

export default ScatterPlotDisplay;


const TICK_LENGTH = 10;

const AxisBottom = ({
                        xScale,
                        pixelsPerTick,
                        height,
                    }) => {
    const range = xScale.range();

    const ticks = useMemo(() => {
        const width = range[1] - range[0];
        const numberOfTicksTarget = Math.floor(width / pixelsPerTick);

        return xScale.ticks(numberOfTicksTarget).map((value) => ({
            value,
            xOffset: xScale(value),
        }));
    }, [xScale]);

    return (
        <>
            {ticks.map(({value, xOffset}) => (
                <g
                    key={value}
                    transform={`translate(${xOffset}, 0)`}
                    shapeRendering={"crispEdges"}
                >

                    <line
                        y1={TICK_LENGTH}
                        y2={-height - TICK_LENGTH}
                        stroke="#3C4043"
                        strokeWidth={0.5}
                    />
                    <text
                        key={value}
                        style={{
                            fontSize: "12px",
                            textAnchor: "middle",
                            transform: "translateY(20px)",
                            fill: "#2B2C2D",
                        }}
                    >
                        {value}
                    </text>
                </g>
            ))}
        </>
    );
};

const AxisLeft = ({yScale, pixelsPerTick, width}) => {
    const range = yScale.range();

    const ticks = useMemo(() => {
        const height = range[0] - range[1];
        const numberOfTicksTarget = Math.floor(height / pixelsPerTick);

        return yScale.ticks(numberOfTicksTarget).map((value) => ({
            value,
            yOffset: yScale(value),
        }));
    }, [yScale]);

    return (
        <>
            {ticks.map(({value, yOffset}) => (
                <g
                    key={value}
                    transform={`translate(0, ${yOffset})`}
                    shapeRendering={"crispEdges"}
                >
                    <line
                        x1={-TICK_LENGTH}
                        x2={width + TICK_LENGTH}
                        stroke="#3C4043"
                        strokeWidth={0.5}
                    />
                    <text
                        key={value}
                        style={{
                            fontSize: "12px",
                            textAnchor: "middle",
                            transform: "translateX(-20px)",
                            fill: "#2B2C2D",
                        }}
                    >
                        {value}
                    </text>
                </g>
            ))}
        </>
    );
};