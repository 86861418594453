import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import s from "../styles.scss";
import resizer from "../../../../../../images/resizer.png";
import redBookmark from "../../../../../../images/bookmark_red.png";
import bookmark from "../../../../../../images/bookmark.png";
import crossingOption from "../../../../../../images/crossing_option.png";
import {SanitizeHTML} from "../../../../../../utils/sanitizeHTML";
import {combinedDomainEnum} from "../../../../utils/models";
import {ReviewMode} from "../reviewMode";
import {BookmarkRedIcon} from "../../../../../../images/BookmarkRedIcon";
import {BookmarkIcon} from "../../../../../../images/BookmarkIcon";
import ScatterPlotDisplay from "../math/d3/ScatterPlot";
import {TexGraphRenderer} from "../math/texHelpers/TexGraphRenderer";
import {LineChart} from "../math/d3/LineChart";
import {ImageComponent} from "../math/images/ImageComponent";
import Latex from "react-latex";
import {READING_WRITING} from "../../../../utils/utils";

const ReadingAndWritingBody = ({
                                   questionNumber,
                                   questionComponent,
                                   leftSizer,
                                   toggleLeftSizer,
                                   rightSizer,
                                   toggleRightSizer,
                                   isBookmarked,
                                   toggleIsMarked,
                                   toggleCrossingOption,
                                   showCrossingOption,
                                   optionsMap,
                                   toggleAnswers,
                                   selectedAnswers,
                                   isOptionCrossed,
                                   handleSelectedCrossingOptions,
                                   // annotatedPositions,
                                   // setAnnotatedPositions,
                                   isReviewMode,
                                   section = isReviewMode ? section : {}, // change the field name
                                   domainResult
                               }) => {

    const [comment, setComment] = useState('');
    const [annotatedPositions, setAnnotatedPositions] = useState({});
    const [selectedPosition, setSelectedPosition] = useState({});

    const renderProblem = (questionComponent) => {
       if (questionComponent.hasImage != null && questionComponent.hasImage) {
           return <ImageComponent imageName={questionComponent.problem}/>;
       }

       return <SanitizeHTML
            input={questionComponent.problem}
            questionNumber={questionNumber}
            annotatedPositions={annotatedPositions}
            selectedPosition={selectedPosition}
            className={s.highlight}
        />;
    }

    const saveSelection = () => {
        // const {selection} = window.getSelection();
        // if (selection) {
        //     const range = selection.getRangeAt(0);
        //     const {startContainer: startNode, endContainer: endNode} = range;
        //
        //     const startPosition = getSelectionPosition(startNode, range.startOffset);
        //     const endPosition = getSelectionPosition(endNode, range.endOffset);
        //
        //     setSelectedPosition({start: startPosition, end: endPosition});
        //
        //     const newPosition = {...annotatedPositions[questionNumber]};
        //     newPosition[startPosition] = [endPosition, ""];
        //
        //     setAnnotatedPositions({
        //         ...annotatedPositions,
        //         [questionNumber]: newPosition
        //     });
        //
        //     console.log("annotatedPositions: ", annotatedPositions)
        // }

        const selection = window.getSelection();
        if (selection.toString()) {
            const range = selection.getRangeAt(0);
            const startNode = range.startContainer;
            const endNode = range.endContainer;

            const startPosition = getSelectionPosition(startNode, range.startOffset);
            const endPosition = getSelectionPosition(endNode, range.endOffset);

            setSelectedPosition({start: startPosition, end: endPosition});

            let newPosition = questionNumber in annotatedPositions ? annotatedPositions[questionNumber] : {};
            newPosition[startPosition] = [endPosition, ""];

            annotatedPositions[questionNumber] = newPosition;
            setAnnotatedPositions(annotatedPositions);


            // console.log("annotatedPositions: ", annotatedPositions)
            // console.log("selectedPositionHI: ", selectedPosition)
        }
    };

    // useEffect(() => {
    //     document.addEventListener('mouseup', saveSelection);
    //     return () => document.removeEventListener('mouseup', saveSelection);
    // }, []);

    const getDomainName = () => combinedDomainEnum.getValueByKey(questionComponent.domain);

    const getTotalNumOfQsByDomain = () => domainResult[getDomainName()].reduce((a, b) => a + b, 0);

    const getSelectionPosition = (node, offset) => {
        const nodeIndex = Array.from(node.parentNode.childNodes).indexOf(node);
        let position = 0;

        for (let i = 0; i < nodeIndex; i++) {
            const childNode = node.parentNode.childNodes[i];
            if (childNode.nodeType === Node.TEXT_NODE) {
                position += childNode.textContent.length;
            } else {
                position += getNodeTextContentLength(childNode);
            }
        }

        return position + offset;
    };

    const getNodeTextContentLength = (node) => {
        let length = 0;
        if (node.nodeType === Node.TEXT_NODE) {
            length += node.textContent.length;
        } else {
            for (const childNode of node.childNodes) {
                if (childNode.nodeType === Node.TEXT_NODE) {
                    length += childNode.textContent.length;
                } else {
                    length += getNodeTextContentLength(childNode);
                }
            }
        }
        return length;
    };

    const setSelectedOrActualAnswers = (key) => {
        const qInfo = section.questionsAnswered;
        return qInfo[questionNumber].correctAnswer === optionsMap[key] ? s.correct_answer :
            qInfo[questionNumber].answered === optionsMap[key] ? s.incorrect_answer : '';
    };

    return (
        <>
            {/* Left Container */}
            <div className={`${leftSizer ? s.size_60 : s.size_50} ${s.left}`}>
                {isReviewMode && (
                    <div className={s.question_review}>
                        <div className={s.question_title}>
                            <div className={s.question_idx}>{questionNumber}</div>
                        </div>
                    </div>
                )}
                <div className={s.left_wrapper}>
                    { renderProblem(questionComponent) }
                </div>
                {isReviewMode ? (
                    <>
                        {/* Question */}
                        <div className={s.question_content}>
                            <SanitizeHTML
                                input={questionComponent.question}
                                annotatedPositions={{}}
                                style={{listStyleType:"disc"}}
                            />
                        </div>

                        {/* Choices */}
                        <div className={s.answer_choices}>
                            {
                                questionComponent.options.map((option, key) => (
                                    <div
                                        key={key}
                                        className={s.option}
                                    >
                                        <div
                                            key={key}
                                            id={optionsMap[key]}
                                            className={`${s.review_mode} ${setSelectedOrActualAnswers(key)}`}
                                        >
                                            <div className={s.option_button}>
                                                {optionsMap[key]}
                                            </div>
                                            <span className={s.option_content}>
                                                    <div className={s.option_wrapper}>
                                                        <div className={s.option_display}>
                                                            <SanitizeHTML
                                                                input={option}
                                                                annotatedPositions={{}}
                                                            />
                                                        </div>
                                                    </div>
                                                </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                ) : null
                }
            </div>

            {/* Resizing Vertical Line */}
            <div className={s.resize}>
                <div
                    onClick={toggleLeftSizer}
                    className={s.resizer} style={{right: "20px"}}
                >
                    <img className={`${leftSizer ? s.sizer_toggled : ''}`} src={resizer}
                         style={{height: "auto", width: "25px"}} alt={"resize_layout"}/>

                </div>
                <div onClick={toggleRightSizer} className={`${s.resizer} ${s.right_img}`} style={{left: "20px"}}>
                    <img className={`${rightSizer ? s.sizer_toggled : ''}`} src={resizer}
                         style={{height: "auto", width: "25px"}} alt={"resize_layout"}/>
                </div>
            </div>

            {/* Right Container */}
            <div className={`${rightSizer ? s.size_60 : s.size_50} ${s.right} ${s.right_custom}`}>
                {isReviewMode ?
                    <ReviewMode
                        title={READING_WRITING}
                        section={section}
                        questionComponent={questionComponent}
                        questionNumber={questionNumber}
                        domainResult={domainResult}
                    /> : (
                        <div className={s.question}>

                            {/* Question Header */}
                            <div className={s.question_title}>
                                {/* Question Index */}
                                <div className={s.question_idx}>{questionNumber}</div>

                                {/* Bookmark for Review*/}
                                <div className={s.bookmark}>
                                    {isBookmarked[questionNumber] ?
                                        <img
                                            id={questionNumber}
                                            onClick={toggleIsMarked}
                                            src={redBookmark}
                                            style={{height: '100%', width: 'auto'}}
                                            alt={"red bookmark"}
                                        /> :
                                        <img
                                            id={questionNumber}
                                            onClick={toggleIsMarked}
                                            src={bookmark}
                                            style={{height: '100%', width: 'auto'}}
                                            alt={"Bookmark Image"}
                                        />
                                    }
                                    Mark for Review
                                </div>

                                {/* Crossing */}
                                <div className={s.crossing_options}>
                                    <div
                                        onClick={toggleCrossingOption}
                                        className={s.crossing_option}
                                    >
                                        <img src={crossingOption} style={{height: '100%', width: 'auto'}}
                                             alt={"crossing option"}/>
                                    </div>
                                </div>
                            </div>

                            {/* Question */}
                            <div className={s.question_content}>
                                <SanitizeHTML
                                    input={questionComponent.question}
                                    annotatedPositions={{}}
                                />
                            </div>

                            {/* Choices */}
                            <div className={s.answer_choices}>
                                {
                                    questionComponent.options.map((option, key) => (
                                        <div
                                            key={key}
                                            className={s.option}
                                        >
                                            <div
                                                key={key}
                                                id={optionsMap[key]}
                                                onClick={toggleAnswers}
                                                className={`${s.select} ${selectedAnswers[questionNumber] === optionsMap[key] ? s.selected : ''}`}
                                            >
                                                <div className={`${isOptionCrossed(optionsMap[key]) ?
                                                    s.crossed_button : s.option_button}`}
                                                >
                                                    {optionsMap[key]}
                                                </div>
                                                <span className={s.option_content}>
                                                        <div className={s.option_wrapper}>
                                                        <div className={isOptionCrossed(optionsMap[key]) ?
                                                            s.option_display_crossed : s.option_display}
                                                        >
                                                            <SanitizeHTML
                                                                input={option}
                                                                annotatedPositions={{}}
                                                            />
                                                        </div>
                                                        <div
                                                            className={isOptionCrossed(optionsMap[key]) ? s.option_crossing : ''}
                                                        />
                                                        </div>
                                                    </span>
                                            </div>
                                            {showCrossingOption ? (
                                                <div
                                                    id={optionsMap[key]}
                                                    onClick={handleSelectedCrossingOptions}
                                                    className={s.crossing}
                                                >
                                                    <div
                                                        className={s.crossing_label}>{optionsMap[key]}</div>
                                                    <div className={s.crossing_line}/>
                                                </div>
                                            ) : <hr style={{display: "none"}}/>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )}
            </div>
        </>
    );
};

const mapStateToProps = (store) => {
    return {
        // selectedPosition: store.selectPosition,
    };
};

export default withRouter(connect(mapStateToProps)(ReadingAndWritingBody));
