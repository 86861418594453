// TODO: store this as csv file.
const scoreConversionTable = {
    // Test #1
    1: {
        "Reading and Writing": {
             0: 200,
             1: 220,
             2: 240,
             3: 260,
             4: 280,
             5: 300,
             6: 330,
             7: 350,
             8: 360,
             9: 370,
            10: 380,
            11: 380,
            12: 390,
            13: 400,
            14: 410,
            15: 420,
            16: 430,
            17: 430,
            18: 440,
            19: 450,
            20: 460,
            21: 470,
            22: 470,
            23: 480,
            24: 490,
            25: 500,
            26: 510,
            27: 520,
            28: 530,
            29: 540,
            30: 550,
            31: 560,
            32: 560,
            33: 570,
            34: 580,
            35: 590,
            36: 600,
            37: 610,
            38: 620,
            39: 630,
            40: 640,
            41: 650,
            42: 660,
            43: 670,
            44: 680,
            45: 690,
            46: 700,
            47: 710,
            48: 720,
            49: 730,
            50: 740,
            51: 750,
            52: 760,
            53: 770,
            54: 780
        },
        "Math": {
            0: 200,
            1: 210,
            2: 310,
            3: 320,
            4: 330,
            5: 340,
            6: 350,
            7: 360,
            8: 370,
            9: 380,
            10: 380,
            11: 390,
            12: 400,
            13: 410,
            14: 420,
            15: 430,
            16: 440,
            17: 450,
            18: 460,
            19: 470,
            20: 490,
            21: 500,
            22: 510,
            23: 520,
            24: 530,
            25: 550,
            26: 560,
            27: 580,
            28: 590,
            29: 600,
            30: 610,
            31: 620,
            32: 630,
            33: 650,
            34: 660,
            35: 680,
            36: 700,
            37: 710,
            38: 730,
            39: 750,
            40: 760,
            41: 770,
            42: 780,
            43: 790,
            44: 800,
        }
    },
    // Test #2
    2: {
        "Reading and Writing": {
            0: 200,
            1: 220,
            2: 240,
            3: 260,
            4: 280,
            5: 300,
            6: 330,
            7: 350,
            8: 360,
            9: 370,
            10: 380,
            11: 380,
            12: 390,
            13: 400,
            14: 410,
            15: 420,
            16: 430,
            17: 430,
            18: 440,
            19: 450,
            20: 460,
            21: 470,
            22: 470,
            23: 480,
            24: 490,
            25: 500,
            26: 510,
            27: 520,
            28: 530,
            29: 540,
            30: 550,
            31: 560,
            32: 560,
            33: 570,
            34: 580,
            35: 590,
            36: 600,
            37: 610,
            38: 620,
            39: 630,
            40: 640,
            41: 650,
            42: 660,
            43: 670,
            44: 680,
            45: 690,
            46: 700,
            47: 710,
            48: 720,
            49: 730,
            50: 740,
            51: 750,
            52: 760,
            53: 770,
            54: 780
        },
        "Math": {
            0: 200,
            1: 210,
            2: 310,
            3: 320,
            4: 330,
            5: 340,
            6: 350,
            7: 360,
            8: 370,
            9: 380,
            10: 380,
            11: 390,
            12: 400,
            13: 410,
            14: 420,
            15: 430,
            16: 440,
            17: 450,
            18: 460,
            19: 470,
            20: 490,
            21: 500,
            22: 510,
            23: 520,
            24: 530,
            25: 550,
            26: 560,
            27: 580,
            28: 590,
            29: 600,
            30: 610,
            31: 620,
            32: 630,
            33: 650,
            34: 660,
            35: 680,
            36: 700,
            37: 710,
            38: 730,
            39: 750,
            40: 760,
            41: 770,
            42: 780,
            43: 790,
            44: 800,
        }
    }
}


module.exports = {
    scoreConversionTable
}
