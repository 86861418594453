import React from 'react';
import {loginUserViaGoogleOAuth} from "../../../../../redux/actions/auth";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {GoogleLogin} from 'react-google-login';
import {gapi} from 'gapi-script';
import s from "../styles.scss";

const LoginViaGoogle = (props) => {
    const clientId = process.env.GOOGLE_CLIENT_ID;
    const baseRedirectUri = process.env.NODE_ENV === 'production' ? process.env.GOOGLE_REDIRECT_URI : "http://localhost:3000/api/sessions/oauth/google";

    const handleGoogleLogin = () => {
        gapi.load('client:auth2', () => {
            gapi.client.init({
                clientId: clientId,
                scope: ""
            })
        })
    }

    const onSuccess = (response) => props.dispatch(loginUserViaGoogleOAuth(response))

    return (
        <GoogleLogin
            className={`${s.rounded_btn} ${s.google_login_btn}`}
            clientId={clientId}
            buttonText="Continue with Google"
            onSuccess={onSuccess}
            onFailure={() => console.error('Login failed')}
            cookiePolicy={'single_host_origin'}
            isSignedIn={false}
            redirectUri={baseRedirectUri}
            accessType={'offline'}
            onClick={handleGoogleLogin}
        />
    );
};

LoginViaGoogle.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(LoginViaGoogle));