import React from "react";
import PropTypes from "prop-types";
import {NavLink, withRouter} from "react-router-dom";
import s from "./styles.scss";

const LinksGroup = (props) => {

    return (
        <li
            className={`${[s.header_link, s.header_link_active].join(" ")}`}
        >
            <NavLink
                to={props.link}
                activeClassName={s.header_link_active}
                exact={props.exact}
                target={props.target}
            >
                <span className={s.icon}>{props.iconName}</span>
                {props.header}
            </NavLink>
        </li>
    );
};

LinksGroup.propTypes = {
    header: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    childrenLinks: PropTypes.array,
    iconName: PropTypes.object,
    className: PropTypes.string,
    badge: PropTypes.string,
    label: PropTypes.string,
    activeItem: PropTypes.string,
    isHeader: PropTypes.bool,
    index: PropTypes.string,
    deep: PropTypes.number,
    onActiveSidebarItemChange: PropTypes.func,
    labelColor: PropTypes.string,
    exact: PropTypes.bool,
}

export default withRouter(LinksGroup);
