import React, {useState} from "react";
import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel, getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";

import {DebouncedInput, Filter} from "./TableHelper";
import {fuzzyFilter} from "./TableHelperJs";
import s from "../../styles.scss";

export const Table = ({
                          columns,
                          data,
                          getRowCanExpand,
                          subData = {},
                          renderSubComponent,
                          showRequired = false,
                      }) => {

    const [rowSelection, setRowSelection] = useState({});
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState([]);


    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            rowSelection,
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
        onRowSelectionChange: setRowSelection,
        getRowCanExpand,
        enableRowSelection: true,
        getExpandedRowModel: getExpandedRowModel(),
    })

    return (
        <>
            <div>
                <DebouncedInput
                    type={"string"}
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(String(value))}
                    className={s.table_border}
                    placeholder="Search all columns..."
                />
            </div>
            {
                showRequired ?
                    <span style={{color: "red"}}>Must select 1 or more students</span> : null
            }
            <table className={`${s.table_wrapper} ${s.table_wrapper_border}`}>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className={`${s.table_border}`}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} table={table}/>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => {
                    return (
                        <React.Fragment key={row.id}>
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td
                                            key={cell.id}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
                                {row.getIsExpanded() && subData && subData[row.original.userId] && subData[row.original.userId].length > 0 ? (
                                    <table className={`${s.table_wrapper} ${s.table_wrapper_border}`}>
                                        <thead>
                                        <tr>
                                            <th>Test</th>
                                            <th>Assigned Date</th>
                                            <th>Status</th>
                                            <th>Result</th>
                                            <th>Summary</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {subData[row.original.userId].map((datum, idx) => (
                                            <tr key={idx}>
                                                <td>{datum.testName}</td>
                                                <td>{datum.assignedDate}</td>
                                                <td>{datum.status}</td>
                                                <td>
                                                    {datum.status === 'COMPLETED'
                                                        ? `Total: ${datum.result[0]} | Reading & Writing: ${datum.result[1]} | Math: ${datum.result[2]}`
                                                        : 'Not complete!'}
                                                </td>
                                                <td>{datum.summary}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ) : null}
                            </div>

                        </React.Fragment>
                    )
                })}
                </tbody>
                <tfoot>
                <tr>
                    <td
                        className={s.table_border}
                        style={{marginTop: "1rem"}}
                        colSpan={20}>page rows : {table.getRowModel().rows.length}
                    </td>
                </tr>
                </tfoot>
            </table>
            <div style={{display: "flex", justifyContent: "center", height: "3em"}}>
                <button
                    className={s.table_border}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<'}
                </button>
                <button
                    className={s.table_border}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>'}
                </button>

                <span
                    className={s.table_border}
                >
                <div>Page</div>
                <strong>
                 {table.getState().pagination.pageIndex + 1} of{' '}
                    {table.getPageCount()}
                </strong>
                </span>
                <span style={{padding: "1rem"}}>  Go to page:
                <input
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        table.setPageIndex(page)
                    }}
                    className={s.table_border}
                />
                </span>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            {/*<div style={{paddingLeft: "1rem"}}>*/}
            {/*    <label>Selected Row:</label>*/}
            {/*    <pre>{JSON.stringify(selectedData, null, 2)}</pre>*/}
            {/*</div>*/}
        </>
    )
}