import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.scss';

const Widget = (props) => {
    const {
        title = null,
        className = '',
        headerClass = '',
        children = [],
        options = {},
        component = '',
        ...restProps
    } = props;

    const sectionStyleMapper = {
        'satTestDates': s.test_dates_widget,
        'progressContainer1': s.progress_container1_widget,
        'progressContainer2': s.progress_container2_widget,
        'progressContainer3': s.progress_container3_widget,
        'LatestResult': s.latest_result_widget,
        'satScores': s.sat_scores_widget,
        '': s.default_widget,
        null: s.default_widget
    }

    const titleSectionMapper = {
        'satTestDates': s.test_dates_title,
        '': s.title,
        null: s.title
    }

    return (
        <section
            className={`${s.default_widget} ${sectionStyleMapper[component] ? sectionStyleMapper[component] : ''}`}
            {...restProps}
        >
            {
                title && (
                    <h3 className={`${s.title} ${titleSectionMapper[component] ? titleSectionMapper[component] : ''}`}>
                        {title}
                    </h3>
                )
            }
            <div className={s.children}>
                {children}
            </div>
        </section>
    )
}

Widget.propTypes = {
    title: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    options: PropTypes.object,
}

export default Widget;
