import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import s from "../styles.scss";
import LinksGroup from "../LinksGroup/index";
import {changeActiveSidebarItem} from "../../../../../redux/actions/navigation.js";
import cn from "classnames";
import {logoutUser} from "../../../../../redux/actions/auth";
import {NavLink} from "reactstrap";
import SignOutIcon from "../../../../../images/SignOutIcon";

const AdminSidebar = (props) => {

    const {
        activeItem = '',
        ...restProps
    } = props;

    const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)
    const doLogout = () => {
        props.dispatch(logoutUser());
    }

    useEffect(() => {
        if (props.sidebarOpened) {
            setBurgerSidebarOpen(true)
        } else {
            setTimeout(() => {
                setBurgerSidebarOpen(false)
            }, 0);
        }
    }, [props.sidebarOpened])

    return (
        <nav className={cn(s.sidebar_root, {[s.sidebarOpen]: burgerSidebarOpen})}>
            <header className={s.logo}>
                {/*<Logo here!/>*/}
                <span className={s.title}>PrepNerds</span>
            </header>
            <ul className={s.sidebar_nav}>
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={props.activeItem}
                    header="Dashboard"
                    isHeader
                    link="/template/dashboard"
                    index="dashboard"
                />

                <h5 className={s.nav_title}>Digital SAT</h5>

                <LinksGroup
                    onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={props.activeItem}
                    header="Create a Test"
                    isHeader
                    link="/template/create-test"
                    index="create-test"
                />
                <LinksGroup
                    onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={props.activeItem}
                    header="Assign a Test"
                    isHeader
                    link="/template/assign-test"
                    index="assign-test"
                />

                <LinksGroup
                    onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={props.activeItem}
                    header="Student Progress"
                    isHeader
                    link="/template/student-progress"
                    index="student-progress"
                />

                <h5 className={s.nav_title}>Account</h5>

                <LinksGroup
                    onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                    activeItem={props.activeItem}
                    header="Inbox"
                    isHeader
                    link="/template/inbox"
                    index="inbox"
                />

                <span style={{margin: "5em"}} />

                <NavLink
                    onClick={() => doLogout()}
                    href="#"
                    className={s.nav_container}
                >
                    <div className={s.col}>
                        <SignOutIcon />
                    </div>
                    Log Out
                </NavLink>
            </ul>
        </nav>
    );
}

AdminSidebar.propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
}

const mapStateToProps = (store) => {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        activeItem: store.navigation.activeItem,
    };
}

export default withRouter(connect(mapStateToProps)(AdminSidebar));
