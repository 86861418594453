import { toast } from 'react-toastify';
import {postData} from "../../utils/api";
import {receiveLogin} from "./auth";

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export function receiveRegister() {
    return {
        type: REGISTER_SUCCESS,
    };
}

export function registerError(payload) {
    return {
        type: REGISTER_FAILURE,
        payload,
    };
}

export function registerUser(payload) {
    return (dispatch) => {
        if (payload.creds.email.length > 0 && payload.creds.password.length > 0) {
            postData(`/api/register`, {email: payload.creds.email, password: payload.creds.password})
                .then((response) => {
                    const {token, role} = response.data;

                    if (role != null) {
                        localStorage.setItem('authenticated', true);
                        localStorage.setItem('role', role); // Use role variable instead of string 'role'
                        dispatch(receiveLogin());
                    } else {
                        dispatch(registerError('Something was wrong. Try again'));
                    }
                })

            toast.success("You've been registered successfully", {
                position: toast.POSITION.TOP_RIGHT
            });

            payload.history.push('/login');
        } else {
            dispatch(registerError("Something went wrong. Try again"));
        }
    }
}
