import React from 'react';
import s from './styles.scss';
import {TestStatus} from "../../models/AssignedTest";

export const Footer = ({
                           isFirstPage,
                           isModuleStartingPage = false,
                           testStatus,
                           questionNumber,
                           numOfProblems,
                           testType = 'Test',
                           testNumber,
                           showMap,
                           toggleMap,
                           nextQuestion,
                           prevQuestion,
                           isReviewMode = false
                       }) => {
    return !isReviewMode && (isFirstPage || isModuleStartingPage) ? (
        <footer className={s.footer}>
            {/* Left */}
            <div className={s.footer_left}>
                <div className={s.company_name}>PrepNerds</div>
                <div className={s.test_version}>{`${testType} #${testNumber}`}</div>
            </div>

            {/* Right */}
            <div className={s.footer_right}>
                {testStatus === TestStatus.COMPLETED ? null : isModuleStartingPage ?
                    <button className={s.btn} onClick={nextQuestion}>Begin</button> :
                    <button className={s.btn} onClick={nextQuestion}>Next</button>}
            </div>
        </footer>
    ) : (
        <footer className={s.footer}>

            {/* Left */}
            <div className={s.footer_left}>
                <div className={s.company_name}>PrepNerds</div>
                <div className={s.test_version}>{`${testType} #${testNumber}`}</div>
            </div>

            {/* Middle */}
            {questionNumber <= numOfProblems ? (
                <div
                    onClick={toggleMap}
                    className={`${s.footer_middle} ${s.footer_enable}`}
                >
                    <span>Question {questionNumber} of {numOfProblems}</span>
                    <div className={`${s.footer_arrow} ${showMap ? s.footer_arrow_down : ''}`}/>
                </div>
            ) : null}

            {/* Right */}
            <div className={s.footer_right}>
                {questionNumber === numOfProblems + 2 ? null : (
                    questionNumber === 1 ? (
                        <>
                            <button className={`${s.btn} ${s.disabled_btn}`}>Back</button>
                            <button className={s.btn} onClick={nextQuestion}>Next</button>
                        </>
                    ) : (
                        <>
                            <button className={s.btn} onClick={prevQuestion}>Back</button>
                            <button className={s.btn} onClick={nextQuestion}>
                                {questionNumber <= numOfProblems ? `Next` : `Submit`}
                            </button>
                        </>
                    )
                )}
            </div>
        </footer>
    )
};