import React, {useState} from 'react';
import s from './styles.scss';

import ReadingAndWritingBody from "./readingAndWriting";
import {MATH} from "../../../utils/utils";
import {MathBody} from "./math";

const optionsMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D"
}

export const MainBody = ({
                             testComponent,
                             questionNumber,
                             questionComponent,
                             toggleOption,
                             selectedAnswers,
                             setSelectedAnswers,
                             showCalculator,
                             toggleBookmarks,
                             showCrossingOption,
                             toggleCrossingOption,
                             selectedCrossingOptions,
                             handleSelectedCrossingOptions,
                             annotatedPositions,
                             setAnnotatedPositions,
                             isReviewMode = false,
                             section,
                             performanceBySkills = []
                         }) => {
    const [leftSizer, setLeftSizer] = useState(false);
    const [rightSizer, setRightSizer] = useState(false);
    const [answers, setAnswers] = useState({});
    const [answer, setAnswer] = useState("");
    const [isBookmarked, setIsBookmarked] = useState({});
    const [visible, setVisible] = useState(false)

    const toggleAnswers = (e) => {
        answers[questionNumber] = e.currentTarget.id;
        setAnswers(answers);
        toggleOption(e);
        toggledAnswer()
    }

    const toggledAnswer = () => {
        setAnswer(answers[questionNumber])
    }

    const toggleIsMarked = (e) => {
        const id = e.currentTarget.id;
        isBookmarked[id] = id in isBookmarked ? !isBookmarked[id] : true;
        setIsBookmarked(isBookmarked);
        toggleBookmarks(e);
        markToggled();
    }

    const markToggled = () => {
        setVisible(!visible)
    }

    const toggleLeftSizer = () => {
        if (!leftSizer && rightSizer) {
            setRightSizer(false);
        }
        setLeftSizer(!leftSizer);
    }

    const toggleRightSizer = () => {
        if (!rightSizer && leftSizer) {
            setLeftSizer(false);
        }
        setRightSizer(!rightSizer);
    }

    const isOptionCrossed = (option) => {
        return (
            isReviewMode ? false : (
                questionNumber in selectedCrossingOptions &&
                selectedCrossingOptions[questionNumber].has(option)
            )
        );
    };

    return (
        <>
            <div className={s.container}>
                {testComponent.title === MATH ? (
                    <MathBody
                        testComponent={testComponent}
                        questionNumber={questionNumber}
                        questionComponent={questionComponent}
                        showCalculator={showCalculator}
                        leftSizer={leftSizer}
                        toggleLeftSizer={toggleLeftSizer}
                        rightSizer={rightSizer}
                        toggleRightSizer={toggleRightSizer}
                        toggleOption={toggleOption}
                        selectedAnswers={selectedAnswers}
                        toggleBookmarks={toggleBookmarks}
                        showCrossingOption={showCrossingOption}
                        toggleCrossingOption={toggleCrossingOption}
                        isOptionCrossed={isOptionCrossed}
                        selectedCrossingOptions={selectedCrossingOptions}
                        handleSelectedCrossingOptions={handleSelectedCrossingOptions}
                        toggleAnswers={toggleAnswers}
                        toggleIsMarked={toggleIsMarked}
                        isBookmarked={isBookmarked}
                        optionsMap={optionsMap}
                        isReviewMode={isReviewMode}
                        domainResult={isReviewMode ? performanceBySkills[1] : []}
                        section={section}
                    />
                ) : (
                    <ReadingAndWritingBody
                        testComponent={testComponent}
                        questionNumber={questionNumber}
                        questionComponent={questionComponent}
                        leftSizer={leftSizer}
                        toggleLeftSizer={toggleLeftSizer}
                        rightSizer={rightSizer}
                        toggleRightSizer={toggleRightSizer}
                        toggleOption={toggleOption}
                        setSelectedAnswers={setSelectedAnswers}
                        selectedAnswers={selectedAnswers}
                        toggleBookmarks={toggleBookmarks}
                        showCrossingOption={showCrossingOption}
                        toggleCrossingOption={toggleCrossingOption}
                        selectedCrossingOptions={selectedCrossingOptions}
                        isOptionCrossed={isOptionCrossed}
                        handleSelectedCrossingOptions={handleSelectedCrossingOptions}
                        toggleAnswers={toggleAnswers}
                        toggleIsMarked={toggleIsMarked}
                        isBookmarked={isBookmarked}
                        optionsMap={optionsMap}
                        annotatedPositions={annotatedPositions}
                        setAnnotatedPositions={setAnnotatedPositions}
                        isReviewMode={isReviewMode}
                        domainResult={isReviewMode ? performanceBySkills[0] : []}
                        section={section}
                    />
                )}
            </div>
        </>
    )
};