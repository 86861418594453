import React, {useEffect, useState} from 'react';

import {DefaultPage} from "./components/body/default";
import {Section} from "./components";
import {
    populateTestComponents,
    populateQuestionsAnswered,
} from "./utils/utils";
import {getData} from "../../utils/api";
import {TestStatus} from "./models/AssignedTest";
import {LoadingSpinner} from "./components/loading";
import {TestComponent, TestDocument} from "./utils/models";
import {fetchTestsByUserId} from "../../redux/actions/test";
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {ModuleStartingPage} from "./components/moduleStartingPage";

/** When a student clicks on one of the assigned tests, this component will be rendered.*/
const Test = ({match, dispatch}) => {
    const {
        params: {testId}
    } = match;

    const [isLoading, setIsLoading] = useState(true);
    const [isFirstPage, setIsFirstPage] = useState(true);
    const [isModuleStartingPage, setIsModuleStartingPage] = useState(true);

    /** Populates all the sections in a given test. */
    const [test, setTest] = useState(null);
    const [testStatus, setTestStatus] = useState(TestStatus.COMPLETED);
    const [testEnded, setTestEnded] = useState(false);
    const [testComponents, setTestComponents] = useState([]);
    const [currentTestComponent, setCurrentTestComponent] = useState(new TestComponent());
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);

    /** Populates all the questions answered in a given test. */
    const [questionsAnswered, setQuestionsAnswered] = useState({});

    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
            setIsLoading(true);
            /**
             We first need to validate whether this specific
             test has been assigned to the student. While doing
             validations, we also retrieve additional information
             about the test assigned to the student.


             ************** NOTE *************
             Section number, question number
             will be populated as
             sectionNumber,
             questionNumber
             *********************************
             */

            getData(`/api/assigned-tests/${testId}?userId=${localStorage.getItem("userId")}`)
                .then(({data}) => {
                    let testDocument = new TestDocument(data);
                    if (data.status === (TestStatus.NOT_STARTED || TestStatus.ASSIGNED)) {

                        // TODO: create 'fetchTestsByUserId()'
                        return getData(`/api/tests/${testId}`)
                            .then(({data}) => {
                                testDocument.sections = data.sections;
                                return testDocument;
                            })
                            .then((updatedTestDocument) => {
                                // Update to TestStatus.IN_PROGRESS
                                updatedTestDocument.status = TestStatus.IN_PROGRESS;
                                setTest(testDocument);

                                return updatedTestDocument;
                            });
                    }

                    return testDocument;
                })
                .then((testDocument) => {
                    if (testDocument.status !== TestStatus.COMPLETED) {
                        setTest(testDocument);

                        // console.log("testDocument: ", testDocument)
                        setTestStatus(testDocument.status);

                        const populatedComponents = populateTestComponents(testDocument);
                        setTestComponents(populatedComponents);
                        setQuestionsAnswered(populateQuestionsAnswered(testDocument.sections[testDocument.currentSection]));

                        const currentTestComponent = populatedComponents[testDocument.currentSection]
                        setCurrentTestComponent(currentTestComponent);
                        setCurrentQuestionNumber(currentTestComponent.currentQuestion);
                    }
                })
                .catch(e => console.error(e));

            setIsLoading(false);

        }, [fetchTestsByUserId]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.onpopstate = handlePopState;

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.onpopstate = null;
        };
    }, []);

    const handleBeforeUnload = (event) => {
        const warningMsg= `Your progress won't be saved. Make sure to click on 'Save & Exit' from the 'More' menu`;
        event.preventDefault();
        event.returnValue = warningMsg;

        return warningMsg;
    };

    const handlePopState = (event) => {
        const confirmNavigation = window.confirm(`Your progress won't be saved. Make sure to click on 'Save & Exit' from the 'More' menu`);
        if (!confirmNavigation) {
            history.pushState(null, null, window.location.href);
            event.preventDefault();
        }
    };

    const prevQuestion = () => {
        setCurrentQuestionNumber(() => {
            if (showMap) setShowMap(!showMap);
            return navigateQuestion(currentQuestionNumber - 1);
        });
    };

    const nextQuestion = () => {
        if (isFirstPage) {
            setIsFirstPage(!isFirstPage);
        } else if (isModuleStartingPage) {
            setIsModuleStartingPage(!isModuleStartingPage);
            navigateQuestion(0)
        } else {
            setCurrentQuestionNumber((num) => {
                if (showMap) setShowMap(!showMap);
                return navigateQuestion(num + 1);
            });
        }
    };

    const submitSection = () => {
        const nextSection = currentTestComponent.section + 1;

        if (nextSection === testComponents.length) {
            setTestEnded(true);
        } else {
            const nextTestComponent = testComponents[nextSection];
            setCurrentTestComponent(nextTestComponent);
            setCurrentQuestionNumber(nextTestComponent.currentQuestion);
            setIsModuleStartingPage(true);
            setQuestionsAnswered({});
            return nextTestComponent.section;
        }
    }

    const toggleMap = () => setShowMap(!showMap);

    const navigateQuestion = (num) => {
        if (num === currentTestComponent.noOfQuestions + 3) {
            submitSection();
            return 1;
        }

        return num < 1 ? 1 : num;
    };

    return (
        isFirstPage ? (
            isLoading ? (
                <LoadingSpinner isLoading={isLoading}/>
            ) : (
                <DefaultPage
                    testStatus={testStatus}
                    isFirstPage={isFirstPage}
                    isModuleStartingPage={isModuleStartingPage}
                    testType={currentTestComponent.testType}
                    testNumber={currentTestComponent.testNumber}
                    nextQuestion={nextQuestion}
                    prevQuestion={prevQuestion}
                />
            )
        ) : (
            isModuleStartingPage ?
                <ModuleStartingPage
                    isFirstPage={isFirstPage}
                    isModuleStartingPage={isModuleStartingPage}
                    testStatus={testStatus}
                    testComponent={currentTestComponent}
                    nextQuestion={nextQuestion}
                    prevQuestion={prevQuestion}
                /> : (
                    <Section
                        testId={testId}
                        test={test}
                        testEnded={testEnded}
                        setTestEnded={setTestEnded}
                        setTest={setTest}
                        testComponents={testComponents}
                        currentTestComponent={currentTestComponent}
                        currentQuestionNumber={currentQuestionNumber}
                        setCurrentQuestionNumber={setCurrentQuestionNumber}
                        questionsAnswered={questionsAnswered}
                        isFirstPage={isFirstPage}
                        isModuleStartingPage={isModuleStartingPage}
                        showMap={showMap}
                        toggleMap={toggleMap}
                        prevQuestion={prevQuestion}
                        nextQuestion={nextQuestion}
                        submitSection={submitSection}
                    />
                )
        )
    )
};

const mapStateToProps = (state) => {
    return {
        testsByUserId: state.test.testsByUserId,
        fetchTestsByUserId: fetchTestsByUserId,
    }
}

export default withRouter(connect(mapStateToProps, {fetchTestsByUserId})(Test));