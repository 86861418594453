import React, {useEffect} from "react";
import {Row} from "reactstrap";
import Widget from "../../../components/widget";

import s from "../../../styles.scss";


export const Inbox = () => {

    // useEffect(() => {
    //
    // }

    return (
        <div style={{position: 'relative', flexGrow: 1, padding: '24px 0 68px'}}>
            <h2 style={{marginBottom: "2em"}}>Inbox</h2>


            {/*<div style={{ textAlign: 'center' }}>*/}
            {/*    <canvas id="graphCanvas" width="600" height="600"></canvas>*/}
            {/*</div>*/}

        </div>
    )
}