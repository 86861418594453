import {FETCH_USER_INFO_FAILURE, FETCH_USER_INFO_SUCCESS} from "../actions/user";

const initialState = {
    userInfo: {},
    error: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_INFO_SUCCESS:
            return { ...state, userInfo: action.payload, error: null };
        case FETCH_USER_INFO_FAILURE:
            return { ...state, userInfo: {}, error: action.payload };
        default:
            return state;
    }
};

export default userReducer;