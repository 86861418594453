import React  from "react";
import {withRouter} from "react-router";
import StudentLayout from "./student";
import AdminLayout from "./admin";
import s from "./styles.scss";

const Layout = (props) => {
    const role = localStorage.getItem('role');

    return (
        <div className={s.root}>
            <div className={s.wrap}>
                {
                    role === 'ADMIN' ?
                        <AdminLayout url={props.location.pathname} /> :
                        <StudentLayout url={props.location.pathname} />
                }
            </div>
        </div>
    );
}

export default withRouter(Layout);