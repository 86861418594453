import React from 'react';
import s from "../default/styles.scss";
import {TestStatus} from "../../../models/AssignedTest";
import {Col, Row} from "reactstrap";
import {CheckboxIcon} from "../../../../../images/CheckboxIcon";
import {TimerIcon} from "../../../../../images/TimerIcon";
import {WarningIcon} from "../../../../../images/WarningIcon";

export const ModuleDefaultPage = ({
                                      testComponent,
                                      testStatus
                                  }) => {
    return (
        <>
            <div className={`${s.default_page} ${s.module_padding}`}>
                <div className={s.desc_box}>
                    {testStatus === TestStatus.COMPLETED ?
                        <div>You've already completed this test!</div> : (
                            <>
                                <h1>
                                    {testComponent.title}
                                    <span style={{color: "#4068d7"}}>{` Module ${testComponent.module}`}</span>
                                </h1>
                                <div className={`${s.container} ${s.border}`}>
                                    <Row className={s.flex_row}>
                                        <Col className={s.flex_col}>
                                            <TimerIcon/>
                                        </Col>
                                        <Col className={`${s.flex_col} ${s.content}`}>
                                            {`${testComponent.minutes} minutes`}
                                        </Col>
                                    </Row>
                                    <Row className={s.flex_row}>
                                        <Col className={s.flex_col}>
                                            <CheckboxIcon className={s.image_container}/>
                                        </Col>
                                        <Col className={`${s.flex_col} ${s.content}`}>
                                            {`${testComponent.noOfQuestions} multiple-choice questions`}
                                        </Col>
                                    </Row>
                                </div>
                                <Col>
                                    <div className={s.direction_content}>
                                        DIRECTIONS
                                    </div>
                                    {testComponent.title === "Reading and Writing" ? (
                                        <div className={s.direction}>
                                            The questions in this section address a number of important reading
                                            and
                                            writing
                                            skills.
                                            <br/>
                                            <br/>
                                            Read each question carefully, noting any diagrams, graphs, or tables
                                            provided,
                                            and then choose the best answer to the question based on the
                                            passage(s).

                                            <br/>
                                            <br/>
                                            All question in this section are multiple-choice with four answer
                                            choices.
                                            Each question has a single best answer.
                                        </div>
                                    ) : (
                                        <div className={s.direction}>
                                            The questions in this section address a number of important math
                                            skills.
                                            <br/>
                                            <br/>
                                            Each question includes one or more passages, which may include a
                                            table or graph.
                                            Read each passage and question carefully,
                                            and then choose the best answer to the question based on the
                                            passage(s).

                                            <br/>
                                            <br/>
                                            All question in this section are multiple-choice with four
                                            answer
                                            choices. Each
                                            question has a single best answer.
                                        </div>
                                    )}

                                    <Row className={`${s.container} ${s.border} ${s.flex_row}`}>
                                        <Col className={`${s.flex_col} ${s.icon_padding}`}>
                                            <WarningIcon/>
                                        </Col>
                                        <Col className={s.flex_col}>
                                            <Row className={`${s.flex_row} ${s.progress_padding}`}>
                                                <h3>Saved Progress</h3>
                                            </Row>
                                            <Row className={`${s.flex_row} ${s.direction}`}>
                                                To best simulate the actual exam, your progress will only be saved
                                                up
                                                until
                                                previously submitted sections any progress made on this section will
                                                not
                                                be saved.
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}