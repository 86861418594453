import React from 'react';

import s from "../styles.scss";
import {AnnotateIcon} from "../../../../../images/AnnotateIcon";
import {ThreeDotsIcon} from "../../../../../images/ThreeDotsIcon";


export const ReadingAndWritingNav = ({toggleAnnotate, toggleMore, dropdownRef}) => {


    return (
        <div className={s.tools}>

            {/* Annotate */}
            <div
                onClick={toggleAnnotate}
                className={s.tool}
            >
                {/*<div className={s.tool_image}>*/}
                {/*    <AnnotateIcon/>*/}
                {/*</div>*/}
                {/*<div className={s.tool_desc}>Annotate</div>*/}
            </div>

            {/* More */}
            <div
                onClick={toggleMore}
                className={s.tool}
                ref={dropdownRef}
            >
                <div className={s.tool_image}>
                    <ThreeDotsIcon/>
                </div>
                <div className={s.tool_desc}>More</div>
            </div>
        </div>
    )
}