import {
    FETCH_TESTS_FAILURE,
    FETCH_TESTS_SUCCESS,
    FETCH_ASSIGNED_TESTS_BY_USERID_SUCCESS,
    FETCH_ASSIGNED_TESTS_BY_USERID_FAILURE,
    FETCH_ASSIGNED_TEST_BY_USERID_SUCCESS,
    FETCH_ASSIGNED_TEST_BY_USERID_FAILURE,
    UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ_SUCCESS,
    FETCH_ASSIGNED_TESTS_BY_USERID_OBJ_FAILURE,
    RESET_TESTS_STATE
} from "../actions/test";

const initialState = {
    testsByUserId: [],
    testByUserId: {},
    testsByUserIdObj: {},
    tests: [],
    error: null,
};

const testReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TESTS_SUCCESS:
            return { ...state, tests: action.payload, error: null };
        case FETCH_TESTS_FAILURE:
            return { ...state, tests: [], error: action.payload };
        case FETCH_ASSIGNED_TESTS_BY_USERID_SUCCESS:
            return { ...state, testsByUserId: action.payload, error: null };
        case FETCH_ASSIGNED_TESTS_BY_USERID_FAILURE:
            return { ...state, testsByUserId: [], error: action.payload };
        case FETCH_ASSIGNED_TEST_BY_USERID_SUCCESS:
            return { ...state, testByUserId: action.payload, error: null };
        case FETCH_ASSIGNED_TEST_BY_USERID_FAILURE:
            return { ...state, testByUserId: {}, error: action.payload };
        case UPDATE_ASSIGNED_TESTS_BY_USERID_OBJ_SUCCESS:
            return { ...state, testByUserIdObj: action.payload};
        case FETCH_ASSIGNED_TESTS_BY_USERID_OBJ_FAILURE:
            return { ...state, testByUserIdObj: {}, error: action.payload };
        case RESET_TESTS_STATE:
            return initialState;
        default:
            return state;
    }
};

export default testReducer;
