import React from "react";

export const FunctionIcon = () => {
    return (
        <svg width="30px" height="30px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <title>functions</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="invisible_box" data-name="invisible box">
                    <rect width="48" height="48" fill="none"/>
                </g>
                <g id="Q3_icons" data-name="Q3 icons">
                    <g>
                        <path d="M24,25.9a14.7,14.7,0,0,1,3.5-9.6,2,2,0,0,0-3-2.6A18.2,18.2,0,0,0,20,25.9a18.4,18.4,0,0,0,4.5,12.5A2.2,2.2,0,0,0,26,39a2.4,2.4,0,0,0,1.4-.5,2.2,2.2,0,0,0,.1-2.9A14.2,14.2,0,0,1,24,25.9Z"/>
                        <path d="M41.5,13.7a2,2,0,0,0-3,2.6A14.7,14.7,0,0,1,42,25.9a14.2,14.2,0,0,1-3.5,9.7,2.2,2.2,0,0,0,.1,2.9A2.4,2.4,0,0,0,40,39a2.2,2.2,0,0,0,1.5-.6A18.4,18.4,0,0,0,46,25.9,18.2,18.2,0,0,0,41.5,13.7Z"/>
                        <path d="M38.7,30.9,35.4,26l3.3-4.9a2,2,0,1,0-3.4-2.2L33,22.4l-2.3-3.5a2,2,0,1,0-3.4,2.2L30.6,26l-3.3,4.9a2.1,2.1,0,0,0,.6,2.8A2,2,0,0,0,29,34a2.1,2.1,0,0,0,1.7-.9L33,29.6l2.3,3.5A2.1,2.1,0,0,0,37,34a2,2,0,0,0,1.1-.3A2.1,2.1,0,0,0,38.7,30.9Z"/>
                        <path d="M18,15H15.8l.5-3.5A3.9,3.9,0,0,1,20.2,8H22a2,2,0,0,0,0-4H20.2a8.1,8.1,0,0,0-7.9,6.9L11.7,15H9a2,2,0,0,0,0,4h2.2L8.7,36.5A3.9,3.9,0,0,1,4.8,40H4a2,2,0,0,0,0,4h.8a8.1,8.1,0,0,0,7.9-6.9L15.2,19H18a2,2,0,0,0,0-4Z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}