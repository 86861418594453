import React from 'react';
import s from './styles.scss';
import {ReadingAndWritingDirection} from "./readingAndWriting";
import {MathDirection} from "./math";
import {MATH} from "../../utils/utils";

export const DirectionContent = ({title, toggleDirection}) => {
    return (
        <>
            <div
                onClick={toggleDirection}
                className={s.wrapper}
            >
                <div className={s.container}>
                    <div className={s.triangle_arrow}/>
                    <div className={s.content_wrapper}>
                        <div className={s.content_container}>
                            <div className={s.content}>
                                {title === MATH ? <MathDirection /> : <ReadingAndWritingDirection/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}