import React from "react";
import {
    Table
} from "reactstrap";
import s from "./styles.scss";

export const TestDatesTable = ({testDates}) => {
    return (
        <div className={s.table_container}>
            <Table className={s.table_wrapper} responsive>
                <tbody>
                {
                    testDates.map(date =>
                        <tr key={date.id}>
                            <td key={date.id}>
                                <span style={{alignItems: "center"}}>
                                    {date.testDate}
                                </span>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
        </div>
    )
}