import React from "react";

export const WarningIcon = () => {
    return (
        <svg width="100px" height="100px" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M19.9999 36.7077C19.5555 36.7077 19.1319 36.6244 18.7291 36.4577C18.3263 36.291 17.9583 36.0549 17.6249 35.7494L4.24994 22.3744C3.94439 22.041 3.70828 21.673 3.54161 21.2702C3.37494 20.8674 3.29161 20.4438 3.29161 19.9994C3.29161 19.5549 3.37494 19.1244 3.54161 18.7077C3.70828 18.291 3.94439 17.9299 4.24994 17.6244L17.6249 4.24935C17.9583 3.91602 18.3263 3.67296 18.7291 3.52018C19.1319 3.3674 19.5555 3.29102 19.9999 3.29102C20.4444 3.29102 20.8749 3.3674 21.2916 3.52018C21.7083 3.67296 22.0694 3.91602 22.3749 4.24935L35.75 17.6244C36.0833 17.9299 36.3263 18.291 36.4791 18.7077C36.6319 19.1244 36.7083 19.5549 36.7083 19.9994C36.7083 20.4438 36.6319 20.8674 36.4791 21.2702C36.3263 21.673 36.0833 22.041 35.75 22.3744L22.3749 35.7494C22.0694 36.0549 21.7083 36.291 21.2916 36.4577C20.8749 36.6244 20.4444 36.7077 19.9999 36.7077ZM18.3333 21.666H21.6666V11.666H18.3333V21.666ZM19.9999 26.666C20.4722 26.666 20.868 26.5063 21.1874 26.1869C21.5069 25.8674 21.6666 25.4716 21.6666 24.9994C21.6666 24.5271 21.5069 24.1313 21.1874 23.8119C20.868 23.4924 20.4722 23.3327 19.9999 23.3327C19.5277 23.3327 19.1319 23.4924 18.8124 23.8119C18.493 24.1313 18.3333 24.5271 18.3333 24.9994C18.3333 25.4716 18.493 25.8674 18.8124 26.1869C19.1319 26.5063 19.5277 26.666 19.9999 26.666Z"
                    fill="#FFC700"/>
            </g>
        </svg>
    )
}