import React from 'react';
import s from "./styles.scss";
import {Link} from "react-router-dom";
import {SaveAndExitIcon} from "../../../../images/SaveAndExitIcon";

export const More = () => {
    return (
        <div className={s.wrapper}>
            <Link
                to="/template/dashboard"
                style={{textDecoration: "none"}}
            >
                <div className={s.more_button}>
                    <SaveAndExitIcon />
                    <span className={s.more_desc}>Save and Exit</span>
                </div>
            </Link>
        </div>
    );
}