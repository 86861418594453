import {getToken, postData, SESSION_TOKEN} from "../../utils/api";
import jwt from 'jsonwebtoken';

export const JWT_SECRET = "JWT digital-sat"; // TODO: make this global
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const USER_AUTHENTICATED = 'authenticated';

export function receiveLogin() {
    return {
        type: LOGIN_SUCCESS
    };
}

function loginError(payload) {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

const clientId = "883049042514-8g6v209u4djuvtv3k18nb2c4jbpt5c38.apps.googleusercontent.com"


// logs the user out
export const logoutUser = () => {
    return (dispatch) => {
        dispatch(requestLogout());
        localStorage.removeItem(USER_AUTHENTICATED);
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        localStorage.removeItem(SESSION_TOKEN);

        // googleLogout();
        dispatch(receiveLogout());
    };
}

export const loginUser = (creds) => {
    return (dispatch) => {

        postData(`/api/login`, creds)
            .then((response) => {
                processLogin(dispatch, response.data)
            })
            .catch((error) => {
                console.error(error);
                dispatch(loginError('Something went wrong. Please try again.'));
            });
    }
}

export const loginUserViaGoogleOAuth = (response) => {
    return (dispatch) => {
        const headers = response.headers;
        postData(`/api/sessions/oauth/google`, response, {headers})
            .then((response) => {
                processLogin(dispatch, response.data)
            })
            .catch((error) => {
                console.error(error);
                dispatch(loginError('Something went wrong. Please try again.'));
            });
    }
}

const processLogin = (dispatch, data) => {
    const {token, role, userId} = data;

    const storedToken = getToken();
    validateToken(storedToken);

    if (role != null) {
        localStorage.setItem(USER_AUTHENTICATED, "true");
        localStorage.setItem('role', role); // Use role variable instead of string 'role'
        localStorage.setItem('userId', userId)
        localStorage.setItem(SESSION_TOKEN, token)
        dispatch(receiveLogin());
    } else {
        dispatch(loginError('Something was wrong. Try again'));
    }
}

const validateToken = (token) => {
    try {
        jwt.verify(token, JWT_SECRET, {algorithms: ['none']}, (err, decodedToken) => {
            if (err) {
                logoutUser();
            } else {
                if (decodedToken.exp * 1000 > Date.now()) {
                    localStorage.setItem(USER_AUTHENTICATED, "true");
                } else {
                    logoutUser();
                }
            }
        });
    } catch (error) {
        console.error(error);
        logoutUser();
    }
};