import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import Widget from "../components/widget";
import {ScoreResultsChart} from "./components/charts/ScoreResultsBarChart";
import { withRouter} from "react-router";
import {fetchTestsByUserId} from "../../../redux/actions/test";
import s from "../styles.scss";
import PropTypes from "prop-types";
import {LoadingSpinner} from "../../Test/components/loading";
import {changeActiveSidebarItem} from "../../../redux/actions/navigation";
import LinksGroup from "../components/sidebar/LinksGroup";
import {TestStatus} from "../../Test/models/AssignedTest";
import {timestampConverter} from "../../../utils/utils";
import {TestDatesTable} from "./components/tables/TestDates";
import {TestsTable} from "./components/tables/TestsTable";
import {NoData} from "./components/noData";
import {Link} from "react-router-dom";
// import ChatWidget from "./components/chat";
// import OpenAI from "openai";

const StudentDashboard = (props) => {
    const [tests, setTests] = useState(props.testsByUserId || []);
    const [completedTests, setCompletedTests] = useState([])
    const [assignTests, setAssignedTests] = useState([])
    const [testDates, setTestDates] = useState([])

    useEffect(() => {

        if (props.testsByUserId) {
            const toBeSorted = props.testsByUserId.filter(t => t.status === TestStatus.COMPLETED);
            toBeSorted.sort((a, b) => b.completedDate - a.completedDate);
            setCompletedTests(toBeSorted);
            setAssignedTests(props.testsByUserId.filter(t => t.status !== TestStatus.COMPLETED));
        }

        // getData(`/api/test-dates}`)
        //     .then(({data}) => {
        //         console.log("DATA: ", data)
        //         setTestDates(data);
        //     })
        //     .catch(e => console.error(e));
    }, [props.testsByUserId])

    return (
        props.userInfo.firstName && tests ? (
            <>
                <Row className={s.container_row}>
                    <Col className={s.wrapper_col}>
                        <Widget
                            title={'Latest Result'}
                            component={'LatestResult'}
                        >
                            {completedTests && completedTests.length > 0 ?
                                    <>
                                        <Col className={s.flex_col}>
                                            <Row className={s.flex_row_space_between} style={{paddingBottom: '1rem'}}>
                                                <Col className={s.flex_col}>

                                                    <h2 style={{paddingBottom: '.6rem'}}>{`${completedTests[0].testType} ${completedTests[0].testNumber}`}</h2>
                                                    <div className={s.item_v2}>
                                                        {timestampConverter(completedTests[0].completedDate)}
                                                    </div>

                                                </Col>
                                                <Link
                                                    style={{textDecoration: "none"}}
                                                    to={{
                                                        pathname: `/template/tests/${completedTests[0].testId}/report`,
                                                        state: {testId: completedTests[0].testId},
                                                    }}
                                                >
                                                    <Button type="submit" className={`${s.btn}`}>
                                                        View Report
                                                    </Button>
                                                </Link>
                                            </Row>
                                            <Row className={s.flex_row_space_between} style={{gap: '1.5rem'}}>
                                                <Col className={s.flex_col}>
                                                    <div className={s.item}>Total</div>
                                                    <div className={s.scores}>{completedTests[0].result.total}</div>
                                                </Col>
                                                <Col className={s.flex_col}>
                                                    <div className={s.item}>R & W</div>
                                                    <div
                                                        className={s.scores}>{completedTests[0].result.readingAndWriting}</div>
                                                </Col>
                                                <Col className={s.flex_col}>
                                                    <div className={s.item}>Math</div>
                                                    <div className={s.scores}>{completedTests[0].result.math}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </> :
                                    <NoData content={'No tests taken yet!'}/>
                            }
                        </Widget>
                        <Widget
                            title={'SAT Test Dates'}
                            component={'satTestDates'}
                        >
                            <div className={s.item}>
                                <div style={{paddingTop: "3px"}}>
                                    <span className={s.source}>source: </span>
                                    <a className={s.href} target="_blank" rel="noopener noreferrer"
                                       href={'https://satsuite.collegeboard.org/sat/registration/dates-deadlines'}>Collegeboard</a>
                                </div>
                            </div>
                            <TestDatesTable testDates={testDates}/>

                        </Widget>
                    </Col>
                    <Row className={s.wrapper_row}>
                        <Widget
                            title={'SAT Scores'}
                            component={'satScores'}
                        >
                            {completedTests.length > 0 ?
                                    <ScoreResultsChart tests={completedTests}/> :
                                    <NoData content={'No completed tests yet!'}/>
                            }
                        </Widget>
                    </Row>
                </Row>
                <Row className={`${s.flex_row_start} ${s.center_vertical}`}>
                    <div style={{width: "150px"}}>
                        <h3 className={s.flex_row_start}>Active Tests</h3>
                    </div>
                    <LinksGroup
                        onActiveSidebarItemChange={(activeItem) => props.dispatch(changeActiveSidebarItem(activeItem))}
                        activeItem={props.activeItem}
                        header={`see all  >>`}
                        isHeader
                        link={"/template/tests"}
                        style={{padding: '10px 0 2px 0'}}
                        index={"tests"}
                    />
                </Row>
                <TestsTable
                    tests={assignTests}
                    areCompletedTests={false}
                />
            </>
        ) :
        (
            <Row className={s.container_row}>
                <LoadingSpinner
                    isLoading={true}
                    isDashboard={true}
                />
            </Row>
        )
    )
}

StudentDashboard.propTypes = {
    testsByUserId: PropTypes.array,
}

const mapStateToProps = (state) => {
    return {
        testsByUserId: state.test.testsByUserId,
        fetchTestsByUserId: fetchTestsByUserId,
        userInfo: state.user.userInfo,
    }
}

export default withRouter(connect(mapStateToProps)(StudentDashboard));
