import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {Nav} from "reactstrap";
import s from "./styles.scss";
import {changeActiveSidebarItem} from "../../../../redux/actions/navigation";
import {fetchUserInfo} from "../../../../redux/actions/user";
import DropdownMenu from "../dropdown";
import LinksGroup from "../sidebar/LinksGroup";
import ProfileIcon from "../../../../images/ProfileIcon";
import SettingsIcon from "../../../../images/SettingsIcon";
import SignOutIcon from "../../../../images/SignOutIcon";
import ReportErrorIcon from "../../../../images/ReportErrorIcon";
import RequestTutoringIcon from "../../../../images/RequestTutoringIcon";

const Header = (props) => {
    const alphabetColors = {
        A: '#03CFC4',
        B: '#F17990',
        C: '#F26B65',
        D: '#EA449C',
        E: '#5E83DB',
        F: '#03CFC4',
        G: '#EA449C',
        H: '#5E83DB',
        I: '#F26B65',
        J: '#03CFC4',
        K: '#2BADE9',
        L: '#EA449C',
        M: '#5E83DB',
        N: '#F26B65',
        O: '#2BADE9',
        P: '#03CFC4',
        Q: '#EA449C',
        R: '#5E83DB',
        S: '#2BADE9',
        T: '#F26B65',
        U: '#EA449C',
        V: '#5E83DB',
        W: '#2BADE9',
        X: '#03CFC4',
        Y: '#2BADE9',
        Z: '#F26B65',
    };

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.body.addEventListener('click', handleOutsideClick);
        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const renderLinksGroup = (header, link, index) => (
        <LinksGroup
            onActiveSidebarItemChange={(activeItem) => props.dispatch(changeActiveSidebarItem(activeItem))}
            activeItem={props.activeItem}
            header={header}
            isHeader
            link={link}
            index={index}
        />
    );

    const renderDropdownGroup = (header, link, index, imgSrc) => (
        <DropdownMenu
            onActiveSidebarItemChange={(activeItem) => props.dispatch(changeActiveSidebarItem(activeItem))}
            toggleDropdown={toggleDropdown}
            activeItem={props.activeItem}
            header={header}
            isHeader
            link={link}
            index={index}
            imgSrc={imgSrc}
        />
    );

    return (
        props.userInfo.firstName && (
            <Nav className={s.nav_bar}>
                <div className={s.nav_content}>
                    <div className={s.logo}>
                        <Link
                            style={{textDecoration: "none"}}
                            to={`/template/dashboard`}
                        >
                            <div className={s.title}>PrepNerds</div>
                        </Link>
                    </div>
                    {
                        props.userInfo.role === "ADMIN" ?
                            <div className={s.nav_links}>
                                {renderLinksGroup("Dashboard", "/template/dashboard", "dashboard")}
                                {renderLinksGroup("Create Test", "/template/create-test", "create-test")}
                                {renderLinksGroup("Assign Test", "/template/assign-test", "assign-test")}
                            </div> :
                            <div className={s.nav_links}>
                                {renderLinksGroup("Dashboard", "/template/dashboard", "dashboard")}
                                {renderLinksGroup("Tests", "/template/tests", "tests")}
                                {renderLinksGroup("Progress", "/template/progress", "progress")}
                            </div>
                    }

                    <div className={s.nav_profile} ref={dropdownRef}>
                        <span
                            className={`${s.nav_dropdown} ${s.user_name}`}
                            onClick={toggleDropdown}
                        >
                            <div
                                className={`${s.avatar} ${s.rounded_circle}`}
                                style={{backgroundColor: `${alphabetColors[props.userInfo.firstName[0].toUpperCase()]}`}}
                            >
                                <span className={s.user_initial}>{props.userInfo.firstName[0].toUpperCase()}</span>
                            </div>

                            {isOpen && (
                                <ul className={s.dropdown_menu}>
                                    {renderDropdownGroup("Profile", "/template/profile", "profile", ProfileIcon)}
                                    {renderDropdownGroup("Settings", "/template/inbox", "settings", SettingsIcon)}
                                    {renderDropdownGroup("Request 1-1 Tutoring", "/template/inbox", "requestTutoring", RequestTutoringIcon)}
                                    {renderDropdownGroup("Report Error", "/template/inbox", "reportError", ReportErrorIcon)}
                                    {renderDropdownGroup("Sign Out", "", "logout", SignOutIcon)}
                                </ul>
                            )}
                        </span>
                    </div>
                </div>
            </Nav>
        )
    );
};

Header.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebarOpened: PropTypes.bool,
    direction: PropTypes.string,
    onActiveSidebarItemChange: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (store) => {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        sidebarStatic: store.navigation.sidebarStatic,
        userInfo: store.user.userInfo,
        error: store.admin.error,
        dispatch: store.dispatch,
        fetchUserInfo: fetchUserInfo
    };
}

export default withRouter(connect(mapStateToProps)(Header));