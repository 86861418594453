import React, {useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Redirect, withRouter} from "react-router";

import {connect} from "react-redux";
import {
    Button,
    FormGroup,
} from "reactstrap";
import {loginUser} from "../../../../redux/actions/auth";
import hasToken from "../../../../utils/authService";
import s from "./styles.scss";
import LoginViaGoogle from "./oauth/google";

const Login = (props) => {
    const [initialState, setInitialState] = useState({
        email: '',
        password: '',
        isAdmin: false
    })

    const [isThirdPartyLoggingIn, setIsThirdPartyLoggingIn] = useState(false);

    const doLogin = (e) => {
        e.preventDefault();
        props.dispatch(loginUser({email: initialState.email, password: initialState.password}))
    }

    const changeCreds = (event) => setInitialState({...initialState, [event.target.name]: event.target.value})

    const toggleIsAdmin = () => setInitialState({...initialState, isAdmin: !initialState.isAdmin})

    const {from} = props.location.state || {from: {pathname: '/template'}};
    if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
        return (
            <Redirect to={from}/>
        )
    }

    return (
        <div
            style={{
                background: '#F5F5F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <div className={s.login_container}>
                <div style={{width: "473px"}} className={s.login_form}>
                    <div className={s.login_form_inner}>
                        {/* Top Header */}
                        <div>PrepNerds</div>
                        <h1 style={{paddingBottom: "35px"}}>Log In</h1>
                        <div className={s.select_container}>
                            <div
                                className={`${s.col} ${initialState.isAdmin ? '' : s.selected_col}`}
                                onClick={toggleIsAdmin}
                            >
                                Student
                            </div>
                            <div
                                className={`${s.col} ${initialState.isAdmin ? s.selected_col : ''}`}
                                onClick={toggleIsAdmin}
                            >
                                Admin
                            </div>
                        </div>

                        <form onSubmit={(event) => doLogin(event)}>
                            <FormGroup>
                                {/* Sign in with Email: Email */}
                                <div className={s.login_form_group}>
                                    <label>
                                        Email
                                        <span className={s.required_star}>*</span>
                                    </label>

                                    <input
                                        id="email"
                                        value={initialState.email}
                                        onChange={(event) => changeCreds(event)}
                                        type="email"
                                        required
                                        name="email"
                                        placeholder="email"
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup>
                                {/* Sign in with Email: Password */}
                                <div className={s.login_form_group} style={{marginBottom: "5px"}}>
                                    <label> Password
                                        <span className={s.required_star}>*</span>
                                    </label>
                                    <input
                                        id="password"
                                        value={initialState.password}
                                        onChange={(event) => changeCreds(event)}
                                        type="password"
                                        required
                                        name="password"
                                        placeholder="Minimum 8 characters"
                                    />
                                </div>
                            </FormGroup>

                            {/* Remember Me & Forgot Password? */}
                            <div className={s.rem_container}>
                                <Link className={s.link_style} to="#">
                                    Forgot Your Password?
                                </Link>
                            </div>

                            {/* Login button */}
                            <Button type="submit" className={`${s.rounded_btn} ${s.login_cta}`}>Log In</Button>

                            <div className={s.or_separator}>OR</div>

                            {/*/!* Sign in with Google *!/*/}
                            <LoginViaGoogle/>

                            <div className={s.login_footer}>
                                <span>
                                    By continuing, you agree to PrepNerds' Terms of Service and acknowledge
                                    you've read our Privacy Policy.
                                </span>
                                <div className={s.line}/>
                                {/* Register */}
                                <div
                                    className={s.rem_container}
                                    style={{justifyContent: "center"}}
                                >
                                    <Link
                                        className={s.link_style}
                                        style={{fontSize: "13px"}}
                                        to={"/register"}
                                    >
                                        Not on PrepNerds yet? Create an account
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Login));
