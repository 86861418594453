import React, {useEffect} from 'react';

export const TexGraphRenderer = ({data}) => {

    useEffect(() => {
        const canvas = document.getElementById("cartesianCanvas");
        const ctx = canvas.getContext("2d");
        // Set min and max values for x-axis and y-axis
        const min = -5;
        const max = 5;

        // Calculate the scale factor based on canvas size
        const scaleX = canvas.width / (max - min);
        const scaleY = canvas.height / (max - min);

        // Set the origin to the center of the canvas
        const originX = -min * scaleX;
        const originY = max * scaleY;

        drawCartesianCoordinateGraph(ctx, canvas, min, max, scaleX, scaleY, originX, originY)
            .then(() =>  drawGraph(ctx, min, max, originX, originY, scaleX, scaleY, data));
    }, [data])

    const drawCartesianCoordinateGraph = async (ctx, canvas, min, max, scaleX, scaleY, originX, originY) => {
        if (data) {
            drawXAxis(ctx, canvas, originX);
            drawYAxis(ctx, canvas, originY);
            drawGridLines(ctx, canvas, min, max, originX, scaleX, originY, scaleY);
        }
    }

    const drawXAxis = (ctx, canvas, originX) => {
        // Draw x-axis
        ctx.beginPath();
        ctx.moveTo(0, originX);
        ctx.lineTo(canvas.width, originX);
        ctx.strokeStyle = '#141414';
        ctx.stroke();
    }

    const drawYAxis = (ctx, canvas, originY) => {
        // Draw y-axis
        ctx.beginPath();
        ctx.moveTo(originY, 0);
        ctx.lineTo(originY, canvas.height);
        ctx.strokeStyle = '#141414';
        ctx.stroke();
    }

    const drawGridLines = (ctx, canvas, min, max, originX, scaleX, originY, scaleY) => {
        // Draw grid lines
        const gridSize = 1;
        ctx.strokeStyle = '#EDEEF0';
        ctx.lineWidth = 0.5;

        // Vertical grid lines
        for (let x = min + gridSize; x < max; x += gridSize) {
            ctx.beginPath();
            ctx.moveTo(originX + x * scaleX, 0);
            ctx.lineTo(originX + x * scaleX, canvas.height);
            ctx.stroke();
        }

        // Horizontal grid lines
        for (let y = min + gridSize; y < max; y += gridSize) {
            ctx.beginPath();
            ctx.moveTo(0, originY - y * scaleY);
            ctx.lineTo(canvas.width, originY - y * scaleY);
            ctx.stroke();
        }
    }

    const drawGraph = (ctx, min, max, originX, originY, scaleX, scaleY, data) => {
        // Draw the graph of y = x^2 - x - 2
        ctx.beginPath();
        // ctx.moveTo(0, 0);
        const coordinates = [];

        for (let x = min; x <= max; x += 0.1) {
            const y = data[0] * x ** 2 + data[1] * x + data[2];
            ctx.lineTo(originX + x * scaleX, originY - y * scaleY);

            const epsilon = 0.0001;
            if (Math.abs(y) < epsilon) {
                coordinates.push({ x: Math.round(x), y: 0 });
            }

            // Check if x is close to 0
            if (Math.abs(x) < epsilon) {
                coordinates.push({ x: 0, y: Math.round(y) });
            }
        }

        ctx.strokeStyle = '#2B2C2D';
        ctx.lineWidth = 2;
        ctx.stroke();

        ctx.font = "17px serif";
        ctx.fillStyle = '#041528';
        coordinates.forEach(coord => {
            ctx.fillText(`(${coord.x}, ${coord.y})`, originX + coord.x * scaleX, originY - coord.y * scaleY - 5);
            ctx.beginPath();
            ctx.arc(coord.xCoord, coord.yCoord, 3, 0, 2 * Math.PI, false);
            ctx.fillStyle = '#041528';
            ctx.fill();
            ctx.stroke();

        });

    };

    return (
        <canvas
            id="cartesianCanvas"
            width="400"
            height="400"
            style={{ border: "1px solid #EDEEF0" }} // Light gray for outer border
        ></canvas>
    );
};
