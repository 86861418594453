import React, {useEffect} from "react";
import {IndeterminateCheckbox} from "./helpers/TableHelperJs";
import {Table} from "./helpers/Table";
import {fetchTests} from "../../../../../../redux/actions/test";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import s from "../styles.scss";

const SelectTests = ({
                         tests,
                         fetchTests,
                         selectedData,
                         updateSelectedTests,
                         showRequired,
                     }) => {

    useEffect(() => {
        fetchTests();
    }, [fetchTests]);

    // const [data, setData] = React.useState(() => makeData(50));

    const columns = React.useMemo(
        () => [
            {
                id: 'select',
                cell: ({row}) => (
                    <IndeterminateCheckbox
                        row={row}
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        indeterminate={row.getIsSomeSelected()}
                        updateSelectedData={updateSelectedTests}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                accessorFn: row => row.testNumber,
                id: 'testNumber',
                header: () => <span className={s.header}>Test Number</span>,
                cell: info => `Practice Test #${info.getValue()}`,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => row.testId,
                id: 'testId',
                header: () => <span className={s.header}>Test Id</span>,
                cell: info => info.getValue(),
                footer: props => props.column.id,
            }
        ],
        []
    );

    return (
        <>
            <Table
                columns={columns}
                data={tests}
                updateSelectedData={updateSelectedTests}
                selectedData={selectedData}
                showRequired={showRequired}
            />
        </>
    )
};


const mapStateToProps = (state) => {
    return {
        tests: state.test.tests,
        error: state.test.error,
        fetchTests: fetchTests(),
    }
}

export default withRouter(connect(mapStateToProps, {fetchTests})(SelectTests));