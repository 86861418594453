import React from "react";

import s from "../styles.scss";
import {combinedDomainEnum, combinedTopicEnums} from "../../../../utils/models";
import {Col, Row} from "reactstrap";

const Latex = require('react-latex')

export const ReviewMode = ({
                               title = null,
                               section,
                               questionComponent,
                               questionNumber,
                               domainResult
                           }) => {

    const getDomainName = () => combinedDomainEnum.getValueByKey(questionComponent.domain);

    const getTotalNumOfQsByDomain = () => domainResult[getDomainName()].reduce((a, b) => a + b, 0);
    return (
        <>
            <div className={`${s.question} ${s.review_border}`} style={{minWidth: '20vh', padding: "1rem"}}>
                <Col style={{padding: "1rem"}}>
                    <Row className={s.flex_row_space_between} style={{gap: '1.5rem'}}>
                        <Col className={`${s.flex_col_space_between} ${s.content}`}>
                            <div className={s.item}>Answer</div>
                            <div className={s.value_text}>
                                {section.questionsAnswered[questionNumber].correctAnswer}
                            </div>
                        </Col>
                        <Col className={`${s.flex_col_space_between} ${s.content}`}>
                            <div className={s.item}>Domain</div>
                            <div className={s.value_text}>
                                {getDomainName()}
                            </div>
                        </Col>
                        <Col className={`${s.flex_col_space_between} ${s.content}`}>
                            <div className={s.item}>Topic</div>
                            <div className={s.value_text}>
                                {combinedTopicEnums.getValueByKey(questionComponent.topic)}
                            </div>
                        </Col>
                        <Col className={`${s.flex_col_space_between} ${s.content}`}>
                            <div className={s.item}>Difficulty</div>
                            <div className={s.value_text}>
                                {questionComponent.difficulty}
                            </div>
                        </Col>
                    </Row>
                    <Row className={s.flex_col_space_between} style={{paddingTop: '2rem'}}>
                        <Col className={s.flex_col_space_between}>
                            <div className={s.item} style={{fontSize: "1.1rem"}}>Performance Overview by Domain:</div>

                            <Row className={s.flex_row_space_between} style={{gap: '1.5rem'}}>
                                <Col className={`${s.flex_col_space_between} ${s.content}`}>
                                    <div className={s.item}># of Questions</div>
                                    <div className={s.value_text}>
                                        {getTotalNumOfQsByDomain()}
                                    </div>
                                </Col>
                                <Col className={`${s.flex_col_space_between} ${s.content}`}>
                                    <div className={s.item}>Correct</div>
                                    <div className={s.value_text}>
                                        {domainResult[getDomainName()][0]}
                                    </div>
                                </Col>
                                <Col className={`${s.flex_col_space_between} ${s.content}`}>
                                    <div className={s.item}>Incorrect</div>
                                    <div className={s.value_text}>
                                        {domainResult[getDomainName()][1]}
                                    </div>
                                </Col>
                                <Col className={`${s.flex_col_space_between} ${s.content}`}>
                                    <div className={s.item}>Skipped</div>
                                    <div className={s.value_text}>
                                        {domainResult[getDomainName()][2]}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={s.flex_row_start}>
                        <Col className={s.flex_col_space_between}>
                            <div className={s.item}>Explanation:</div>
                            <div className={s.explanation_text} style={{lineHeight: "2em"}}>
                                <Latex >
                                {section.questionsAnswered[questionNumber].explanation}
                                </Latex>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </>
    )
}