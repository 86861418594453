import React, {useEffect, useState} from 'react';
import s from "../styles.scss";
import redBookmark from "../../../../../../images/bookmark_red.png";
import bookmark from "../../../../../../images/bookmark.png";
import crossingOption from "../../../../../../images/crossing_option.png";
import {Calculator} from "./calculator";
import resizer from "../../../../../../images/resizer.png";
import {combinedDomainEnum} from "../../../../utils/models";
import ScatterPlotDisplay from "./d3/ScatterPlot";
import {ImageComponent} from "./images/ImageComponent";
import {LineChart} from "./d3/LineChart";
import {TexGraphRenderer} from "./texHelpers/TexGraphRenderer";
import {ReviewMode} from "../reviewMode";
import {BookmarkRedIcon} from "../../../../../../images/BookmarkRedIcon";
import {BookmarkIcon} from "../../../../../../images/BookmarkIcon";

const Latex = require('react-latex')

export const MathBody = ({
                             questionNumber,
                             questionComponent,
                             leftSizer,
                             toggleLeftSizer,
                             rightSizer,
                             toggleRightSizer,
                             isBookmarked,
                             toggleIsMarked,
                             showCalculator,
                             toggleCrossingOption,
                             showCrossingOption,
                             optionsMap,
                             toggleAnswers,
                             selectedAnswers,
                             isOptionCrossed,
                             handleSelectedCrossingOptions,
                             isReviewMode,
                             section = isReviewMode ? section : {},
                             domainResult
                         }) => {

    // TODO: move this to csvParser
    const [problem, setProblem] = useState(questionComponent.problem);
    const [problemType, setProblemType] = useState(questionComponent.problemType || null);

    const renderQuestionByQuestionType = (problemType, problem) => {
        let content;
        switch (problemType) {
            case 'SCATTER_PLOT':
                content = <ScatterPlotDisplay dataValues={stringToListConverter(problem)}/>
                break;
            case 'GRAPH':
                content = <TexGraphRenderer data={convertStringToArray(problem)}/>;
                break;
            case 'LINE_CHART':
                content = <LineChart data={problem}/>;
                break;
            case 'GEOMETRY':
                content = <ImageComponent imageName={problem}/>;
                break;
            default:
                content = renderLatexWithNewline(problem);
        }

        return content;
    }

    const renderLatexWithNewline = (content) =>
        content.split("\\newline")
            .map((txt, index) =>
                <div key={index}>
                    <Latex>{txt}</Latex>
                </div>)

    const stringToListConverter = (inputString) => {
        try {
            const parsedArray = JSON.parse(inputString);
            return Array.isArray(parsedArray) && parsedArray.every(Array.isArray) ?
                parsedArray : [];
        } catch (error) {
            console.error('Error converting string to list:', error.message);
            return [];
        }
    }

    const convertStringToArray = (inputString) => {
        const trimmedString = inputString.replace(/^\[|\]$/g, '');
        const stringArray = trimmedString.split(',');
        return stringArray.map(element => parseInt(element, 10));
    }

    useEffect(() => {
        const script = document.createElement('script');
        // TODO: #62 need to encrypt this
        script.src = `https://www.desmos.com/api/v1.6/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6`;
        script.async = true;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {

        if (questionComponent.topic !== "Nonlinear equations in 1 variable") {
            const questionPrompt = questionComponent.problem.split("\n");
            setProblem(questionPrompt[0]);
            // setQuestion(questionPrompt.length > 1 ? questionPrompt[1] : "");
        }
    }, [questionComponent]);

    const isBlockMath = (input) => {
        let b = /\[.*\]/.test(input);

        console.log(b)
    }
    const cleanedInput = (input) => {
        input.trim().replace('[', '');
        input.trim().replace(']', '')

        console.log(input)
        return input;
    }

    const setSelectedOrActualAnswers = (key) => {
        const qInfo = section.questionsAnswered;
        return qInfo[questionNumber].correctAnswer === optionsMap[key] ? s.correct_answer :
            qInfo[questionNumber].answered === optionsMap[key] ? s.incorrect_answer : ''
    }

    const getDomainName = () => combinedDomainEnum.getValueByKey(questionComponent.domain);

    const getTotalNumOfQsByDomain = () => domainResult[getDomainName()].reduce((a, b) => a + b, 0);

    return (
        <>
            {isReviewMode ? (
                <>
                    <div className={`${leftSizer ? s.size_60 : s.size_50} ${s.left}`}>
                        <div className={s.right}>
                            <div
                                className={s.question}
                                style={{minWidth: '20vh'}}
                            >
                                {/* Question Header */}
                                <div className={s.question_title}>
                                    {/* Question Index */}
                                    <div className={s.question_idx}>{questionNumber}</div>

                                </div>

                                {/* Question */}
                                <div className={s.question_content}>
                                    <div className={s.content_display}>
                                        {renderQuestionByQuestionType(questionComponent.problemType, problem)}
                                    </div>
                                    {questionComponent.question ?
                                        <div className={s.content_display} style={{paddingTop: '1rem'}}>
                                            {renderLatexWithNewline(questionComponent.question)}
                                        </div>
                                        : null
                                    }
                                </div>

                                {/* Choices */}
                                <div className={s.answer_choices}>
                                    {questionComponent.options.map((option, key) => (
                                            <div
                                                key={key}
                                                className={`${s.option}`}
                                            >
                                                <div
                                                    id={optionsMap[key]}
                                                    onClick={toggleAnswers}
                                                    className={`${s.select} ${setSelectedOrActualAnswers(key)}`}
                                                >
                                                    <div className={s.option_button}>
                                                        {optionsMap[key]}
                                                    </div>
                                                    <span className={`${s.option_content}`}>
                                                            <div className={`${s.option_wrapper}`}>
                                                                <div className={s.option_display}>
                                                                    <Latex strict>{option}</Latex>
                                                                </div>
                                                            </div>
                                                        </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Resizing Vertical Line */}

                    <div className={s.resize}>
                        <div
                            onClick={toggleLeftSizer}
                            className={s.resizer} style={{right: "20px"}}
                        >
                            <img className={`${leftSizer ? s.sizer_toggled : ''}`} src={resizer}
                                 style={{height: "auto", width: "25px"}} alt={"resize_layout"}/>
                        </div>
                        <div
                            onClick={toggleRightSizer}
                            className={`${s.resizer} ${s.right_img}`} style={{left: "20px"}}
                        >
                            <img
                                className={`${rightSizer ? s.sizer_toggled : ''}`}
                                src={resizer}
                                style={{height: "auto", width: "25px"}}
                                alt={"resize_layout"}/>
                        </div>
                    </div>

                    {/* Right Container */}
                    <div
                        className={`${rightSizer ? s.size_60 : s.size_50} ${s.right} ${s.right_custom}`}
                        style={{margin: "3rem"}}
                    >
                        <ReviewMode
                            section={section}
                            questionComponent={questionComponent}
                            questionNumber={questionNumber}
                            domainResult={domainResult}
                        />
                    </div>
                </>
            ) : (
                <>
                    {showCalculator ?
                        <div className={s.left}>
                            <div className={s.left_wrapper}>
                                <Calculator/>
                            </div>
                        </div> : null
                    }

                    <div className={s.right}>
                        <div className={s.question}>

                            {/* Question Header */}
                            <div className={s.question_title}>
                                {/* Question Index */}
                                <div className={s.question_idx}>{questionNumber}</div>

                                {/* Bookmark for Review*/}
                                <div className={s.bookmark}>
                                    {isBookmarked[questionNumber] ?
                                        <img
                                            id={questionNumber}
                                            onClick={toggleIsMarked}
                                            src={redBookmark}
                                            style={{height: '100%', width: 'auto'}}
                                            alt={"Red Bookmark Image"}
                                        /> :
                                        <img
                                            id={questionNumber}
                                            onClick={toggleIsMarked}
                                            src={bookmark}
                                            style={{height: '100%', width: 'auto'}}
                                            alt={"Bookmark Image"}
                                        />
                                    }
                                    Mark for Review
                                </div>

                                {/* Crossing */}
                                <div className={s.crossing_options}>
                                    <div
                                        onClick={toggleCrossingOption}
                                        className={s.crossing_option}
                                    >
                                        {/*<CrossingOptionIcon />*/}
                                        <img src={crossingOption} style={{height: '100%', width: 'auto'}}
                                             alt={"Crossing Option Image"}/>
                                    </div>
                                </div>
                            </div>

                            {/* Question */}
                            <div className={s.question_content}>
                                <div className={s.content_display}>
                                    {renderQuestionByQuestionType(questionComponent.problemType, problem)}
                                </div>
                                {questionComponent.question ?
                                    <div className={s.content_display} style={{paddingTop: '1rem'}}>
                                            {renderLatexWithNewline(questionComponent.question)}
                                    </div> : null
                                }
                            </div>

                            {/* Choices */}
                            <div className={s.answer_choices}>
                                {questionComponent.options.map((option, key) => (
                                    <div
                                        key={key}
                                        className={s.option}
                                    >
                                        <div
                                            id={optionsMap[key]}
                                            onClick={toggleAnswers}
                                            className={`${s.select} ${selectedAnswers[questionNumber] === optionsMap[key] ? s.selected : ''}`}
                                        >
                                            <div className={`${isOptionCrossed(optionsMap[key]) ?
                                                s.crossed_button : s.option_button}`}
                                            >
                                                {optionsMap[key]}
                                            </div>
                                            <span className={s.option_content}>
                                                <div className={s.option_wrapper}>
                                                    <div className={`${isOptionCrossed(optionsMap[key]) ?
                                                        s.option_display_crossed : s.option_display}`}
                                                    >
                                                         <Latex strict>{option}</Latex>
                                                    </div>
                                                    <div className={`${isOptionCrossed(optionsMap[key]) ?
                                                        s.option_crossing : ''}`}
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                        {showCrossingOption ? (
                                                <div
                                                    id={optionsMap[key]}
                                                    onClick={handleSelectedCrossingOptions}
                                                    className={`${s.crossing}`}
                                                >
                                                    <div
                                                        className={`${s.crossing_label}`}>{optionsMap[key]}</div>
                                                    <hr className={`${s.crossing_line}`}/>
                                                </div>
                                            ) :
                                            <hr style={{display: "none"}}/>
                                        }
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}