import React, {useEffect, useRef} from "react";
import s from "../../styles.scss";
import {rankItem} from "@tanstack/match-sorter-utils";

export const IndeterminateClickedUser = ({
                                             row,
                                             indeterminate,
                                             updateSelectedData,
                                             ...rest
                                         }) => {
    const ref = useRef(null);
    return row.getCanExpand() ? (
        <button

            {...{
                onClick: () => updateSelectedData(row),
                style: { cursor: 'pointer' },
            }}
        >
            {row.getIsExpanded() ? '\u25BC' : '\u25B6'}
        </button>
    ) : (
        '🔵'
    )
}

export const IndeterminateCheckbox = ({
                                          row,
                                          indeterminate,
                                          updateSelectedData,
                                          ...rest
                                      }) => {

    const ref = useRef(null);

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked, row]);


    return (
        <div className={s.custom_check}>
            <input
                autoComplete="off"
                type="checkbox"
                checked={rest.checked}
                ref={ref}
                onClick={() => updateSelectedData(row, !rest.checked)}
                {...rest}
                id={row.id}
            />
            <label

                htmlFor={row.id}
            />
        </div>
    );
};

export const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank: itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

export const renderSubComponent = ({row}) => {
    return (
        <pre style={{fontSize: '10px'}}>
      <code>{JSON.stringify(row.original, null, 2)}</code>
    </pre>
    );
};