import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import s from "./styles.scss";

export const NavDirection = ({navs, title, component, testId}) => {

    const [navsLink, setNavsLink] = useState(navs)

    let navStyleMapper = {
        'testReview': s.test_review_nav,
        '': s.nav_direction,
        null: s.nav_direction
    }
    let styleMapper = {
        'progress': s.score_report_title,
        'scoreReport': s.score_report_title,
        '': s.default_title,
        null: s.default_title
    }

    return (
        <div className={`${s.default_nav} ${navStyleMapper[component] ? navStyleMapper[component] : s.nav_direction}`}>
            <nav>
                <ol>
                    {
                        navs && navs.map((nav, idx) => (
                                idx < navs.length - 1 ? (
                                    <React.Fragment key={idx}>
                                        <li key={idx}>
                                            {
                                                nav === 'Report' ?
                                                    <Link
                                                        key={idx}
                                                        className={s.nav_link}
                                                        to={`/template/tests/${testId}/${navsLink[idx].toLowerCase()}`}
                                                    >
                                                        <div>{nav}</div>
                                                    </Link>
                                                    :
                                                    <Link
                                                        key={idx}
                                                        className={s.nav_link}
                                                        to={`/template/${navsLink[idx].toLowerCase()}`}
                                                    >
                                                        <div>{nav}</div>
                                                    </Link>

                                            }
                                        </li>
                                        <li style={{color: '#6c6c6c'}}>
                                            &#62;
                                        </li>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={idx}>
                                        <li>
                                            {nav}
                                        </li>
                                    </React.Fragment>
                                )
                            )
                        )
                    }

                </ol>
            </nav>
            {title === '' ? null :
                <h1 className={styleMapper[component] ? styleMapper[component] : s.default_title}>{title}</h1>}
        </div>
    );
}