import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Switch, Route, withRouter, Redirect} from "react-router";
import PropTypes from "prop-types";
import Header from "../../header/index";
import StudentDashboard from "../../../StudentDashboard";
import Tests from "../../../StudentDashboard/components/tests";
import Progress from "../../../StudentDashboard/components/progress";
import {Profile} from "../../../StudentDashboard/components/profile";
import {Inbox} from "../../../StudentDashboard/components/inbox";
import s from "../styles.scss";
import Report from "../../../StudentDashboard/components/report";
import {fetchUserInfo} from "../../../../../redux/actions/user";
import {fetchTestsByUserId} from "../../../../../redux/actions/test";
import TestReview from "../../../StudentDashboard/components/testReview";

const StudentLayout = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData().then(r => r);
    }, [dispatch]);

    const fetchData = async () => {
        try {
            await Promise.all([
                dispatch(fetchUserInfo()),
                dispatch(fetchTestsByUserId())
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <>
            <Header/>
            <main className={s.content}>
                <Switch>
                    <Route path="/template" exact render={() => <Redirect to={"/template/dashboard"}/>}/>
                    <Route path="/template/dashboard" exact component={StudentDashboard}/>
                    <Route path="/template/tests" exact component={Tests}/>
                    <Route path="/template/progress" exact component={Progress}/>
                    <Route path="/template/tests/:testId/report" exact component={Report}/>
                    <Route path="/template/profile" exact component={Profile}/>
                    <Route path="/template/inbox" exact component={Inbox}/>
                    <Route path="/template/tests/:testId/sections/:sectionNumber/questions/:questionNumber/review" exact component={TestReview}/>
                    <Route path='*' exact render={() => <Redirect to="/error"/>}/>
                </Switch>
            </main>
        </>
    );
}

StudentLayout.propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    userInfo: PropTypes.object,
    testsByUserId: PropTypes.array
}

const mapStateToProps = (store) => {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        userInfo: store.user.userInfo,
        error: store.admin.error,
        fetchUserInfo: fetchUserInfo,
        testsByUserId: store.test.testsByUserId,
        dispatch: store.dispatch
    };
}

export default withRouter(connect(mapStateToProps)(StudentLayout));
