import React from 'react';
import {Nav} from "../nav";
import s from "../../styles.scss";
import {Footer} from "../footer";
import {ModuleDefaultPage} from "../body/moduleDefault";


export const ModuleStartingPage = ({
                                       testStatus,
                                       isFirstPage,
                                       testComponent,
                                       nextQuestion,
                                       prevQuestion,
                                       isModuleStartingPage
                                   }) => {

    return (
        <>
            <Nav>
                <header>
                    <h1 className={s.header}>{`${testComponent.testType} ${testComponent.testNumber}`}</h1>
                </header>
            </Nav>

            <main className={s.main}>
                <ModuleDefaultPage
                    testComponent={testComponent}
                />
            </main>
            <Footer
                isFirstPage={isFirstPage}
                isModuleStartingPage={isModuleStartingPage}
                testStatus={testStatus}
                testType={testComponent.testType}
                testNumber={testComponent.testNumber}
                nextQuestion={nextQuestion}
                prevQuestion={prevQuestion}
            />
        </>
    )
}