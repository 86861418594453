import React from 'react';
import s from "./styles.scss";
import {QuestionMap} from "../../../questionmap/"

export const Overview = ({
                             currentTestComponent,
                             currentQuestionNumber,
                             selectedAnswers,
                             bookmarks,
                             navigateToSpecificQuestion
                         }) => {
    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                <span className={s.title}>
                    Check Your Work
                </span>
                <span className={s.desc}>
                    <p className={s.para}>On test day, you won't be able to move on to the <b>next</b> module until time expires.</p>
                    <p className={s.para}>For these practice questions, you can click <b>Next</b> when you're ready to move on.</p>
                </span>

                <div className={s.question_map}>
                    <QuestionMap
                        currentTestComponent={currentTestComponent}
                        currentQuestionNumber={currentQuestionNumber}
                        selectedAnswers={selectedAnswers}
                        bookmarks={bookmarks}
                        navigateToSpecificQuestion={navigateToSpecificQuestion}
                    />
                </div>
            </div>
        </div>
    )
}