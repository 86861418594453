const axios = require('axios');
const {TestStatus} = require("../components/Test/models/AssignedTest");

axios.defaults.headers.common['Content-Type'] = 'application/json';

// export axios promise to be consumed

// TODO: Enable this later!
// TODO: also, enable "router.use(authenticateToken);" on the server side!
// const headers = {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json'
// };
//
// module.exports = {
//     getData: (uri, headers = {}) => axios.get(uri, { headers }),
//     postData: (uri, data, headers = {}) => axios.post(uri, data, { headers }),
//     putData: (uri, data, headers = {}) => axios.put(uri, data, { headers })
// };
const SESSION_TOKEN = 'session_token';

const getToken = () => localStorage.getItem(SESSION_TOKEN);

const headers = {
    authorization: `Bearer ${getToken()}`
};

const getData = (uri, headers) => axios(uri, {headers});

const postData = (uri, data, headers) => axios.post(uri, data, {headers});

const putData = (uri, data, headers) => axios.put(uri, data, {headers});

const submitAnswersBySection = (testId, request) => {
    putData(`/api/assigned-tests/${testId}?userId=${localStorage.getItem("userId")}`, request, headers)
        .then(r => r)
        .catch(e => console.error(e));
}

const assignUsersToTests = (request) => {
    putData("/api/tests/assign-users", request, headers)
        .then(r => r)
        .catch(e => console.error(e));
}


// TODO: will need to remove userId once JWT is in place and extract userId from JWT
const resetTest = (testId, test, userId = null) => {
    test.sections.forEach(section => {
        delete section.questionsAnswered;
        delete section.areasToImprove;
    })
    // TODO: #42 go through all sections and set 'questionsAnswered' = [] and 'areasToIm
    const request = {
        status: TestStatus.NOT_STARTED,
        currentSection: 1,
        sections: [],
        completedDate: null,
        result: {}
    }

    putData(`/api/assigned-tests/${testId}/reset?userId=${userId ? userId : localStorage.getItem("userId")}`, request, headers)
        .then(r => r)
        .catch(e => console.error(e));
}

module.exports = {
    getData,
    SESSION_TOKEN,
    getToken,
    postData,
    putData,
    assignUsersToTests,
    submitAnswersBySection,
    resetTest
}