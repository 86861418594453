import React from 'react';
import PropTypes from 'prop-types';
import s from './styles.scss';

const WidgetV2 = (props) => {
    const {
        headerClass = '',
        children = [],
        options = {},
        component = '',
        ...restProps
    } = props;

    let styleMapper = {
        'scoreReportInfo': s.report_info_widget,
        'scoreReport': s.report_widget,
        '' : s.default_widget,
        null: s.default_widget
    }

    return (
        <section
            className={`${s.common} ${styleMapper[component]}`}
            {...restProps}
        >
            {children}
        </section>
    )
}

WidgetV2.propTypes = {
    title: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    options: PropTypes.object,
}

export default WidgetV2;
