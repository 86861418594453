import React, {useEffect, useRef, useState} from "react";
import Button from "./helpers/button";
import s from "../../../../styles.scss";
import p from "../../../../StudentDashboard/components/progress/styles.scss"


export const Summary = ({
                            data,
                        }) => {
    const selectedUsersArray = Object.values(data.selectedUsers);
    const selectedTestsArray = Object.values(data.selectedTests);

    const maxLength = Math.max(selectedUsersArray.length, selectedTestsArray.length);

    const paddedUsers = [...selectedUsersArray, ...Array(maxLength - selectedUsersArray.length).fill(null)];
    const paddedTests = [...selectedTestsArray, ...Array(maxLength - selectedTestsArray.length).fill(null)];

    return (
        <>
            <h2>Finishing Up</h2>
            <p>Double-check everything looks OK before confirming.</p>

            <table className={p.table}>
                <thead>
                <tr>
                    <th style={{color: '#2B2C2D'}}>Student</th>
                    <th style={{color: '#2B2C2D'}}>Tests</th>
                </tr>
                </thead>
                <tbody>
                {paddedUsers.map((user, index) => (
                    <tr key={index}>
                        <td style={{border: '1px solid #2B2C2D', padding: '8px'}}>
                            <div style={{color: '#2B2C2D', paddingBottom: '4px'}}>
                                <strong>{user ? user.firstName : ''} {user ? user.lastName : ''}</strong>
                            </div>
                            <div style={{color: '#5E83DB'}}>{user ? user.email : ''}</div>
                        </td>
                        {paddedTests[index] ? (
                            <td style={{border: '1px solid #2B2C2D', padding: '8px'}}>
                                <strong>Test {paddedTests[index].testNumber}</strong>

                            </td>
                        ) : null
                        }
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
};
