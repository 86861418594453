import React from "react";
import {Button, Col, Row} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {TestStatus} from "../../../../Test/models/AssignedTest";
import {resetTest} from "../../../../../utils/api";
import s from "./styles.scss";
import WidgetV2 from "../../../components/widget_v2";
import {timestampConverter} from "../../../../../utils/utils";
import {LoadingSpinner} from "../../../../Test/components/loading";
import {fetchTestsByUserId, resetAllRenderedTests} from "../../../../../redux/actions/test";
import {useDispatch} from "react-redux";

export const TestsTable = ({
                               tests,
                               areCompletedTests
                           }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const resetTestOnClick = async (e) => {
        const testId = e.currentTarget.id
        const test = tests.find(test => test.testId === testId)
        resetTest(testId, test);
        await Promise.all([
            dispatch(resetAllRenderedTests()),
            dispatch(fetchTestsByUserId())
        ]);

        history.push("/template/dashboard");
    }

    return (
        tests && tests.length > 0 ? (
                <Row className={s.container_row}>
                    {tests.map((test, idx) => (
                        <React.Fragment key={`${test.testNumber}_${idx}`}>
                            <WidgetV2>
                                <Col className={`${s.wrapper_col}`}>
                                    <h3 style={{paddingBottom: '.5rem'}}>{`${test.testType} ${test.testNumber}`}</h3>
                                    <div
                                        className={s.item}
                                    >
                                        {`Assigned Date:   `}
                                        <span style={{fontWeight: '300', color: '#2B2C2D'}}>
                                            {timestampConverter(test.assignedDate)}
                                        </span>
                                    </div>
                                    {(test.status === TestStatus.ASSIGNED || test.status === TestStatus.IN_PROGRESS || test.status === TestStatus.NOT_STARTED) ?
                                        <>
                                            <div className={s.item}>
                                                {`Latest Attempt:`}
                                                <span style={{
                                                    fontWeight: '300',
                                                    color: '#2B2C2D'
                                                }}>
                                                        {timestampConverter(test.startedDate ? test.startedDate : test.assignedDate)}
                                                    </span>
                                            </div>
                                            <Row className={`${s.flex_row_start} ${s.item}`}>
                                                {
                                                    test.sections.length > 0 ?

                                                        <div>{`Begin Section:  `}
                                                            <span style={{
                                                                color: '#2B2C2D'
                                                            }}>{`Module ${test.sections[test.currentSection - 1].module} ${test.sections[test.currentSection - 1].title}`}</span>
                                                        </div>
                                                        : null
                                                }
                                            </Row>

                                        </> :
                                        <>
                                            <div className={s.item}>Completed Date:
                                                <span style={{
                                                    fontWeight: '300',
                                                    color: '#2B2C2D'
                                                }}>{test.completedDate ? timestampConverter(test.completedDate) : null}</span>
                                            </div>
                                            <Row className={s.flex_row_space_between} style={{gap: '1.5rem'}}>
                                                <Col className={s.flex_col}>
                                                    <div className={s.item}>Total</div>
                                                    <div className={s.scores}>{test.result.total}</div>
                                                </Col>
                                                <Col className={s.flex_col}>
                                                    <div className={s.item}>R & W</div>
                                                    <div
                                                        className={s.scores}>{test.result.readingAndWriting}</div>
                                                </Col>
                                                <Col className={s.flex_col}>
                                                    <div className={s.item}>Math</div>
                                                    <div className={s.scores}>{test.result.math}</div>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {areCompletedTests ? (
                                        <>
                                            <div className={s.flex_row_start}>
                                                <Link
                                                    style={{textDecoration: "none"}}
                                                    to={{
                                                        pathname: `/template/tests/${test.testId}/report`,
                                                        state: {testId: test.testId},
                                                    }}
                                                >
                                                    <Button type="submit" className={`${s.btn}`}>
                                                        Review
                                                    </Button>
                                                </Link>
                                                <div
                                                    className={s.flex_col}
                                                    key={`reset_${test.testId}`}
                                                    id={test.testId}
                                                    onClick={resetTestOnClick}
                                                >
                                                    <Button type="submit" className={`${s.btn}`}>
                                                        Retake
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={s.flex_row_start}>
                                                <Link
                                                    style={{textDecoration: "none"}}
                                                    to={`/tests/${test.testId}`}
                                                >
                                                    <Button type="submit" className={`${s.btn}`}>
                                                        {`${test.status === TestStatus.IN_PROGRESS ? "Resume" : "Start"}`}
                                                    </Button>
                                                </Link>
                                                {
                                                    test.status === TestStatus.IN_PROGRESS ?
                                                        <div
                                                            className={s.flex_col}
                                                            key={`reset_${test.testId}`}
                                                            id={test.testId}
                                                            onClick={resetTestOnClick}
                                                        >
                                                            <Button type="submit" className={`${s.btn}`}>
                                                                Retake
                                                            </Button>
                                                        </div> : null
                                                }
                                            </div>
                                        </>
                                    )
                                    }
                                < /Col>
                            </WidgetV2>
                        </React.Fragment>
                    ))}
                </Row>
            ) : <LoadingSpinner/>
    )
}