import React from 'react';
import s from "../styles.scss";
import {CalculatorIcon} from "../../../../../images/CalcIcon";
import {FunctionIcon} from "../../../../../images/FunctionIcon";
import {ThreeDotsIcon} from "../../../../../images/ThreeDotsIcon";

export const MathNav = ({toggleCalculator, toggleReference, toggleMore, dropdownRef, referenceRef}) => {
    return (
        <div className={s.tools}>

            {/* Calculator */}
            <div
                onClick={toggleCalculator}
                className={s.tool}
            >
                <div className={s.tool_image}>
                    <CalculatorIcon/>
                </div>
                <div className={s.tool_desc}>Calculator</div>
            </div>

            {/* Reference */}
            <div
                onClick={toggleReference}
                ref={referenceRef}
                className={s.tool}
            >
                <div className={s.tool_image}>
                    <FunctionIcon/>
                </div>
                <div className={s.tool_desc}>Reference</div>
            </div>

            {/* More */}
            <div
                onClick={toggleMore}
                className={s.tool}
                ref={dropdownRef}
            >
                <div className={s.tool_image}>
                    <ThreeDotsIcon/>
                </div>
                <div className={s.tool_desc}>More</div>
            </div>
        </div>
    )
}