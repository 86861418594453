import React, { useState, useEffect } from 'react';

export const ImageComponent = ({ imageName }) => {
    const [imgModule, setImgModule] = useState(null);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const imgModule = await import(`../../../../../../../images/questions/${imageName}.png`);
                setImgModule(imgModule.default);
            } catch (error) {
                console.error(`Error loading image: ${error}`);
            }
        };

        loadImage().then(r => r);
    }, [imageName]);

    if (!imgModule) return null;

    return <img src={imgModule} alt={imageName} />;
};
