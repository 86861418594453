import React from "react";
import ReactDOM from "react-dom/client";

import App from './components/Dashboard/index';
import {legacy_createStore as createStore, applyMiddleware} from 'redux'
import ReduxThunk from "redux-thunk";
import {Provider} from 'react-redux';
import reducers from './redux/reducers/';
import "./styles/main.scss"
import "katex/dist/katex.min.css";

// -- Data Store
const store = createStore(
    reducers,
    applyMiddleware(ReduxThunk)
);

ReactDOM
    .createRoot(document.getElementById("root"))
    .render(
        <Provider store={store}>
            <App/>
        </Provider>
    );
