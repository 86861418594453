import React, { useEffect } from "react";
import {connect} from "react-redux";
import {Switch, Route, withRouter, Redirect} from "react-router";
import PropTypes from "prop-types";
import Header from "../../header/index";
import AdminSidebar from "../../sidebar/admin";
import AdminDashboard from "../../../AdminDashboard/";
import s from "../styles.scss";
import {CreateTest} from "../../../AdminDashboard/components/createTest";
import AssignTest from "../../../AdminDashboard/components/assignTest/";
import {fetchUsers} from "../../../../../redux/actions/admin";
import {fetchUserInfo} from "../../../../../redux/actions/user";

const AdminLayout = (props) => {

    useEffect(() => {
        props.dispatch(fetchUserInfo());
        props.dispatch(fetchUsers());
    }, []);

    return (
        <>
            <Header/>
            <AdminSidebar/>
            <main className={s.content}>
                <Switch>
                    <Route path="/template" exact render={() => <Redirect to={"/template/dashboard"}/>}/>
                    <Route path="/template/dashboard" exact component={AdminDashboard}/>
                    <Route path="/template/create-test" exact component={CreateTest} />
                    <Route path="/template/assign-test" exact component={AssignTest} />
                    {/*<Route path="/template/review" exact component={Review} />*/}
                    <Route path='*' exact render={() => <Redirect to="/error"/>}/>
                </Switch>
            </main>
        </>
    );
}

AdminLayout.propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    userInfo: PropTypes.object,
    users: PropTypes.array,
}

const mapStateToProps = (store) => {
    return {
        sidebarOpened: store.navigation.sidebarOpened,
        dispatch: store.dispatch,
        userInfo: store.user.userInfo,
        users: store.admin.users,
        fetchUsers: fetchUsers,
        fetchUserInfo: fetchUserInfo
    };
}

export default withRouter(connect(mapStateToProps)(AdminLayout));
