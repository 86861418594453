import React, {useEffect, useState} from "react";
import {Col, Row, Table} from "reactstrap";
import Widget from "../../../components/widget";
import {fetchTestsByUserId} from "../../../../../redux/actions/test";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {FilterIcon} from "../../../../../images/FilterIcon";
import s from "./styles.scss";
import {timestampConverter} from "../../../../../utils/utils";
import {fetchUserInfo} from "../../../../../redux/actions/user";
import {NavDirection} from "../../../components/navDirection";
import {test_status} from "../../../../Test/utils/models";
import {ScoreResultsChart} from "../charts/ScoreResultsBarChart";
import {LoadingSpinner} from "../../../../Test/components/loading";
import {TestStatus} from "../../../../Test/models/AssignedTest";
import {NoData} from "../noData";


const Progress = (props) => {
    const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
    const [completedTests, setCompletedTests] = useState([]);
    const pageSize = 4;
    const firstTablePagesCount = Math.ceil(props.testsByUserId.length / pageSize);

    const setFirstTablePage = (e, index) => {
        e.preventDefault();
        setFirstTableCurrentPage(index);
    }
    const [highestRecord, setHighestRecord] = useState(0);
    const [testsTaken, setTestsTaken] = useState(0);
    useEffect(() => {
        if (props.testsByUserId) {
            const filteredCompletedTests = props.testsByUserId
                .filter(test => test.status === TestStatus.COMPLETED);
            setCompletedTests(filteredCompletedTests);
            setTestsTaken(filteredCompletedTests.length);

            if (filteredCompletedTests.length > 0) {
                setHighestRecord(filteredCompletedTests.map(tests => tests.result.total).reduce((a, b) => a > b));
            }
        }
    }, [props.testsByUserId])

    const sortTests = (a, b) => {
        if (a.status === "COMPLETED" && b.status !== "COMPLETED") {
            return -1;
        } else if (a.status !== "COMPLETED" && b.status === "COMPLETED") {
            return 1;
        } else {
            const dateA = a.status === "IN_PROGRESS" ?
                a.startedDate : (a.status === "NOT_STARTED" ? a.assignedDate : a.completedDate);
            const dateB = b.status === "IN_PROGRESS" ?
                b.startedDate : (b.status === "NOT_STARTED" ? b.assignedDate : b.completedDate);

            return dateA - dateB;
        }
    }

    return (
        props.testsByUserId ?
            <>
                <NavDirection
                    navs={['Dashboard', 'Progress']}
                    title={`My Progress`}
                    component={'progress'}
                />

                <Col className={s.flex_col}>
                    <Row className={s.flex_row}>
                        <Widget
                            component={"progressContainer1"}
                        >
                            <div className={s.table_title}>
                                <h2 style={{padding: '1rem'}}>{props.userInfo.firstName}</h2>
                            </div>
                            <Table className={s.table} responsive>
                                <tbody>
                                <tr>
                                    <td>
                                    <span>
                                       Highest Record
                                    </span>
                                    </td>
                                    <td>
                                    <span style={{alignItems: "center", fontWeight: "400"}}>
                                       {`${highestRecord ? highestRecord : 'No tests taken!'}`}
                                    </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <span>
                                       Tests taken:
                                    </span>
                                    </td>
                                    <td>
                                    <span style={{alignItems: "center", fontWeight: "400"}}>
                                       {testsTaken}
                                    </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <span>
                                       Target schools:
                                    </span>
                                        <br/>
                                        <span style={{color: '#919191'}}>(*recommended</span>
                                        <br/>
                                        <span style={{color: '#919191'}}>by score)</span>
                                    </td>
                                    <td>
                                    <span style={{alignItems: "center", fontWeight: "400"}}>
                                       TODO
                                    </span>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Widget>
                        <Widget
                            title={'SAT Scores'}
                            component={'progressContainer2'}
                        >
                            {testsTaken && completedTests.length > 0 ?
                                <ScoreResultsChart tests={completedTests}/> :
                                <NoData content={'No tests taken yet!'}/>}

                        </Widget>

                    </Row>
                    <h2 style={{paddingLeft: '1rem', paddingTop: '1rem'}}>Practice History</h2>
                    <Row className={s.dashboard_row}>
                        <Row className={s.row_chunk}>
                            <Widget
                                component={"progressContainer3"}
                            >
                                <div className={s.table_func}>
                                    <div style={{justifyContent: "flex-end"}} className={s.table_icons}>
                                        {/*<a href="/#"><img className={s.table_icon} src={cloudIcon} alt="Cloud"/></a>*/}
                                        {/*<a href="/#"><img className={s.table_icon} src={printerIcon} alt="Printer"/></a>*/}
                                        <a href="/#"><FilterIcon/></a>
                                        {/*<a href="/#"><img className={s.table_icon} src={funnelIcon} alt="Funnel"/></a>*/}
                                    </div>
                                </div>

                                <div className={s.table_container}>
                                    <Table className={s.table} responsive>
                                        <thead style={{padding: '1rem'}}>
                                        <tr>
                                            <th className={s.table_th_10}>Date</th>
                                            <th className={s.table_th_10}>Test</th>
                                            <th>Status</th>
                                            <th>Total Score</th>
                                            <th>R & W</th>
                                            <th>Math</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.testsByUserId
                                            .slice(
                                                firstTableCurrentPage * pageSize,
                                                (firstTableCurrentPage + 1) * pageSize
                                            )
                                            .sort(sortTests)
                                            .map(test => (
                                                <tr key={test.testId}>
                                                    <td style={{textAlign: "center"}}>
                                                        {timestampConverter(test.assignedDate)}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {test.testNumber}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {test_status.getValueByKey(test.status)}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {test.result.total}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {test.result.readingAndWriting}
                                                    </td>
                                                    <td style={{textAlign: "center"}}>
                                                        {test.result.math}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                {/*    <div className={s.pagination}>*/}
                                {/*    <Pagination aria-label="Page navigation example">*/}
                                {/*        <PaginationItem disabled={firstTableCurrentPage <= 0}>*/}
                                {/*            <PaginationLink*/}
                                {/*                onClick={e => setFirstTablePage(e, firstTableCurrentPage - 1)}*/}
                                {/*                previous*/}
                                {/*                href="#top"*/}
                                {/*            />*/}
                                {/*        </PaginationItem>*/}
                                {/*        {[...Array(firstTablePagesCount)].map((page, i) =>*/}
                                {/*            <PaginationItem active={i === firstTableCurrentPage} key={i}>*/}
                                {/*                <PaginationLink onClick={e => setFirstTablePage(e, i)} href="#top">*/}
                                {/*                    {i + 1}*/}
                                {/*                </PaginationLink>*/}
                                {/*            </PaginationItem>*/}
                                {/*        )}*/}
                                {/*        <PaginationItem disabled={firstTableCurrentPage >= firstTablePagesCount - 1}>*/}
                                {/*            <PaginationLink*/}
                                {/*                onClick={e => setFirstTablePage(e, firstTableCurrentPage + 1)}*/}
                                {/*                next*/}
                                {/*                href="#top"*/}
                                {/*            />*/}
                                {/*        </PaginationItem>*/}
                                {/*    </Pagination>*/}
                                {/*    </div>*/}
                            </Widget>
                        </Row>
                    </Row>
                </Col>
            </> : <LoadingSpinner/>
    )
}


const mapStateToProps = (state) => {
    return {
        userInfo: state.user.userInfo,
        fetchUserInfo: fetchUserInfo,
        testsByUserId: state.test.testsByUserId,
        fetchTestsByUserId: fetchTestsByUserId
    }
}

export default withRouter(connect(mapStateToProps)(Progress));
