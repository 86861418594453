import React from 'react';
import s from './styles.scss';
import {TestStatus} from "../../../models/AssignedTest";
import {Nav} from "../../nav";
import {Footer} from "../../footer";
import {AssistiveIcon} from "../../../../../images/AssistiveIcon";
import {ClockIcon} from "../../../../../images/ClockIcon";
import {TestReportIcon} from "../../../../../images/TestReportIcon";

export const DefaultPage = ({
                                testStatus,
                                isFirstPage,
                                isModuleStartingPage,
                                testType,
                                testNumber,
                                nextQuestion,
                                prevQuestion,
                            }) => {
    return (
        <>
            <Nav>
                <header>
                    <h1 className={s.header}>Digital SAT® Practice Test</h1>
                </header>
            </Nav>
            <main className={s.main}>
                <div className={`${s.default_page} ${s.border} ${s.default_margin} ${s.default_padding}`}>
                    <div className={s.desc_box}>
                        {testStatus === TestStatus.COMPLETED ? <div>You've already completed this test!</div> : (
                            <>
                                {/* Timing */}
                                <div className={s.desc_title}>
                                    <div className={s.desc_icon}>
                                        <ClockIcon/>
                                    </div>
                                    <span>Timing</span>
                                </div>
                                <div className={s.desc_cont}>
                                    This full-length practice test is structured to simulate the timing challenges
                                    you'll encounter on the real SAT exam.
                                    <br/>
                                    <br/>
                                    If you need to take a break or exit the test for any reason, just click on <b>Save
                                    &
                                    Exit</b>.
                                    Your progress will be saved up until the previous section, enabling you to pick
                                    up
                                    right
                                    where you left off when you're ready to continue.

                                </div>

                                {/* Scores */}
                                <div className={s.desc_title}>
                                    <div className={s.desc_icon}>
                                        <TestReportIcon/>
                                    </div>
                                    <span>Scores</span>
                                </div>
                                <div className={s.desc_cont}>
                                    Once you've completed the practice test, head over to <b>My Progress</b> to
                                    view your scores and receive customized study recommendations.
                                </div>

                                {/* Assistive Technology */}
                                <div className={s.desc_title}>
                                    <div className={s.desc_icon}>
                                        <AssistiveIcon/>
                                    </div>
                                    <span>Assistive Technology</span>
                                </div>
                                <div className={s.desc_cont}>
                                    If you encounter any questions or need assistance during the practice test,
                                    simply select the <b>Help</b> option from the <b>More</b> menu to ask for
                                    clarification.
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </main>
            <Footer
                isFirstPage={isFirstPage}
                isModuleStartingPage={isModuleStartingPage}
                testStatus={testStatus}
                testType={testType}
                testNumber={testNumber}
                nextQuestion={nextQuestion}
                prevQuestion={prevQuestion}
            />
        </>
    )
};