import {SELECT_POSITION_FAILURE, SELECT_POSITION_SUCCESS} from "../actions/selectPosition";

const initialState = {
    selectedPosition: [],
    error: null,
};

const selectPositionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_POSITION_SUCCESS:
            return { ...state, selectedPosition: action.payload, error: null };
        case SELECT_POSITION_FAILURE:
            return { ...state, selectedPosition: [], error: action.payload };
        default:
            return state;
    }
};

export default selectPositionReducer;
