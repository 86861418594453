import React, {useEffect, useState} from "react";
import {Sidebar} from "./components/helpers/sidebar";
import {Summary} from "./components/Summary";
import {Complete} from "./components/Complete";
import Button from "./components/helpers/button";
import s from "./styles.scss";
import SelectStudents from "./components/SelectStudents";
import SelectTests from "./components/SelectTests";
import PropTypes from "prop-types";
import {fetchUsers} from "../../../../../redux/actions/admin";
import {withRouter} from "react-router";
import {connect} from "react-redux";

const AssignTest = (props) => {
    const [step, setStep] = useState(1);
    const [showRequired, setShowRequiredFields] = useState(false);

    const [selectedData, setSelectedData] =
        useState({
            selectedUsers: {},
            selectedTests: {}
        });

    const updateSelectedUsers = (row, isChecked) => {
        updateData(row, isChecked, "selectedUsers");

    };

    const updateSelectedTests = (row, isChecked) => {
        updateData(row, isChecked, "selectedTests");
    };

    const updateData = (row, isChecked, selectedInfo) => {
        if (row.original) {
            const selectedRow = row.original;
            const data = selectedData[selectedInfo];
            const id = selectedInfo === "selectedUsers" ? selectedRow.userId : selectedRow.testId;

            if (id in data && !isChecked) {
                delete selectedData[selectedInfo][id];
            } else {
                selectedData[selectedInfo][id] = selectedRow;
            }

            setSelectedData({...selectedData});
        }
    };

    const nextStep = (onGoingStep) => {
        if (step === 4) return;
        if (step === 1 || (onGoingStep && onGoingStep !== 1 && step === 1)) {
            if (selectedData.selectedUsers.length === 0) {
                setShowRequiredFields(true);
                return;
            }
        }

        if (step === 2 || (onGoingStep && onGoingStep !== 2 && step === 2)) {
            if (selectedData.selectedTests.length === 0) {
                setShowRequiredFields(true);
                return;
            }
        }

        setStep((step) => {
            setShowRequiredFields(false);
            if (onGoingStep) return onGoingStep;
            return step + 1;
        });
    };

    const goBack = () => {
        if (step === 1) return;
        setStep((step) => step - 1);
    };

    return (
        <div style={{position: "relative", flexGrow: 1}}>
            <h2 style={{marginBottom: "2em"}}>Assign a Test</h2>

            <main className={s.main}>
                <Sidebar currentStep={step} handleNextStep={nextStep}/>
                <div className={s.right_container}>
                    <form className={s.form}>
                        {step === 1 && (
                            <SelectStudents
                                updateSelectedUsers={updateSelectedUsers}
                                selectedData={selectedData}
                                showRequired={showRequired}
                            />
                        )}
                        {step === 2 && (
                            <SelectTests
                                updateSelectedTests={updateSelectedTests}
                                selectedData={selectedData}
                                showRequired={showRequired}
                            />
                        )}
                        {step === 3 && (
                            <Summary
                                data={selectedData}
                            />
                        )}
                        {step === 4 && (
                            <Complete
                                data={selectedData}
                            />
                        )}
                    </form>
                    <footer className={`${s.footer}`}>
                        <Button
                            step={step}
                            goBack={goBack}
                            nextStep={nextStep}
                        />
                    </footer>
                </div>
            </main>
        </div>
    );
};

AssignTest.propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    testsByUserId: PropTypes.array,
    tests: PropTypes.array
}

const mapStateToProps = (state) => {
    return {
        tests: state.test.tests,
        error: state.admin.error,
        testsByUserId: state.test.testsByUserId,
        dispatch: state.dispatch,
        users: state.admin.users,
        fetchUsers: fetchUsers,
    }
}


export default withRouter(connect(mapStateToProps)(AssignTest));