import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import {fetchTestByUserId, fetchTestsByUserId} from "../../../../../redux/actions/test";
import s from "./styles.scss";
import {NavDirection} from "../../../components/navDirection";
import PropTypes from "prop-types";
import {LoadingSpinner} from "../../../../Test/components/loading";
import WidgetV2 from "../../../components/widget_v2";
import {MATH, READING_WRITING} from "../../../../Test/utils/utils";
import {percentilesConversionTable} from "../../../../Test/utils/percentilesConversionTable";
import {ScoreAnalysisBySkillsDoughnutChart} from "../charts/ScoreAnalysisBySkillsDoughnutChart";
import {
    mTopicsToDomains,
    rwTopicsToDomains,
} from "../../../../Test/utils/models";
import {timestampConverter, topicToNumOfQsInfo} from "../../../../../utils/utils";

const Report = (props) => {
    const [loading, setLoading] = useState(true);
    const [testNum, setTestNum] = useState(0);
    const [testType, setTestType] = useState("Practice Test");
    const [sectionsBySubject, setSectionsBySubject] = useState([]);
    const [scoreReport, setScoreReport] = useState([]);
    const [performanceBySkills, setPerformanceBySkills] = useState([]);
    const {testId} = props.location.state || '';
    const [testCompletedDate, setTestCompletedDate] = useState(null);
    const [testAssignedDate, setTestAssignedDate] = useState(null);
    const [section, setSection] = useState(1);
    const [questionNum, setQuestionNum] = useState(1);
    const dispatch = useDispatch();
    const history = useHistory();

    const fetchData = async (testId) => {
        try {
            return await dispatch(fetchTestByUserId(testId));
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const quantifyPerformanceBySkills = (sections, subject) => {
        let map = {};

        sections.map(section => {
            section.questionsAnswered.map(question => {
                if (question) {
                    // correct, incorrect, skipped
                    let curr = [0, 0, 0];
                    const domain = subject === "Math" ? mTopicsToDomains[question.topic] : rwTopicsToDomains[question.topic];

                    if (map[domain]) {
                        curr = map[domain];
                    }

                    if (question.answered === '' || !question.answered) {
                        curr[2] += 1
                    } else if (question.answered === question.correctAnswer) {
                        curr[0] += 1;
                    } else {
                        curr[1] += 1;
                    }

                    map[domain] = curr;
                }
            })
        })

        return map;
    }

    const MAX_RETRIES = 3;
    const RETRY_DELAY = 1000;
    const handleDataWithRetries = async (data) => {
        let retries = 0;

        while (retries < MAX_RETRIES) {
            try {
                if (data && data.sections) {
                    const readingWritingSections = data.sections.filter(section => section.title === READING_WRITING);
                    const mathSections = data.sections.filter(section => section.title === MATH);

                    setSectionsBySubject([readingWritingSections, mathSections]);
                    setPerformanceBySkills([
                        quantifyPerformanceBySkills(readingWritingSections, "Reading and Writing"),
                        quantifyPerformanceBySkills(mathSections, "Math")
                    ])
                }

                setTestNum(data.testNumber);
                setTestType(data.testType);
                setScoreReport(
                    {
                        "total": {
                            title: "Total <br/> Score",
                            field: "total",
                            score: data.result.total,
                            range: "400 - 1600"
                        },
                        "readingAndWriting": {
                            title: "Reading & <br/> Writing",
                            field: "readingAndWriting",
                            score: data.result.readingAndWriting,
                            range: "400 - 1600"
                        },
                        "math": {
                            title: "Math",
                            field: "math",
                            score: data.result.math,
                            range: "400 - 1600"
                        }
                    }
                );
                setTestAssignedDate(timestampConverter(data.assignedDate))
                setTestCompletedDate(timestampConverter(data.completedDate));
                setLoading(false);

                break;
            } catch (error) {
                console.error("Error occurred:", error);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                retries++;
            }
        }

        if (retries === MAX_RETRIES) {
            console.error("Max retries reached. Failed to handle data.");
            history.push("/template/tests");
        }
    }

    const transformToCamelCase = (inputString) => {
        return inputString.split(' ').map((word, index) => {
            return index === 0 ?
                word.toLowerCase() :
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join('');
    }

    const determineColor = (q) => {
        if (q.answered === '' || !q.answered) return '';
        return q.answered === q.correctAnswer ? s.correct : s.incorrect;
    }

    useEffect(() => {
        if (!testId) {
            history.push("/template/tests");
            return;
        }

        if (props.testsByUserId) {
            // TODO:  1/14/24: map testId to tests on click
            props.testsByUserId.filter(test => test.testId === testId)
                .map(currTest => handleDataWithRetries(currTest));
        } else {
            //  1/14/24:
            //  We already have redirect to the "tests" page in place
            //  if users ever try to enter the test report link directly.
            //  this can be removed after further testing.
            if (props.testByUserId && Object.keys(props.testByUserId).length === 0) {
                fetchData(testId).then((data) => {
                    handleDataWithRetries(props.testByUserId).then()
                });
            } else {
                handleDataWithRetries(props.testByUserId).then();
            }
        }
    }, [props.testsByUserId || props.testByUserId]);

    return (
        loading ?
            <LoadingSpinner
                isLoading={loading}
                className={s.spinner_center}
            /> : (
                <>
                    <NavDirection
                        navs={['Dashboard', 'Tests', 'Report']}
                        title={`Score Report`}
                        component={'scoreReport'}
                        testId={testId}
                    />
                    <Row className={`${s.container_v3} ${s.padding_v4}`}>
                        <WidgetV2
                            component={'scoreReportInfo'}
                        >
                            <div className={s.flex_col}>
                                <h2 style={{paddingBottom: "1rem"}}>{`${testType} ${testNum}`}</h2>
                                <div style={{paddingBottom: "0.5rem"}}>Test assigned on: <strong>{testAssignedDate}</strong>
                                </div>
                                <div>Test completed on: <strong>{testCompletedDate}</strong></div>
                            </div>
                        </WidgetV2>
                        <Link
                            style={{textDecoration: "none"}}
                            to={{
                                pathname: `/template/tests/${testId}/sections/1/questions/1/review`,
                                state: {
                                    testId: testId,
                                    section: section,
                                    questionNum: questionNum,
                                    performanceBySkills: performanceBySkills
                                }
                            }}
                        >
                            <Button
                                type="submit"
                                style={{marginTop: '1rem'}}
                                className={s.btn}
                            >
                                Start Review
                            </Button>
                        </Link>
                    </Row>

                    <Row className={`${s.container_v3} ${s.padding_v3}`}>
                        {scoreReport && Object.values(scoreReport).map((report, idx) => (
                            <WidgetV2
                                key={`score_report_${idx}`}
                                component={'scoreReport'}
                            >
                                <div
                                    className={`${s.flex_row_space_between}`}
                                >
                                    <h2 className={s.text_color} dangerouslySetInnerHTML={{__html: report.title}}/>
                                    <div className={s.flex_col}>
                                        <div className={s.content_score}>{report.score}</div>
                                        <div className={s.line_border_bottom}/>
                                        <div className={s.content_range}>{report.range}</div>
                                    </div>
                                </div>
                                <div className={s.flex_row} style={{paddingTop: '2rem'}}>
                                    <div className={s.border_box}>
                                        <strong>
                                            {percentilesConversionTable[report.field][report.score]}
                                        </strong>
                                        %
                                    </div>
                                    <div className={s.custom_font}>
                                        {`You are in the `}
                                        <strong>
                                            {percentilesConversionTable[report.field][report.score]}
                                        </strong>
                                        {`th percentile.`}
                                    </div>
                                </div>
                            </WidgetV2>
                        ))
                        }
                    </Row>
                    <div className={s.line_long}/>
                    {sectionsBySubject && sectionsBySubject.map((sections, idx) => (
                            <React.Fragment key={`sectionsBySubject_${idx}`}>
                                <Row className={s.container}>
                                    <div className={s.flex_row}>
                                        <h1>
                                            {sections[0].title}
                                        </h1>
                                        <div className={`${s.flex_row} ${s.align_contents}`}>
                                            <div
                                                className={`${s.content_score} ${s.padding_right}`}>{scoreReport[transformToCamelCase(sections[0].title)].score}</div>
                                            <div className={`${s.content_range_row} ${s.line_border_left}`}>200 - <br/> 800
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <Row className={s.container_v5}>
                                    {performanceBySkills && Object.entries(performanceBySkills[idx]).map(([topic, performanceResult]) => (
                                        <React.Fragment key={topic}>
                                            <Row className={`${s.flex_row_space_between}`}>
                                                <Col className={`${s.flex_col}`} style={{alignItems: 'center'}}>
                                                    <div className={s.container_fix}>
                                                        <ScoreAnalysisBySkillsDoughnutChart
                                                            topic={topic}
                                                            performanceResult={performanceResult}
                                                        />
                                                    </div>
                                                    <div className={s.chart_info}>
                                                        <div className={s.topic_title}>{topic}</div>
                                                        <div className={s.topic_info}>{`( ${topicToNumOfQsInfo[topic]} )`}</div>
                                                        <div style={{borderTop: '1.5px solid #adb5bd'}}/>
                                                        <div className={s.content_overall}>
                                                            <strong
                                                                className={s.blue_color}>{performanceResult[0]}</strong> {`correct | `}
                                                            <strong
                                                                className={s.red_color}>{performanceResult[1]}</strong> {`incorrect | `}
                                                            <strong
                                                                className={s.gray_color}>{performanceResult[2]}</strong> skipped
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                </Row>
                                <Col className={`${s.flex_col}`}>
                                    {sections.map((section, idx) => (
                                        <Col
                                            key={`sections_${idx}`}
                                            className={s.flex_col}
                                        >
                                            <Row className={s.container_v4}>
                                                <div className={s.flex_col}>
                                                    <h3>
                                                        {`Module ${section.module}`}
                                                    </h3>
                                                    <div className={s.content_overall}>
                                                        <strong
                                                            className={s.blue_color}>{`${section.correctAnswers}`}</strong> {`correct | `}
                                                        <strong
                                                            className={s.red_color}>{`${section.wrongAnswers}`}</strong> {`incorrect | `}
                                                        <strong
                                                            className={s.gray_color}>{`${section.unanswered}`}</strong> skipped
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className={`${s.flex_row} ${s.wrapper}`}>
                                                {
                                                    section.questionsAnswered
                                                        .map((q, idx) => (
                                                                q && q.questionNumber !== null ?
                                                                    <Link
                                                                        key={`${section.section}_${idx}`}
                                                                        style={{textDecoration: "none"}}
                                                                        to={{
                                                                            pathname: `/template/tests/${testId}/sections/${section.section}/questions/${q.questionNumber}/review`,
                                                                            state: {
                                                                                testId: testId,
                                                                                section: section.section,
                                                                                questionNum: q.questionNumber,
                                                                                performanceBySkills: performanceBySkills
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div
                                                                            key={`section_${idx}`}
                                                                            className={`${s.question_num} ${determineColor(q)}`}
                                                                        >
                                                                            {q.questionNumber}
                                                                        </div>
                                                                    </Link>
                                                                    : null
                                                            )
                                                        )
                                                }
                                            </Row>
                                        </Col>
                                    ))}
                                </Col>
                                <div className={s.line_long}/>
                            </React.Fragment>
                        )
                    )}
                </>
            )
    )
}

Report.propTypes = {
    dispatch: PropTypes.func.isRequired,
    testByUserId: PropTypes.object,
    testsByUserId: PropTypes.array
}

const mapStateToProps = (store) => {
    return {
        testByUserId: store.test.testByUserId,
        fetchTestByUserId: fetchTestByUserId,
        testsByUserId: store.test.testsByUserId,
        fetchTestsByUserId: fetchTestsByUserId
    }
}

export default withRouter(connect(mapStateToProps)(Report));
