import React from "react";
import {Row} from "reactstrap";
import Widget from "../../../components/widget";
import s from "../../../styles.scss";


export const Profile = () => {

    // useEffect(() => {
    //
    // }

    return (
        <div style={{position: 'relative', flexGrow: 1, padding: '24px 0 68px'}}>
            <h2 style={{marginBottom: "2em"}}>Profile</h2>

            {/*<Row className={s.container_row}>*/}
            {/*    <Row className={s.row_chunk}>*/}
            {/*        <Widget>*/}
            {/*            <div className={s.chart_header}>*/}
            {/*                <div className={s.chart_title}>This is MyAccount</div>*/}
            {/*            </div>*/}
            {/*        </Widget>*/}
            {/*    </Row>*/}
            {/*</Row>*/}
        </div>
    )
}