import React, {useEffect} from "react";

import {IndeterminateCheckbox,} from "./helpers/TableHelperJs.js";

import {Table} from "./helpers/Table";
import {fetchUsers} from "../../../../../../redux/actions/admin";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import s from "../styles.scss";

const SelectStudents = ({
                            users,
                            updateSelectedUsers,
                            selectedData,
                            showRequired,
                        }) => {

    const columns = React.useMemo(
        () => [
            {
                id: 'select',
                cell: ({row}) => (
                    <IndeterminateCheckbox
                        row={row}
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        indeterminate={row.getIsSomeSelected()}
                        updateSelectedData={updateSelectedUsers}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                accessorFn: row => row.firstName,
                id: 'firstName',
                header: () => <span className={s.header}>First Name</span>,
                cell: info => info.getValue(),
                footer: props => props.column.id,
            },
            {
                accessorFn: row => row.lastName,
                id: 'lastName',
                cell: info => info.getValue(),
                header: () => <span className={s.header}>Last Name</span>,
                footer: props => props.column.id,
            },
            {
                accessorFn: row => row.email,
                id: 'email',
                cell: info => info.getValue(),
                header: () => <span className={s.header}>Email</span>,
                footer: props => props.column.id,
            }
        ],
        []
    );

    return (
        <>
            <Table
                columns={columns}
                data={users}
                updateSelectedData={updateSelectedUsers}
                selectedData={selectedData}
                showRequired={showRequired}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        users: state.admin.users,
        error: state.admin.error,
        fetchUsers: fetchUsers(),
    }
}

export default withRouter(connect(mapStateToProps)(SelectStudents));