import {FETCH_USERS_FAILURE, FETCH_USERS_SUCCESS} from "../actions/admin";

const initialState = {
    users: [],
    error: null,
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_SUCCESS:
            return { ...state, users: action.payload, error: null };
        case FETCH_USERS_FAILURE:
            return { ...state, users: [], error: action.payload };
        default:
            return state;
    }
};

export default adminReducer;
