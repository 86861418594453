// TODO: store this as csv file.
const percentilesConversionTable = {
    "total": {
        1600: 99,
        1590: 99,
        1580: 99,
        1570: 99,
        1560: 99,
        1550: 99,
        1540: 99,
        1530: 99,
        1520: 98,
        1510: 98,
        1500: 98,
        1490: 97,
        1480: 97,
        1470: 96,
        1460: 96,
        1450: 96,
        1440: 95,
        1430: 95,
        1420: 94,
        1410: 94,
        1400: 93,
        1390: 92,
        1380: 92,
        1370: 91,
        1360: 90,
        1350: 90,
        1340: 89,
        1330: 88,
        1320: 87,
        1310: 87,
        1300: 86,
        1290: 85,
        1280: 84,
        1270: 83,
        1260: 82,
        1250: 81,
        1240: 80,
        1230: 79,
        1220: 78,
        1210: 76,
        1200: 75,
        1190: 74,
        1180: 73,
        1170: 71,
        1160: 70,
        1150: 69,
        1140: 67,
        1130: 66,
        1120: 64,
        1110: 63,
        1100: 61,
        1090: 60,
        1080: 58,
        1070: 56,
        1060: 55,
        1050: 53,
        1040: 52,
        1030: 50,
        1020: 48,
        1010: 47,
        1000: 45,
        990: 43,
        980: 42,
        970: 40,
        960: 39,
        950: 37,
        940: 36,
        930: 34,
        920: 32,
        910: 31,
        900: 29,
        890: 28,
        880: 26,
        870: 25,
        860: 23,
        850: 22,
        840: 20,
        830: 19,
        820: 17,
        810: 16,
        800: 14,
        790: 13,
        780: 12,
        770: 10,
        760: 9,
        750: 8,
        740: 7,
        730: 6,
        720: 5,
        710: 4,
        700: 3,
        690: 3,
        680: 2,
        670: 2,
        660: 1,
        650: 1,
        640: 1,
        630: 1,
        620: -1,
        610: -1,
        600: -1,
        590: -1,
        580: -1,
        570: -1,
        560: -1,
        550: -1,
        540: -1,
        530: -1,
        520: -1,
        510: -1,
        500: -1,
        490: -1,
        480: -1,
        470: -1,
        460: -1,
        450: -1,
        440: -1,
        430: -1,
        420: -1,
        410: -1,
        400: -1
    },
    "readingAndWriting": {
        800: 99,
        790: 99,
        780: 99,
        770: 99,
        760: 99,
        750: 98,
        740: 97,
        730: 96,
        720: 95,
        710: 94,
        700: 93,
        690: 92,
        680: 90,
        670: 89,
        660: 87,
        650: 85,
        640: 83,
        630: 81,
        620: 78,
        610: 76,
        600: 73,
        590: 71,
        580: 68,
        570: 65,
        560: 62,
        550: 59,
        540: 56,
        530: 53,
        520: 50,
        510: 47,
        500: 43,
        490: 40,
        480: 37,
        470: 33,
        460: 30,
        450: 27,
        440: 24,
        430: 21,
        420: 18,
        410: 15,
        400: 13,
        390: 10,
        380: 8,
        370: 6,
        360: 5,
        350: 3,
        340: 2,
        330: 2,
        320: 1,
        310: 1,
        300: 1,
        290: -1,
        280: -1,
        270: -1,
        260: -1,
        250: -1,
        240: -1,
        230: -1,
        220: -1,
        210: -1,
        200: -1
    },
    "math": {
        800: 99,
        790: 99,
        780: 98,
        770: 97,
        760: 96,
        750: 95,
        740: 94,
        730: 94,
        720: 93,
        710: 92,
        700: 91,
        690: 90,
        680: 89,
        670: 87,
        660: 86,
        650: 85,
        640: 83,
        630: 82,
        620: 80,
        610: 78,
        600: 76,
        590: 73,
        580: 71,
        570: 68,
        560: 66,
        550: 63,
        540: 60,
        530: 57,
        520: 53,
        510: 49,
        500: 46,
        490: 44,
        480: 41,
        470: 38,
        460: 35,
        450: 33,
        440: 30,
        430: 27,
        420: 24,
        410: 22,
        400: 19,
        390: 16,
        380: 13,
        370: 11,
        360: 8,
        350: 6,
        340: 4,
        330: 3,
        320: 2,
        310: 2,
        300: 1,
        290: -1,
        280: -1,
        270: -1,
        260: -1,
        250: -1,
        240: -1,
        230: -1,
        220: -1,
        210: -1,
        200: -1
    }
}

module.exports = {
    percentilesConversionTable
}